
import { EnemyActor } from "../enemy-actors/EnemyActor";
import { LaserTurretActor } from "./LaserTurretActor";
import { GameVars } from "../../../GameVars";


export class LaserBeam extends Phaser.GameObjects.Container {

    private laserTurretActor: LaserTurretActor;
    private enemyActors: EnemyActor[];
    private f: number;
    private framesDuration: number;
    private impact_x: number[];
    private impact_y: number[];
    private grade: number;
    private lines: Phaser.GameObjects.Sprite[];

    constructor(scene: Phaser.Scene, laserTurretActor: LaserTurretActor, enemyActors: EnemyActor[], grade: number) {

        super(scene);

        this.laserTurretActor = laserTurretActor;
        this.enemyActors = enemyActors;
        this.grade = grade;
        this.f = 0;
        this.framesDuration = GameVars.timeStepFactor === 1 ? 16 : 4;

        this.lines = [];

        this.impact_x = [];
        this.impact_y = [];

        for (let i = 0; i < this.enemyActors.length; i++) {
            this.impact_x.push(this.enemyActors[i].x);
            this.impact_y.push(this.enemyActors[i].y);
        }

        if (this.grade === 3) {

            let impact_x = this.enemyActors[0].x;
            let impact_y = this.enemyActors[0].y - 20;

            const emmission_x = this.laserTurretActor.x;
            const emmission_y = this.laserTurretActor.y - 30;

            let offX = impact_x - emmission_x;
            let offY = impact_y - emmission_y;

            impact_x += (offX * 50);
            impact_y += (offY * 50);

            let line = this.scene.add.sprite(impact_x, impact_y, "texture_atlas_3", "plasma_blast_1");
            line.scaleX = Math.hypot(impact_y - emmission_y, impact_x - emmission_x) / line.width;
            line.setOrigin(1, .5);
            line.setAngle(Math.atan2(impact_y - emmission_y, impact_x - emmission_x) * 180 / Math.PI);
            this.add(line);
            this.lines.push(line);

            line.anims.play("plasma_blast");

            let topAnimation = this.scene.add.sprite(emmission_x, emmission_y - 7, "texture_atlas_1");
            topAnimation.setScale(.9);
            this.add(topAnimation);

            topAnimation.anims.play("plasma");

            let spark = this.scene.add.sprite(emmission_x, emmission_y - 7, "texture_atlas_1", "electric_damage_00");
            spark.setScale(1.1);
            this.add(spark);

            spark.anims.play("electric_damage");

            this.scene.tweens.add({
                targets: spark,
                scaleX: .1,
                scaleY: .1,
                ease: Phaser.Math.Easing.Cubic.In,
                duration: 500
            });

            spark.on("animationcomplete", () => {
                spark.destroy();
                spark = null;
            }, this);

            this.lines[0].visible = false;

        } else {

            this.impact_x = [];
            this.impact_y = [];

            for (let i = 0; i < this.enemyActors.length; i++) {
                this.impact_x.push(this.enemyActors[i].x);
                this.impact_y.push(this.enemyActors[i].y);
            }

            for (let i = 0; i < this.enemyActors.length; i++) {

                let impact_x: number;
                let impact_y: number;
                
                let emmission_x: number;
                let emmission_y: number;

                if (i === 0) {

                    impact_x = this.impact_x[i];
                    impact_y = this.impact_y[i];
                    
                    emmission_x = this.laserTurretActor.x;
                    emmission_y = this.laserTurretActor.y - 20;
                } else {
                    impact_x = this.impact_x[i];
                    impact_y = this.impact_y[i];
                    
                    emmission_x = this.impact_x[i - 1];
                    emmission_y = this.impact_y[i - 1];
                }

                if (this.grade === 1) {

                    const line = this.scene.add.sprite(impact_x, impact_y, "texture_atlas_3", "flame_00");
                    line.scaleX = Math.hypot(impact_y - emmission_y, impact_x - emmission_x) / line.width * 1.1;
                    line.scaleY = .4 + this.grade * .2;
                    line.setOrigin(1, .5);
                    line.setAngle(Math.atan2(impact_y - emmission_y, impact_x - emmission_x) * 180 / Math.PI);
                    this.add(line);
                    this.lines.push(line);
    
                    line.anims.play("flame");

                } else {

                    const line = this.scene.add.sprite(impact_x, impact_y, "texture_atlas_3", "bolt_00");
                    line.scaleX = Math.hypot(impact_y - emmission_y, impact_x - emmission_x) / line.width * 1.1;
                    line.scaleY = .4 + this.grade * .2;
                    line.setOrigin(1, .5);
                    line.setAngle(Math.atan2(impact_y - emmission_y, impact_x - emmission_x) * 180 / Math.PI);
                    this.add(line);
                    this.lines.push(line);
    
                    line.anims.play("bolt");
                }
            }
        }
        
        this.scene.sys.updateList.add(this);
    }

    public preUpdate(time: number, delta: number): void {

        if (this.f++ === this.framesDuration) {
            this.destroy();
            return;
        }

        if (this.grade === 3) {

            if (this.f <= (GameVars.timeStepFactor === 1 ? 4 : 1)) {
                this.lines[0].visible = false;
            } else {
                this.lines[0].visible = true;
            }

            let impact_x = this.enemyActors[0].x;
            let impact_y = this.enemyActors[0].y - 20;

            const emmission_x = this.laserTurretActor.x;
            const emmission_y = this.laserTurretActor.y - 30;

            let offX = impact_x - emmission_x;
            let offY = impact_y - emmission_y;

            impact_x += (offX * 50);
            impact_y += (offY * 50);

            if (this.lines[0]) {
                this.lines[0].setPosition(impact_x, impact_y);
                this.lines[0].scaleX = 1;
                this.lines[0].scaleX = Math.hypot(impact_y - emmission_y, impact_x - emmission_x) / this.lines[0].width;
                this.lines[0].setAngle(Math.atan2(impact_y - emmission_y, impact_x - emmission_x) * 180 / Math.PI);
            }

        } else {

            this.impact_x = [];
            this.impact_y = [];

            for (let i = 0; i < this.enemyActors.length; i++) {
                this.impact_x.push(this.enemyActors[i].x);
                this.impact_y.push(this.enemyActors[i].y);
            }

            for (let i = 0; i < this.enemyActors.length; i++) {

                let impact_x;
                let impact_y;
                
                let emmission_x;
                let emmission_y;

                if (i === 0) {

                    impact_x = this.impact_x[i];
                    impact_y = this.impact_y[i];
                    
                    emmission_x = this.laserTurretActor.x;
                    emmission_y = this.laserTurretActor.y - 30;
                } else {
                    impact_x = this.impact_x[i];
                    impact_y = this.impact_y[i];
                    
                    emmission_x = this.impact_x[i - 1];
                    emmission_y = this.impact_y[i - 1];
                }

                if (this.lines[i]) {
                    this.lines[i].setPosition(impact_x, impact_y);
                    this.lines[i].scaleX = 1;
                    this.lines[i].scaleX = Math.hypot(impact_y - emmission_y, impact_x - emmission_x) / this.lines[i].width;
                    this.lines[i].setAngle(Math.atan2(impact_y - emmission_y, impact_x - emmission_x) * 180 / Math.PI);
                }

                if (this.grade === 1) {
                    this.lines[i].scaleY -= .025;
                    this.lines[i].scaleX -= .055 * this.f;
                    this.lines[i].scaleX = Math.max(0, this.lines[i].scaleX);
                }
            }
        }
    }
}
