import { GameVars } from "../GameVars";
import { SmokeEffect } from "./battle-scene/SmokeEffect";
import { SplashScene } from "./splash-scene/SplashScene";
import { AudioManager } from "../AudioManager";

export class GameTitle extends Phaser.GameObjects.Container {

    private static PIVOT_X = 536;
    private static PIVOT_Y = 190;

    private base: Phaser.GameObjects.Image;
    private hammerShadow: Phaser.GameObjects.Image;
    private hammer: Phaser.GameObjects.Image;
    private splashElementsShown: boolean;

    constructor(scene: Phaser.Scene) {

        super(scene);

        this.splashElementsShown = false;

        const titleBanner = new Phaser.GameObjects.Image(scene, 0, 0, "texture_atlas_1", "red_banner");
        this.add(titleBanner);

        this.hammerShadow = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_0", "endless_siege_title_3");
        this.hammerShadow.x = GameTitle.PIVOT_X - this.hammerShadow.width / 2;
        this.hammerShadow.y = GameTitle.PIVOT_Y - this.hammerShadow.height / 2;
        this.hammerShadow.setOrigin(GameTitle.PIVOT_X / this.hammerShadow.width, GameTitle.PIVOT_Y / this.hammerShadow.height);
        this.add(this.hammerShadow);

        this.base = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_0", "endless_siege_title_01");
        this.add(this.base);

        this.hammer = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_0", "endless_siege_title_02");
        this.hammer.x = GameTitle.PIVOT_X - this.hammer.width / 2;
        this.hammer.y = GameTitle.PIVOT_Y - this.hammer.height / 2;
        this.hammer.setOrigin(GameTitle.PIVOT_X / this.hammer.width, GameTitle.PIVOT_Y / this.hammer.height);
        this.add(this.hammer);

        if (SplashScene.firstInstantiation) {

            this.hammerShadow.alpha = 0;
            this.hammer.alpha = 0;

            this.alpha = 0;

            this.scene.tweens.add({
                targets: this,
                alpha: 1,
                ease: Phaser.Math.Easing.Cubic.Out,
                duration: 250
            });
        }
    }

    public update(): void {

        this.hammerShadow.x = this.hammer.x;
        this.hammerShadow.angle = this.hammer.angle;
        this.hammerShadow.scaleX = this.hammer.scaleX;
        this.hammerShadow.scaleY = this.hammer.scaleY;
    }

    public activate(): void {

        if (SplashScene.firstInstantiation) {

            const px = this.hammer.x;

            this.hammer.x += 30;

            this.scene.tweens.add({
                targets: [this.hammer, this.hammerShadow],
                x: px,
                alpha: 1,
                ease: Phaser.Math.Easing.Cubic.Out,
                duration: 500,
                onComplete: function(): void {
                    this.loop();
                },
                onCompleteScope: this
            });
        } else {
            this.loop();
        }        
    }

    private loop(): void {

        this.scene.tweens.add({
            targets: this.hammer,
            angle: 15,
            scaleX: 1.1,
            scaleY: 1.1,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 500,
            onComplete: function(): void {
                this.scene.tweens.add({
                    targets: this.hammer,
                    angle: 16,
                    scaleX: 1.11,
                    scaleY: 1.11,
                    ease: Phaser.Math.Easing.Linear,
                    duration: 200,
                    onComplete: function(): void {

                        let smokeEffect = new SmokeEffect(this.scene);
                        smokeEffect.x = 170;
                        smokeEffect.y = -10;
                        smokeEffect.scaleX = 2.5;
                        smokeEffect.scaleY = 2.5;
                        smokeEffect.alpha = .25;
                        this.addAt(smokeEffect, 2);

                        smokeEffect = new SmokeEffect(this.scene);
                        smokeEffect.x = 180;
                        smokeEffect.y = -10;
                        smokeEffect.scaleX = 3;
                        smokeEffect.scaleY = 3;
                        smokeEffect.alpha = .15;
                        this.addAt(smokeEffect, 2);
    
                        this.scene.tweens.add({
                            targets: this.hammer,
                            angle: -3,
                            scaleX: .97,
                            scaleY: .97,
                            ease: Phaser.Math.Easing.Cubic.In,
                            duration: 150,
                            onComplete: function(): void {

                                AudioManager.playSoundEffect("hammer_hits_shield");

                                if (!this.splashElementsShown) {
                                    SplashScene.currentInstance.showSplashElelements();
                                    this.splashElementsShown = true;
                                }

                                this.scene.tweens.add({
                                    targets: this.hammer,
                                    angle: 0,
                                    scaleX: 1,
                                    scaleY: 1,
                                    ease: Phaser.Math.Easing.Cubic.Out,
                                    duration: 200
                                });

                                this.base.scaleX = .95;
                                this.base.scaleY = .95;

                                this.scene.tweens.add({
                                    targets: this.base,
                                    scaleX: 1,
                                    scaleY: 1,
                                    ease: Phaser.Math.Easing.Cubic.Out,
                                    duration: 400,
                                    onComplete: function(): void {
                                        this.scene.time.delayedCall(2500, function(): void {
                                            this.loop();
                                        }, [], this);
                                    },
                                    onCompleteScope: this
                                });
                            },
                            onCompleteScope: this
                        });
                    },
                    onCompleteScope: this
                });
                
            },
            onCompleteScope: this
        });
    }
}
