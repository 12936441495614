import { GameConstants } from "../../../GameConstants";
import { BattleManager } from "../BattleManager";
import { GameVars } from "../../../GameVars";

export class CreditsContainer extends Phaser.GameObjects.Container {

    public text: Phaser.GameObjects.Text;

    private coinIcon: Phaser.GameObjects.Image;

    constructor(scene: Phaser.Scene) {

        super(scene);

        this.x = GameConstants.GAME_WIDTH / 2 + 40;
        this.y = 35;

        const bckMid = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "box_small_2");
        bckMid.setOrigin(0, .5);
        bckMid.scaleX = 10;
        this.add(bckMid);

        const bckRight = new Phaser.GameObjects.Image(this.scene, bckMid.width * bckMid.scaleX, 0, "texture_atlas_1", "box_small_1");
        bckRight.setOrigin(0, .5);
        this.add(bckRight);

        this.text = new Phaser.GameObjects.Text(this.scene, (bckMid.width * bckMid.scaleX) / 2 + 17, -2, GameVars.formatNumber(BattleManager.engine.credits), { fontFamily: "Supercell", fontSize: "22px", color: "#FFE734" });
        this.text.setOrigin(.5);
        this.text.setStroke("#B35B20", 4);
        this.add(this.text);

        this.coinIcon = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "info_icon_gold");
        this.add(this.coinIcon);
    }

    public onCoinsReachedEndPosition(): void {

        this.coinIcon.setScale(1.35);

        this.scene.time.delayedCall(80, function (): void {
            this.coinIcon.setScale(1);
        }, [], this);
    }
}
