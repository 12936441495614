
import { TurretButton } from "./TurretButton";
import { TurretType } from "endless-siege-engine/build/main/lib/Types";

export class TurretButtonsContainer extends Phaser.GameObjects.Container {

    private turretButtons: TurretButton [];

    constructor(scene: Phaser.Scene) {

        super(scene);

        this.y = -60;
        this.x = 80;

        let turretTypes = [
            {index: 0, type: TurretType.PROJECTILE},
            {index: 1, type: TurretType.LASER},
            {index: 2, type: TurretType.LAUNCH},
            {index: 3, type: TurretType.GLUE}
        ];
        this.turretButtons = [];

        let globalSeparation = 0;
        let selfSeparation = 0;
        if (turretTypes.length === 3) {
            globalSeparation = 40;
            selfSeparation = 15;
        } else if (turretTypes.length === 2) {
            globalSeparation = 100;
            selfSeparation = 25;
        } else if (turretTypes.length === 1) {
            globalSeparation = 175;
        }

        for (let i = 0; i < turretTypes.length; ++i) {
            const turretButton = new TurretButton(this.scene, turretTypes[i].type, turretTypes[i].index, globalSeparation + 110 * i + selfSeparation * i);
            this.add(turretButton);

            this.turretButtons.push(turretButton);
        }
    }

    public updatePriceText(): void {

        for (let i = 0; i < this.turretButtons.length; ++i) {
            this.turretButtons[i].updateTextPrice();
        }
    }

    public hideButtonsAtTutorialStart(): void {

        for (let i = 0; i < this.turretButtons.length; i ++) {
            
            if (this.turretButtons[i].name === TurretType.PROJECTILE) {
                this.turretButtons[i].showHandCursor();
            } else {
                this.turretButtons[i].hide();
            }
        }
    }

    public changeTurretSprite(type: string, newSprite: string): void {

        for (let i = 0; i < this.turretButtons.length; i ++) {
            if (this.turretButtons[i].type === type) {
                this.turretButtons[i].setFrame(newSprite);
                break;
            }
        }
    }
}
