
import { GameConstants } from "../../../GameConstants";
import * as Creepts from "endless-siege-engine";


export class GlueBulletActor extends Phaser.GameObjects.Container {

    public glueBullet: Creepts.GlueBullet;
    public initialPosition: {x: number, y: number};

    private img: Phaser.GameObjects.Sprite;
    private f: number;

    constructor(scene: Phaser.Scene, glueBullet: Creepts.GlueBullet) {

        super(scene);

        this.img = this.scene.add.sprite(0, 0, "texture_atlas_1", "bullet_fx_3");
        this.img.setOrigin(.5, 1);
        this.img.setScale(.25);
        this.add(this.img);

        this.glueBullet = glueBullet;

        this.visible = false;

        this.x = this.glueBullet.x * GameConstants.CELLS_SIZE;
        this.y = this.glueBullet.y * GameConstants.CELLS_SIZE;

        this.initialPosition = {x: this.x, y: this.y};

        this.f = 0;
    }

    public update(time: number, delta: number): void {
        
        let smoothFactor: number;

        if (GameConstants.INTERPOLATE_TRAJECTORIES) {
            smoothFactor = .3;
        } else {
            smoothFactor = 1;
        }

        const offX = (this.glueBullet.x * GameConstants.CELLS_SIZE - (this.x + this.img.x)) * smoothFactor;
        const offY = (this.glueBullet.y * GameConstants.CELLS_SIZE - (this.y + this.img.y)) * smoothFactor;
        
        this.img.x += offX;
        this.img.y += offY;

        this.img.rotation = Math.atan2(offY, offX) + Math.PI * .5;

        if (!this.visible) {
            const distX = this.initialPosition.x - (this.x + this.img.x);
            const distY = this.initialPosition.y - (this.y + this.img.y);
            if (Math.sqrt( distX * distX + distY * distY) > 20) {
                this.visible = true;
            }
        }

        this.f++;

        if (this.f % 6 === 0) {
            let fx = this.scene.add.sprite(this.img.x, this.img.y, "texture_atlas_1");
            fx.setScale(Math.random() * .1 + .2);
            this.add(fx);
            fx.anims.play("snow_shoot");

            fx.on("animationcomplete", () => {
                fx.destroy();
                fx = null;
            }, this);
        }
    }

    public destroy(): void {

        this.img.visible = false;

        if (this.scene) {
            this.scene.time.addEvent({callback: () => {
                super.destroy();
            }, delay: 500, callbackScope: this});
        }
    }
}
