import { GameConstants } from "endless-siege-engine";
import { EnemyType } from "endless-siege-engine/build/main/lib/Types";


export class LifeBar extends Phaser.GameObjects.Container {

    private bar: Phaser.GameObjects.Image;
    private totalLife: number;

    constructor(scene: Phaser.Scene) {

        super(scene);

        this.bar = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "life_bar_1");
        this.bar.setOrigin(.5, 0);
        this.add(this.bar);

        this.visible = false;
    }

    public reset(totalLife: number, enemyType: string): void {
        this.totalLife = totalLife;

        switch (enemyType) {
            case EnemyType.SOLDIER:
                this.y = -50;
                break;
            case EnemyType.RUNNER:
                this.y = -50;
                break;
            case EnemyType.HEALER:
                this.y = -50;
                break;
            case EnemyType.BLOB:
                this.y = -65;
                break;
            case EnemyType.FLIER:
                this.y = -55;
                break;
            default:
        }

        this.visible = false;
    }

    public updateValue(life: number): void {

        const num = 40 - Math.round((life / this.totalLife) * 40);
        this.bar.setFrame("life_bar_" + Math.max(num, 1));
    }
}
