import * as Creepts from "endless-siege-engine";
import { TurretMenu } from "./TurretMenu";
import { BattleManager } from "../BattleManager";
import { AudioManager } from "../../../AudioManager";
import { GameVars } from "../../../GameVars";
import { StrategyShoot } from "endless-siege-engine/build/main/lib/Types";


export class ButtonStrategy extends Phaser.GameObjects.Container {

    private button: Phaser.GameObjects.Image;
    private turretMenu: TurretMenu;

    private textResult: Phaser.GameObjects.Text;

    constructor(scene: Phaser.Scene, x: number, y: number) {

        super(scene);

        this.x = x;
        this.y = y;

        this.button = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "btn_info");
        this.button.setInteractive({ useHandCursor: true });
        this.button.on("pointerdown", this.onDownButton, this);
        this.button.on("pointerover", () => {

            if (this.alpha !== 1) {
                return;
            }

            this.setScale(1.025); 
        }, this);

        this.button.on("pointerout", () => { 
        
            if (this.alpha !== 1) {
                return;
            }

            this.setScale(1); 
        }, this);
        this.add(this.button);

        const text = new Phaser.GameObjects.Text(this.scene, -130, 0, GameVars.gameText[GameVars.gameData.language].STRATEGY, {fontFamily: "Supercell", fontSize: "20px", color: "#FFFFFF"});
        text.setOrigin(0, .5);
        text.setStroke("#000000", 4);
        text.setShadow(3, 3, "#000000");
        this.add(text);

        this.textResult = new Phaser.GameObjects.Text(this.scene, 92, 0, "text", {fontFamily: "Supercell", fontSize: "20px", color: "#FFFFFF"});
        this.textResult.setOrigin(.5);
        this.textResult.setStroke("#000000", 4);
        this.textResult.setShadow(3, 3, "#000000");
        this.add(this.textResult);
    }

    public setTurretMenu(turretMenu: TurretMenu): void {

        this.turretMenu = turretMenu;
        const textR = this.turretMenu.turret.shootingStrategy;
        this.textResult.text = textR.charAt(0).toUpperCase() + textR.slice(1);
        if (this.turretMenu.turret.shootingStrategy === StrategyShoot.CLOSEST) {
            this.textResult.setFontSize(18);
        } else if (this.turretMenu.turret.shootingStrategy === StrategyShoot.WEAKEST) {
            this.textResult.setFontSize(16);
        } else if (this.turretMenu.turret.shootingStrategy === StrategyShoot.STRONGEST) {
            this.textResult.setFontSize(13);
        } else {
            this.textResult.setFontSize(20);
        }
    }

    public clearTurretMenu(): void {

        this.turretMenu = null;
    }

    public setInactiveButton(): void {

        if (this.turretMenu == null) {
            return;
        }
        this.button.disableInteractive();
        this.alpha = .5;
        this.textResult.setText("");
    }

    public setActiveButton(): void {

        if (this.turretMenu == null) {
            return;
        }
        this.button.setInteractive({ useHandCursor: true });
        const textR = this.turretMenu.turret.shootingStrategy;

        this.alpha = 1;
        this.textResult.setText(textR.charAt(0).toUpperCase() + textR.slice(1));
    }

    private onDownButton(): void {

        if (this.alpha !== 1 || this.turretMenu == null) {
            return;
        }

        BattleManager.setNextStrategy(this.turretMenu.turret.id);

        const text = this.turretMenu.turret.shootingStrategy;
        
        this.textResult.setText(text.charAt(0).toUpperCase() + text.slice(1));

        if (this.turretMenu.turret.shootingStrategy === StrategyShoot.CLOSEST) {
            this.textResult.setFontSize(18);
        } else if (this.turretMenu.turret.shootingStrategy === StrategyShoot.WEAKEST) {
            this.textResult.setFontSize(16);
        } else if (this.turretMenu.turret.shootingStrategy === StrategyShoot.STRONGEST) {
            this.textResult.setFontSize(13);
        } else {
            this.textResult.setFontSize(20);
        }

        AudioManager.playSoundEffect("btn_click");
    }
}
