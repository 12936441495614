import { TurretSelected } from "./TurretSelected";
import { TurretButtonsContainer } from "./TurretButtonsContainer";
import { BattleManager } from "../BattleManager";
import { GameVars } from "../../../GameVars";
import { GameConstants } from "../../../GameConstants";
import { AudioManager } from "../../../AudioManager";
import { TutorialManager } from "../TutorialManager";
import { BoardContainer } from "../board-container/BoardContainer";
import { TurretActor } from "../turret-actors/TurretActor";

export class GUI extends Phaser.GameObjects.Container {

    public turretButtonsContainer: TurretButtonsContainer;

    private rewardChest: Phaser.GameObjects.Sprite;
    private menuButton: Phaser.GameObjects.Image;
    private timeStepButton: Phaser.GameObjects.Image;
    private timeStepImage: Phaser.GameObjects.Image;
    private pauseButton: Phaser.GameObjects.Image;
    private pauseImage: Phaser.GameObjects.Image;
    private autoButton: Phaser.GameObjects.Image;
    private autoImage: Phaser.GameObjects.Image;
    private turretSelected: TurretSelected;
    private topContainer: Phaser.GameObjects.Container;
    private bottomContainer: Phaser.GameObjects.Container;
    private handCursor: Phaser.GameObjects.Image;
    private pauseButtonTween: Phaser.Tweens.Tween;
    private notificationContainer: Phaser.GameObjects.Container;
    turretDragContainer: Phaser.GameObjects.Container;
    turretDraggingTween: Phaser.Tweens.Timeline;

    constructor(scene: Phaser.Scene) {

        super(scene);

        this.handCursor = null;
        this.pauseButtonTween = null;

        this.topContainer = new Phaser.GameObjects.Container(this.scene);
        this.topContainer.scaleY = GameVars.scaleY;
        this.add(this.topContainer);

        this.bottomContainer = new Phaser.GameObjects.Container(this.scene);
        this.bottomContainer.y = GameConstants.GAME_HEIGHT;
        this.bottomContainer.scaleY = GameVars.scaleY;
        this.add(this.bottomContainer);

        this.menuButton = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "btn_menu");
        this.menuButton.setInteractive({ useHandCursor: true });
        this.menuButton.setOrigin(0);
        this.menuButton.on("pointerdown", this.onClickMenu, this);
        this.menuButton.on("pointerover", () => {
            if (GameVars.paused || GameVars.gameOver) {
                return;
            }
            this.onBtnOver(this.menuButton);
        });
        this.menuButton.on("pointerout", () => {
            if (GameVars.paused || GameVars.gameOver) {
                return;
            }
            this.onBtnOut(this.menuButton);
        });
        this.topContainer.add(this.menuButton);

        this.pauseButton = new Phaser.GameObjects.Image(this.scene, GameConstants.GAME_WIDTH - 186, 0, "texture_atlas_1", "btn_wave");
        this.pauseButton.setOrigin(1);
        this.pauseButton.setInteractive({ useHandCursor: true });
        this.pauseButton.on("pointerdown", this.onClickPause, this);
        this.pauseButton.on("pointerover", () => {
            if (GameVars.gameOver || GameVars.paused) {
                return;
            }
            this.pauseButton.setFrame("btn_wave_on");
        }, this);
        this.pauseButton.on("pointerout", () => {

            if (GameVars.gameOver || GameVars.paused) {
                return;
            }

            this.pauseButton.setFrame("btn_wave");
        }, this);
        this.bottomContainer.add(this.pauseButton);

        this.pauseImage = new Phaser.GameObjects.Image(this.scene, GameConstants.GAME_WIDTH - 232, -35, "texture_atlas_1", "icon_waves_4");
        this.bottomContainer.add(this.pauseImage);

        this.timeStepButton = new Phaser.GameObjects.Image(this.scene, GameConstants.GAME_WIDTH - 93, 0, "texture_atlas_1", "btn_wave");
        this.timeStepButton.setOrigin(1);
        this.timeStepButton.setInteractive({ useHandCursor: true });
        this.timeStepButton.on("pointerdown", this.onClickTimeStep, this);
        this.timeStepButton.on("pointerover", () => {
            if (GameVars.paused || GameVars.gameOver) {
                return;
            }
            this.timeStepButton.setFrame("btn_wave_on");
        });
        this.timeStepButton.on("pointerout", () => {
            if (GameVars.paused || GameVars.gameOver) {
                return;
            }
            this.timeStepButton.setFrame("btn_wave");
        });
        this.bottomContainer.add(this.timeStepButton);

        this.timeStepImage = new Phaser.GameObjects.Image(this.scene, GameConstants.GAME_WIDTH - 139, -35, "texture_atlas_1", "icon_x1");
        this.bottomContainer.add(this.timeStepImage);

        this.autoButton = new Phaser.GameObjects.Image(this.scene, GameConstants.GAME_WIDTH, 0, "texture_atlas_1", "btn_wave");
        this.autoButton.setOrigin(1);
        this.autoButton.setInteractive({ useHandCursor: true });
        this.autoButton.on("pointerdown", () => {
            if (GameVars.gameOver || GameVars.paused) {
                return;
            }
            this.onClickAuto();
        });
        this.autoButton.on("pointerover", () => {

            if (GameVars.gameOver || GameVars.paused) {
                return;
            }
            this.autoButton.setFrame("btn_wave_on");
        });
        this.autoButton.on("pointerout", () => {
            if (GameVars.gameOver || GameVars.paused) {
                return;
            }
            this.autoButton.setFrame("btn_wave");
        });
        this.bottomContainer.add(this.autoButton);

        this.autoImage = new Phaser.GameObjects.Image(this.scene, GameConstants.GAME_WIDTH - 47, -35, "texture_atlas_1", "icon_waves_6");
        this.bottomContainer.add(this.autoImage);

        if (GameVars.autoSendWave) {
            this.autoImage.setFrame("icon_waves_5");
        } else {
            this.autoImage.setFrame("icon_waves_6");
        }

        this.turretButtonsContainer = new TurretButtonsContainer(this.scene);
        this.bottomContainer.add(this.turretButtonsContainer);

        // la barra de espacio
        const spaceKey = this.scene.input.keyboard.addKey("SPACE");
        spaceKey.on("down", this.onClickPause, this);
    }

    public createTurret(type: string): void {

        this.turretSelected = new TurretSelected(this.scene, type, this);
        this.bottomContainer.add(this.turretSelected);
    }

    public removeTurret(): void {

        if (this.turretSelected) {
            this.bottomContainer.remove(this.turretSelected);
            this.turretSelected.visible = false;
            this.turretSelected = null;
        }
    }

    public onShowRewardWave(): void {

        const path = GameVars.currentMapData.path[1];

        let x = GameConstants.GAME_WIDTH / 2;
        let y = (GameConstants.GAME_HEIGHT / 2 - GameConstants.CELLS_SIZE / 2) / GameVars.scaleY;

        x -= (GameConstants.CELLS_SIZE * GameVars.currentMapData.size.c / 2) * GameVars.scaleCorrectionFactor;
        y -= (GameConstants.CELLS_SIZE * GameVars.currentMapData.size.r / 2) * GameVars.scaleCorrectionFactor;

        x += (GameConstants.CELLS_SIZE * path.c + GameConstants.CELLS_SIZE / 2) * GameVars.scaleCorrectionFactor;
        y += (GameConstants.CELLS_SIZE * path.r + GameConstants.CELLS_SIZE / 2) * GameVars.scaleCorrectionFactor;

        this.rewardChest = new Phaser.GameObjects.Sprite(this.scene, x, y, "texture_atlas_1", "icon_chest_01");
        this.rewardChest.setScale(1.65);
        this.scene.add.existing(this.rewardChest);
        this.topContainer.add(this.rewardChest);

        this.rewardChest.anims.play("chest_loop");
    }

    public onHideRewardWave(): void {

        this.rewardChest.destroy();
    }

    public hideButtonsAtTutorialStart(): void {

        this.turretButtonsContainer.hideButtonsAtTutorialStart();

        BoardContainer.currentInstance.hideButtonsAtTutorialStart();

        this.pauseImage.visible = false;
        this.timeStepImage.visible = false;
        this.autoImage.visible = false;
    }

    public showTimeMultiplierButton(): void {

        this.timeStepImage.visible = true;

        this.handCursor = new Phaser.GameObjects.Image(this.scene, this.timeStepImage.x, this.timeStepImage.y - 50, "texture_atlas_1", "hand_cursor");
        this.handCursor.setOrigin(.2);
        this.handCursor.angle = -90;
        this.bottomContainer.add(this.handCursor);


        this.scene.tweens.add({
            targets: this.handCursor,
            y: this.handCursor.y - 25,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 750,
            yoyo: false,
            repeat: -1
        });
    }

    public showModeButton() {
        this.autoImage.visible = true;

        this.handCursor = new Phaser.GameObjects.Image(this.scene, this.autoImage.x, this.autoImage.y - 50, "texture_atlas_1", "hand_cursor");
        this.handCursor.setOrigin(.2);
        this.handCursor.angle = -90;
        this.bottomContainer.add(this.handCursor);


        this.scene.tweens.add({
            targets: this.handCursor,
            y: this.handCursor.y - 25,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 750,
            yoyo: false,
            repeat: -1
        });
    }

    public onSpeedX1Forced(): void {

        this.timeStepImage.setFrame("icon_x1");
    }

    public createFinishDuelNotification(): void {

        this.notificationContainer = new Phaser.GameObjects.Container(this.scene, GameConstants.GAME_WIDTH + 150, 40);
        this.notificationContainer.scaleY = GameVars.scaleY;
        this.add(this.notificationContainer);

        const notBackground = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "tab_finish");
        notBackground.setOrigin(.5, .5);
        notBackground.setScale(1.2, 1);
        this.notificationContainer.add(notBackground);

        const notText = new Phaser.GameObjects.Text(this.scene, -90, 0, GameVars.gameText[GameVars.gameData.language].FINISH_MATCH, { fontFamily: "Oswald-SemiBold", fontSize: "16px", color: "#FFFFFF" });
        notText.setOrigin(0.5);
        notText.setStroke("#000000", 4);
        notText.setShadow(3, 3, "#000000");
        this.notificationContainer.add(notText);

        // finish button
        const finishButtonContainer = new Phaser.GameObjects.Container(this.scene, 70, 0);
        this.notificationContainer.add(finishButtonContainer);

        const finishDuelButton = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "btn_yellow_wide");
        finishDuelButton.setScale(.8, 0.5);
        finishDuelButton.setInteractive({ useHandCursor: true });
        finishDuelButton.on("pointerover", () => {
            finishButtonContainer.setScale(1.025);
        });
        finishDuelButton.on("pointerout", () => {
            finishButtonContainer.setScale(1);
        });
        finishDuelButton.on("pointerdown", this.onDownFinishButton, this);
        finishButtonContainer.add(finishDuelButton);

        const finishDuelText = new Phaser.GameObjects.Text(this.scene, 0, 0, GameVars.gameText[GameVars.gameData.language].FINISH, { fontFamily: "Supercell", fontSize: "14px", color: "#FFFFFF" });
        finishDuelText.setOrigin(.5);
        finishDuelText.setStroke("#000000", 6);
        finishDuelText.setShadow(3, 3, "#000000");
        finishButtonContainer.add(finishDuelText);

        // close button
        const closePopUpContainer = new Phaser.GameObjects.Container(this.scene, -210, 0);
        this.notificationContainer.add(closePopUpContainer);
        const closePopUpButton = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "btn_close_tab");
        closePopUpButton.setScale(.95);
        closePopUpButton.setInteractive({ useHandCursor: true });
        closePopUpButton.on("pointerover", () => {
            closePopUpContainer.setScale(1.025);
        });
        closePopUpButton.on("pointerout", () => {
            closePopUpContainer.setScale(1);
        });
        closePopUpButton.on("pointerdown", this.onDownClosePopUp, this);
        closePopUpContainer.add(closePopUpButton);

        this.scene.tweens.add({
            targets: this.notificationContainer,
            x: GameConstants.GAME_WIDTH - 140,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 1000,
        });
    }

    private onClickTimeStep(): void {

        if (this.timeStepButton.alpha !== 1 || GameVars.paused || GameVars.gameOver || (!GameVars.gameData.tutorialSeen && TutorialManager.State !== "waiting_for_tap2x" && TutorialManager.State !== "waiting_for_tap3x")) {
            return;
        }

        if (GameVars.timeStepFactor === 1) {

            BattleManager.setTimeStepFactor(4);
            this.timeStepImage.setFrame("icon_x2");

            if (!GameVars.gameData.tutorialSeen) {
                TutorialManager.onTapSpeedMultiplier();
            }

        } else if (GameVars.timeStepFactor === 4) {

            BattleManager.setTimeStepFactor(8);
            this.timeStepImage.setFrame("icon_x3");

            if (!GameVars.gameData.tutorialSeen) {
                this.handCursor.destroy();
                this.handCursor = null;
                TutorialManager.onTapSpeedMultiplier();
            }

        } else {

            BattleManager.setTimeStepFactor(1);
            this.timeStepImage.setFrame("icon_x1");

        }
    }

    private onClickMenu(): void {

        if (GameVars.paused || GameVars.gameOver || !GameVars.gameData.tutorialSeen) {
            return;
        }

        BattleManager.onClickMenu();

        AudioManager.playSoundEffect("btn_click");
    }

    private onClickPause(): void {

        if (GameVars.paused || GameVars.gameOver || !GameVars.gameData.tutorialSeen) {
            return;
        }

        if (GameVars.semiPaused) {

            BattleManager.semiResume();
            this.pauseImage.setFrame("icon_waves_4");

            this.pauseButtonTween.remove();
            this.pauseImage.alpha = 1;

        } else {

            BattleManager.semiPause();
            this.pauseImage.setFrame("icon_waves_1");

            this.pauseButtonTween = this.scene.tweens.add({
                targets: this.pauseImage,
                alpha: .35,
                ease: Phaser.Math.Easing.Cubic.InOut,
                duration: 600,
                repeat: -1,
                yoyo: true
            });
        }

        AudioManager.playSoundEffect("btn_click");
    }

    private onClickAuto(): void {

        if (!GameVars.gameData.tutorialSeen && (TutorialManager.State === "waiting_for_tapauto" || TutorialManager.State === "waiting_for_tapmanual")) {
            this.handCursor?.destroy();
            this.handCursor = null;
            TutorialManager.onModeChanged();
        }
        if (GameVars.gameData.tutorialSeen) {
            if (GameVars.autoSendWave) {
                this.autoImage.setFrame("icon_waves_6");
                BattleManager.setAutoSendWave(false);
            } else {
                this.autoImage.setFrame("icon_waves_5");
                BattleManager.setAutoSendWave(true);
            }
            AudioManager.playSoundEffect("btn_click");
        }
    }

    private onBtnOver(btn: any): void {

        btn.setScale(1.1);
    }

    private onBtnOut(btn: any): void {

        btn.setScale(1);
    }

    private onDownFinishButton(): void {

        BattleManager.forceFinishDuel();
        AudioManager.playSoundEffect("btn_click");

        this.scene.tweens.add({
            targets: this.notificationContainer,
            x: GameConstants.GAME_WIDTH + 250,
            ease: Phaser.Math.Easing.Cubic.Out,
        });
    }

    private onDownClosePopUp(): void {

        this.notificationContainer.destroy();
    }

    public showTurretDragAction(tp: { c: number, r: number }) {

        const pos = {
            x: ((tp.c + .5) * GameConstants.CELLS_SIZE) + GameConstants.CELLS_SIZE / 2 - 5,
            y: ((tp.r + .5) * GameConstants.CELLS_SIZE - 8) + GameConstants.CELLS_SIZE
        };
        const turrentButtonPos = { x: 60, y: GameConstants.GAME_HEIGHT - 70 };

        this.turretDragContainer = new Phaser.GameObjects.Container(this.scene, pos.x, pos.y);
        this.turretDragContainer.scaleY = GameVars.scaleY;
        this.add(this.turretDragContainer);

        const tutorialTurretContainer = new Phaser.GameObjects.Container(this.scene, 0, 0);
        tutorialTurretContainer.setScale(.8);
        tutorialTurretContainer.alpha = .45;
        this.turretDragContainer.add(tutorialTurretContainer);

        const turretBase = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "archer_1_base");
        tutorialTurretContainer.add(turretBase);

        const turretCannon = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "archer_1_top");
        turretCannon.angle = -90;
        tutorialTurretContainer.add(turretCannon);

        this.handCursor = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "hand_cursor");
        this.handCursor.setOrigin(0, 0.1);
        this.handCursor.scaleY = GameVars.scaleY;
        this.turretDragContainer.add(this.handCursor);

        this.turretDraggingTween = this.scene.tweens.timeline({
            targets: this.turretDragContainer,
            tweens: [
                {
                    alpha: { from: 1, to: 1 },
                    x: { from: turrentButtonPos.x, to: turrentButtonPos.x },
                    y: { from: turrentButtonPos.y, to: turrentButtonPos.y },
                    scale: { from: 1.1, to: 1 },
                    duration: 100
                },
                {
                    x: { from: turrentButtonPos.x, to: pos.x },
                    y: { from: turrentButtonPos.y, to: pos.y },
                    duration: 1000,
                    offset: 100,
                },
                {
                    x: { from: pos.x, to: pos.x },
                    y: { from: pos.y, to: pos.y },
                    duration: 400,
                    offset: 1100
                },
                {
                    alpha: 0,
                    duration: 100,
                    offset: 1500
                },
                {
                    alpha: 0,
                    duration: 1500,
                    offset: 1600
                }
            ],
            onComplete: () => {
                this.turretDraggingTween.play();
            }
        });
    }

    public removeDragAction() {
        this.turretDraggingTween.stop();
        this.turretDraggingTween.destroy();
        this.turretDragContainer.destroy();
    }
}
