import { GameConstants } from "./GameConstants";
import { GameVars } from "./GameVars";
import { AudioManager } from "./AudioManager";
import mapsData from "../assets/config/maps.json";
import * as seedrandom from "seedrandom";

const { SimplioUserInfoSDK } = require('@SimplioOfficial/simplio-user-info-sdk');

export class GameManager {

    public static init(): void {

        const urlParams = new URLSearchParams(window.location.search);

        GameVars.simplioApiUrl = urlParams.get("env") || "test";
        GameVars.simplioUserId = urlParams.get("userId") || "test";
        GameVars.simplioGameId = "7flvuW7LxeLLwHSyy-H3VOHHlCSXy4JQMbf4PkUNju_EAzFufaFKEQ==";

        GameVars.simplio = new SimplioUserInfoSDK(GameVars.simplioApiUrl, GameVars.simplioGameId);

        if (GameVars.currentScene.game.device.os.desktop) {
            window.addEventListener("resize", () => {
                GameVars.currentScene.scale.refresh();
            }, false);
        }

        GameVars.mapsData = mapsData;

        if (GameVars.currentScene.sys.game.device.os.desktop) {
            GameVars.scaleY = 1;
            GameVars.scaleCorrectionFactor = 1;
        } else {

            GameVars.currentScene.game.scale.displaySize = GameVars.currentScene.game.scale.parentSize;
            GameVars.currentScene.game.scale.refresh();

            const aspectRatio = window.innerHeight / window.innerWidth;
            GameVars.scaleY = (GameConstants.GAME_HEIGHT / GameConstants.GAME_WIDTH) / aspectRatio;

            if (aspectRatio > 1.5) {
                GameVars.scaleCorrectionFactor = 1.2;
            } else if (aspectRatio < 1.33) {
                GameVars.scaleCorrectionFactor = .8;
            } else {
                GameVars.scaleCorrectionFactor = 1;
            }
        }

        const date = new Date();

        const seed = GameManager.hashCode(date.getUTCDay() + "/" + date.getUTCMonth() + "/" + date.getUTCFullYear());
        GameVars.seedRandom = seedrandom.default(seed);

        GameManager.readGameData();
    }

    public static  onSplashPlayDown(): void {
        /* TutorialManager.isStartedFromSettings = false;
        GameManager.enterBattleScene(); */
        GameVars.gameData.tutorialSeen = GameVars.gameData.tutorialSeenAtleastOnce;
        GameManager.readGameData(true);
    }

    public static resize(): void {

        GameVars.currentScene.time.delayedCall(250, function (): void {

            const canvas = document.querySelector("canvas");

            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;

            canvas.style.width = windowWidth + "px";
            canvas.style.height = windowHeight + "px";

            GameVars.currentScene.scale.refresh();
        }, [], this);
    }

    public static readGameData(jumpToGame?: boolean): void {
        GameManager.getGameStorageData(
            GameConstants.SAVED_GAME_DATA_KEY,
            function (data: string): void {
                let json: any = null;
                if (data) {
                    json = JSON.parse(data);
                }
                GameVars.gameData = (json && json.gameData) ? json.gameData : {
                    soundMuted: false,
                    musicMuted: false,
                    language: GameConstants.LANGUAGE_ENGLISH,
                    scores: [],
                    tutorialSeen: false,
                    tutorialSeenAtleastOnce: false,
                    announcementsSeen: []
                };
                GameVars.logsObject = (json && json.logsObject) ? json.logsObject : {
                    actions: []
                };
                GameVars.currentMapId = (json && json.logsObject && json.logsObject.actions.length > 0 && json.currentMapId) ? Number(json.currentMapId) : new Date().getUTCDate() - 1;

                GameVars.currentMapId = GameVars.gameData.tutorialSeen ? GameVars.currentMapId : 1;

                GameManager.startGame(GameVars.currentMapId, jumpToGame);
            }
        );
    }

    public static setCurrentScene(scene: Phaser.Scene): void {

        GameVars.currentScene = scene;
    }

    public static onGameAssetsLoaded(): void {

        AudioManager.init();

        GameVars.gameText = GameVars.currentScene.cache.json.get("game-text");

        if (GameVars.logsObject && GameVars.logsObject.actions && GameVars.logsObject.actions.length) {
            GameManager.enterBattleScene();
        } else {
            // GameManager.enterSplashScene();
            GameManager.onSplashPlayDown();
        }
    }

    public static enterGraphicScene(): void {

        GameVars.currentScene.scene.start("GraphicsScene");
    }

    public static enterSplashScene(): void {

        GameVars.currentScene.scene.start("SplashScene");
    }

    public static enterReplayScene(): void {

        GameVars.currentScene.scene.start("ReplayScene");
    }

    public static enterBattleScene(): void {

        GameVars.currentScene.scene.start("BattleScene");
    }

    public static reset(): void {
        GameVars.logsObject = { actions: [] };
        GameManager.writeGameData(() => {
            if (GameVars.gameOver) {
                // GameManager.enterSplashScene();
                GameManager.onSplashPlayDown(); 
            } else {
                GameManager.enterBattleScene();
            }
        })
    }

    public static writeGameData(callback?: any): void {

        GameManager.setGameStorageData(
            GameConstants.SAVED_GAME_DATA_KEY,
            {
                gameData: GameVars.gameData,
                logsObject: GameVars.logsObject,
                currentMapId: GameVars.currentMapId,
            },
            function (): void {
                if (!!callback) {
                    callback();
                }
                GameManager.log("game data successfully saved");
            }
        );
    }

    public static log(text: string, error?: Error): void {

        if (!GameConstants.VERBOSE) {
            return;
        }

        if (error) {
            console.error(text + ":", error);
        } else {
            console.log(text);
        }
    }

    public static getTimeUntilEndOfDay(): number {

        const d = new Date();
        const h = d.getUTCHours();
        const m = d.getUTCMinutes();
        const s = d.getUTCSeconds();

        return (24 * 60 * 60) - (h * 60 * 60) - (m * 60) - s;
    }

    public static generateObstaclesBoard(): void {

        GameVars.obstaclesBoard = [];

        for (let i = 0; i < 22; i++) {

            GameVars.obstaclesBoard[i] = [];
            for (let j = 0; j < 14; j++) {
                GameVars.obstaclesBoard[i][j] = 0;
            }
        }

        let rand = (Math.floor(GameVars.seedRandom() * 5) + 1);
        let prevRand = rand;

        for (let i = 0; i < 22; i++) {
            for (let j = 0; j < 14; j++) {

                let c = j - 1;
                let r = i - 8;

                if (GameVars.seedRandom() < .2) {
                    rand = (Math.floor(GameVars.seedRandom() * 4) + 6);
                }

                if (r < 0 || c === -1 || c === 12) {

                    if (GameVars.enemiesPathCells[0].r === -1) {
                        if ((c > GameVars.enemiesPathCells[0].c + 1 || c < GameVars.enemiesPathCells[0].c - 1) || (r > GameVars.enemiesPathCells[0].r || r < GameVars.enemiesPathCells[0].r - 1)) {
                            GameVars.obstaclesBoard[i][j] = rand;
                        }
                    } else {
                        if (GameVars.enemiesPathCells[0].r + 1 !== r || GameVars.enemiesPathCells[0].c !== c) {
                            GameVars.obstaclesBoard[i][j] = rand;
                        }
                    }
                }

                rand = prevRand;
            }
        }

        for (let i = 0; i < GameVars.plateausCells.length; i++) {

            if (GameVars.seedRandom() < .1) {
                rand = (Math.floor(GameVars.seedRandom() * 4) + 6);
            }

            GameVars.obstaclesBoard[GameVars.plateausCells[i].r + 8][GameVars.plateausCells[i].c + 1] = rand;

            rand = prevRand;
        }

        if (GameVars.waterCells) {
            for (let i = 0; i < GameVars.waterCells.length; i++) {
                GameVars.obstaclesBoard[GameVars.waterCells[i].r + 8][GameVars.waterCells[i].c + 1] = 99;
            }
        }
    }

    public static generateObstacle(scene: Phaser.Scene, x: number, y: number, obstacleNumber: number): Phaser.GameObjects.Image {

        return new Phaser.GameObjects.Image(scene, x, y, "texture_atlas_1", "obstacle_" + obstacleNumber);;
    }

    private static startGame(mapIndex: number, fromTutorial = false): void {

        GameVars.currentMapId = mapIndex;

        if (GameVars.gameData.scores.length === 0) {
            for (let i = 0; i < 31; i++) {
                GameVars.gameData.scores[i] = 0;
            }
        }

        GameVars.currentMapData = GameVars.mapsData[mapIndex];
        GameVars.enemiesPathCells = GameVars.currentMapData.path;
        GameVars.plateausCells = GameVars.currentMapData.plateaus;
        GameVars.waterCells = GameVars.currentMapData.water;

        GameManager.generateObstaclesBoard();
        if (fromTutorial) {
            GameVars.currentScene.scene.start("BattleScene");
        } else {
            GameVars.currentScene.scene.start("PreloadScene");
        }
    }

    private static getGameStorageData(key: string, successCb: Function): void {

        let gameDataStr = localStorage.getItem(key);
        /* gameDataStr = `{"gameData":{"soundMuted":true,"musicMuted":true,"language":"en","scores":[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,10,0,0,0,0,2575],"tutorialSeen":true,"announcementsSeen":[]},"logsObject":{"actions":[{"ty":"nw","tk":0},{"ty":"at","tk":22,"trT":"laser","p":{"r":8,"c":3}},{"ty":"lu","tk":32,"id":0},{"ty":"lu","tk":34,"id":0},{"ty":"lu","tk":35,"id":0},{"ty":"lu","tk":36,"id":0},{"ty":"nw","tk":186},{"ty":"lu","tk":287,"id":0},{"ty":"lu","tk":297,"id":0},{"ty":"lu","tk":584,"id":0},{"ty":"nw","tk":912},{"ty":"at","tk":1098,"trT":"projectile","p":{"r":13,"c":2}},{"ty":"lu","tk":1172,"id":1},{"ty":"lu","tk":1183,"id":1},{"ty":"nw","tk":1296},{"ty":"lu","tk":1374,"id":1},{"ty":"lu","tk":1387,"id":1},{"ty":"lu","tk":1397,"id":1},{"ty":"nw","tk":1535},{"ty":"lu","tk":1588,"id":1},{"ty":"lu","tk":1600,"id":1},{"ty":"lu","tk":1613,"id":1}]},"currentMapId":30}`; */
        successCb(gameDataStr);
    }

    private static setGameStorageData(key: string, value: any, successCb: Function): void {

        localStorage.setItem(key, JSON.stringify(value));
        successCb();
    }

    private static hashCode(s: string): string {

        return s.split("").reduce(function (a, b) { a = ((a << 5) - a) + b.charCodeAt(0); return a & a; }, 0).toString();
    }
}
