import { BattleScene } from "./BattleScene";
import { GameConstants } from "../../GameConstants";
import { GameVars } from "../../GameVars";
import { BoardContainer } from "./board-container/BoardContainer";
import enemiesData from "../../../assets/config/enemies.json";
import turretsData from "../../../assets/config/turrets.json";
import wavesData from "../../../assets/config/waves.json";
import tutorialWavesData from "../../../assets/config/tutorial_waves.json";

import * as Creepts from "endless-siege-engine";
import { TutorialManager } from "./TutorialManager";
import { GameManager } from "../../GameManager";
import { ActionTypes, EngineReturn, ErrorType } from "endless-siege-engine/build/main/lib/Types";

export class BattleManager {

    public static engine: Creepts.Engine;
    public static t: number;
    public static isProcessingActions: boolean;

    public static async init(): Promise<void> {

        GameVars.wave = 1;
        GameVars.paused = false;
        GameVars.semiPaused = false;
        GameVars.waveOver = true;
        GameVars.autoSendWave = false;
        GameVars.loopNumber = 1;
        GameVars.loopRate = 1;
        GameVars.loopVolume = .2;
        GameVars.dangerRate = 1;
        GameVars.gameOver = false;
        GameVars.ballistaUpgraded = false;
        GameVars.torchUpgraded = false;
        GameVars.cannonUpgraded = false;
        GameVars.timeWarpUpgraded = false;

        BattleManager.startGame(!!GameVars.logsObject);
    }

    public static update(time: number, delta: number): void {
        BattleManager.engine.update();
        if (BattleManager.engine.score > GameVars.gameData.scores[GameVars.currentMapId]) {
            GameVars.gameData.scores[GameVars.currentMapId] = BattleManager.engine.score;
            GameManager.writeGameData();
        }
    }

    public static startGame(isResumedGame: boolean) {

        const aspectRatio = window.innerHeight / window.innerWidth;

        if (aspectRatio > 1.5) {
            if (GameVars.currentMapData.size.c > 11) {
                GameVars.scaleCorrectionFactor = 1;
            } else if (GameVars.currentMapData.size.c > 10) {
                GameVars.scaleCorrectionFactor = 1.1;
            } else {
                GameVars.scaleCorrectionFactor = 1.2;
            }
        }


        GameVars.currentMapId = GameVars.currentMapId;
        GameVars.currentWaveId = GameVars.currentMapId % 11;

        let gameConfig: Creepts.GameConfig;

        GameVars.currentMapData = GameVars.mapsData[GameVars.currentMapId];
        GameVars.enemiesPathCells = GameVars.currentMapData.path;
        GameVars.plateausCells = GameVars.currentMapData.plateaus;
        GameVars.waterCells = GameVars.currentMapData.water;

        GameVars.enemiesData = enemiesData.enemies;
        GameVars.turretsData = turretsData.turrets;

        GameVars.wavesData = GameVars.gameData.tutorialSeen ? wavesData[GameVars.currentWaveId] : tutorialWavesData[0];

        GameManager.generateObstaclesBoard();

        gameConfig = {
            timeStep: GameConstants.TIME_STEP,
            runningInClientSide: true,
            enemySpawningDeltaTicks: GameConstants.ENEMY_SPAWNING_DELTA_TICKS,
            credits: GameConstants.DEVELOPMENT ? GameConstants.INITIAL_CREDITS : GameConstants.INITIAL_CREDITS,
            lifes: GameConstants.INITIAL_LIFES,
            boardSize: GameVars.currentMapData.size,
            enemiesPathCells: GameVars.enemiesPathCells,
            plateausCells: GameVars.plateausCells,
            turretsAvailable: { cannon: true, ballista: true, torch: true, timeWarp: true },
            chestRound: 1e5
        };

        if (GameVars.currentScene === BattleScene.currentInstance || !GameVars.timeStepFactor) {
            GameVars.timeStepFactor = 1;
        }

        GameVars.wave = 1;
        GameVars.paused = false;
        GameVars.semiPaused = false;
        GameVars.waveOver = true;
        GameVars.autoSendWave = false;
        GameVars.loopNumber = 1;
        GameVars.loopRate = 1;
        GameVars.loopVolume = .2;
        GameVars.dangerRate = 1;
        GameVars.gameOver = false;
        GameVars.ballistaUpgraded = false;
        GameVars.torchUpgraded = false;
        GameVars.cannonUpgraded = false;
        GameVars.timeWarpUpgraded = false;

        BattleManager.engine = new Creepts.Engine(gameConfig, GameVars.enemiesData, GameVars.turretsData, GameVars.wavesData);


        BattleManager.setTimeStepFactor(GameVars.timeStepFactor);

        GameVars.levelObject = {
            engineVersion: BattleManager.engine.version,
            gameConfig: gameConfig,
            enemiesData: GameVars.enemiesData,
            turretsData: GameVars.turretsData,
            wavesData: GameVars.wavesData
        };

        BattleScene.currentInstance.start();

        BattleManager.engine.addEventListener(Creepts.Event.ENEMY_SPAWNED, BattleManager.onEnemySpawned, BattleManager);

        if (isResumedGame) {
            BattleManager.isProcessingActions = true;
            BattleManager.processActions();
            setTimeout(() => {
                BattleManager.isProcessingActions = false;
            }, 100);
        }

        BattleManager.addEngineListeners();
    }

    private static processActions(): { error: boolean, result?: EngineReturn } {

        const logs = GameVars.logsObject;
        const engine = BattleManager.engine;

        for (let i = 0; i < logs.actions.length; i++) {

            const action = logs.actions[i];
            let result: EngineReturn = { success: true, error: null };

            if (typeof action.tk !== "number" || action.tk < engine.ticksCounter) {
                return { error: true };
            }

            engine.runningInClientSide = false;
            while (engine.ticksCounter < action.tk && engine.lifes > 0) {
                engine.update();
            }
            engine.runningInClientSide = true;

            switch (action.ty) {
                case ActionTypes.TYPE_NEXT_WAVE:
                    BattleManager.newWave();
                    break;
                case ActionTypes.TYPE_ADD_TURRET:
                    BattleManager.addTurretToScene(action.trT, action.p);
                    break;
                case ActionTypes.TYPE_SELL_TURRET:
                    BattleManager.sellTurret(action.id);
                    break;
                case ActionTypes.TYPE_UPGRADE_TURRET:
                    BattleManager.upgradeTower(action.id);
                    break;
                case ActionTypes.TYPE_LEVEL_UP_TURRET:
                    BattleManager.improveTurret(action.id);
                    break;
                case ActionTypes.TYPE_CHANGE_STRATEGY_TURRET:
                    BattleManager.setNextStrategy(action.id);
                    break;
                case ActionTypes.TYPE_CHANGE_FIXED_TARGET_TURRET:
                    BattleManager.setFixedTarget(action.id);
                    break;
                case ActionTypes.TYPE_FORCE_GAME_OVER:
                    BattleManager.engine.forceGameOver();
                    break;
                default:
                    result = {
                        error: {
                            type: ErrorType.ACTION_TYPE,
                            info: action.ty
                        },
                        success: false
                    };
                    break;
            }
        }
    }
    private static addEngineListeners() {

        BattleManager.engine.addEventListener(Creepts.Event.ENEMY_REACHED_EXIT, BattleManager.onEnemyReachedExit, BattleManager);
        BattleManager.engine.addEventListener(Creepts.Event.BULLET_SHOT, BattleManager.onBulletShot, BattleManager);
        BattleManager.engine.addEventListener(Creepts.Event.GLUE_BULLET_SHOT, BattleManager.onGlueBulletShot, BattleManager);
        BattleManager.engine.addEventListener(Creepts.Event.ENEMY_HIT, BattleManager.onEnemyHit, BattleManager);
        BattleManager.engine.addEventListener(Creepts.Event.REMOVE_BULLET, BattleManager.removeBullet, BattleManager);
        BattleManager.engine.addEventListener(Creepts.Event.REMOVE_GLUE_BULLET, BattleManager.removeGlueBullet, BattleManager);
        BattleManager.engine.addEventListener(Creepts.Event.ENEMY_GLUE_HIT, BattleManager.onEnemyGlueHit, BattleManager);
        BattleManager.engine.addEventListener(Creepts.Event.ENEMY_KILLED, BattleManager.onEnemyKilled, BattleManager);
        BattleManager.engine.addEventListener(Creepts.Event.LASER_SHOT, BattleManager.onLaserBeamShot, BattleManager);
        BattleManager.engine.addEventListener(Creepts.Event.MORTAR_SHOT, BattleManager.onMortarShot, BattleManager);
        BattleManager.engine.addEventListener(Creepts.Event.GLUE_SHOT, BattleManager.onGlueShot, BattleManager);
        BattleManager.engine.addEventListener(Creepts.Event.MINE_SHOT, BattleManager.onMineShot, BattleManager);
        BattleManager.engine.addEventListener(Creepts.Event.GLUE_CONSUMED, BattleManager.onGlueConsumed, BattleManager);
        BattleManager.engine.addEventListener(Creepts.Event.ENEMIES_TELEPORTED, BattleManager.onEnemiesTeleported, BattleManager);
        BattleManager.engine.addEventListener(Creepts.Event.NO_ENEMIES_ON_STAGE, BattleManager.onNoEnemiesOnStage, BattleManager);
        BattleManager.engine.addEventListener(Creepts.Event.WAVE_OVER, BattleManager.onWaveOver, BattleManager);
        BattleManager.engine.addEventListener(Creepts.Event.GAME_OVER, BattleManager.onGameOver, BattleManager);
        BattleManager.engine.addEventListener(Creepts.Event.ACTIVE_NEXT_WAVE, BattleManager.onNextWaveActive, BattleManager);
    }

    public static pause(): void {

        if (!GameVars.semiPaused) {
            GameVars.currentScene.anims.pauseAll();
        }

        GameVars.paused = true;
    }

    public static resume(): void {

        if (!GameVars.semiPaused) {
            GameVars.currentScene.anims.resumeAll();
        }

        if (BattleScene.currentInstance) {
            BattleScene.currentInstance.hidePauseMenu();
        }

        GameVars.paused = false;
    }

    public static semiPause(): void {

        GameVars.currentScene.anims.pauseAll();

        GameVars.semiPaused = true;

        BattleScene.currentInstance.semiPause();
    }

    public static semiResume(): void {
        if (GameVars.semiPaused) {
            GameVars.currentScene.anims.resumeAll();
            GameVars.semiPaused = false;
            BattleScene.currentInstance.semiResume();
        }
    }

    public static setTimeStepFactor(timeStepFactor: number): void {

        GameVars.timeStepFactor = timeStepFactor;

        BattleManager.engine.timeStep = GameConstants.TIME_STEP / timeStepFactor;
    }

    public static newWave(): number {

        const bonus = BattleManager.engine.newWave();

        if (bonus !== null) {

            GameVars.waveOver = false;

            BattleScene.currentInstance.newWave();

            const action = { ty: ActionTypes.TYPE_NEXT_WAVE, tk: BattleManager.engine.ticksCounter };
            BattleManager.addAction(action);
        }

        return bonus;
    }

    public static createTurret(type: string): void {

        if (GameVars.currentScene === BattleScene.currentInstance) {
            BattleScene.currentInstance.createTurret(type);
        }
    }

    public static addTurretToScene(type: string, position: { r: number, c: number }): void {

        BoardContainer.currentInstance.addTurret(type, position);
    }

    public static addTurret(type: string, position: { r: number, c: number }): Creepts.Turret {

        const data = BattleManager.engine.addTurret(type, position);

        if (data.turret) {
            const action = { ty: ActionTypes.TYPE_ADD_TURRET, tk: BattleManager.engine.ticksCounter, trT: data.turret.type, p: position };
            BattleManager.addAction(action);
        }

        return data.turret;
    }

    public static sellTurret(id: number): void {

        if (BattleManager.engine.sellTurret(id).success) {

            let action = { ty: ActionTypes.TYPE_SELL_TURRET, tk: BattleManager.engine.ticksCounter, id: id };
            BattleManager.addAction(action);

            BoardContainer.currentInstance.onTurretSold(id);
        }
    }

    public static onClickMenu(): void {

        BoardContainer.currentInstance.showPauseMenu();
    }

    public static setAutoSendWave(value: boolean): void {

        GameVars.autoSendWave = value;
    }

    public static improveTurret(id: number): void {

        const success = BattleManager.engine.improveTurret(id).success;

        if (success) {

            const action = { ty: ActionTypes.TYPE_LEVEL_UP_TURRET, tk: BattleManager.engine.ticksCounter, id: id };
            BattleManager.addAction(action);

            BoardContainer.currentInstance.improveTurret(id);
        }
    }

    public static upgradeTower(id: number): void {

        const success = BattleManager.engine.upgradeTurret(id).success;

        if (success) {

            let action = { ty: ActionTypes.TYPE_UPGRADE_TURRET, tk: BattleManager.engine.ticksCounter, id: id };
            BattleManager.addAction(action);

            BoardContainer.currentInstance.upgradeTurret(id);

            if (GameVars.currentScene === BattleScene.currentInstance) {
                BattleScene.currentInstance.updateTurretMenu();
            }
        }
    }

    public static setNextStrategy(id: number): void {

        if (BattleManager.engine.setNextStrategy(id).success) {
            const action = { ty: ActionTypes.TYPE_CHANGE_STRATEGY_TURRET, tk: BattleManager.engine.ticksCounter, id: id };
            BattleManager.addAction(action);
        }
    }

    public static setFixedTarget(id: number): void {

        if (BattleManager.engine.setFixedTarget(id).success) {
            const action = { ty: ActionTypes.TYPE_CHANGE_FIXED_TARGET_TURRET, tk: BattleManager.engine.ticksCounter, id: id };
            BattleManager.addAction(action);
        }
    }

    public static createRangeCircle(range: number, x: number, y: number): Phaser.GameObjects.Image {

        return BoardContainer.currentInstance.createRangeCircle(range, x, y);
    }

    public static hideRangeCircles(): void {

        BoardContainer.currentInstance.hideRangeCircles();
    }

    public static showTurretMenu(turret: Creepts.Turret): void {

        BattleScene.currentInstance.showTurretMenu(turret);
    }

    public static hideTurretMenu(): void {

        BattleScene.currentInstance.hideTurretMenu();
    }

    public static setTurretPrePosition(c: number, r: number): void {

        BoardContainer.currentInstance.setTurretPrePosition(c, r);
    }

    public static onDiscardMatch(): void {

        GameManager.reset();
    }

    public static forceFinishDuel(): void {

        if (GameVars.gameOver) {
            return;
        }

        const success = BattleManager.engine.forceGameOver().success;

        if (success) {
            const action = {
                ty: ActionTypes.TYPE_FORCE_GAME_OVER,
                tk: BattleManager.engine.ticksCounter
            };

            BattleManager.addAction(action);

            console.log("SCORE: " + BattleManager.engine.score);

            GameVars.gameOver = true;

            BattleScene.currentInstance.showGameOverLayer();
        }
    }

    private static addAction(action: Action): void {

        if (!BattleManager.isProcessingActions && GameVars.gameData.tutorialSeen) {
            GameVars.logsObject.actions.push(action);
            GameManager.writeGameData();
        }
    }

    private static onEnemySpawned(enemy: Creepts.Enemy, p: { r: number, c: number }): void {

        BoardContainer.currentInstance.addEnemy(enemy, p);
    }

    private static onEnemyReachedExit(enemy: Creepts.Enemy): void {

        BoardContainer.currentInstance.removeEnemy(enemy.id);

        if (!BattleManager.engine.gameOver) {

            GameVars.dangerRate = Math.max(GameVars.dangerRate - .0125, .75);

            BattleScene.currentInstance.onEnemyReachedExit();
        }

        BattleScene.currentInstance.hud.updateLifes();
    }

    private static onBulletShot(bullet: Creepts.Arrow, projectileTurret: Creepts.ProjectileTurret): void {

        BoardContainer.currentInstance.addBullet(bullet, projectileTurret);
    }

    private static onGlueBulletShot(bullet: Creepts.GlueBullet, turret: Creepts.GlueTurret): void {

        BoardContainer.currentInstance.addGlueBullet(bullet, turret);
    }

    private static onLaserBeamShot(laserTurret: Creepts.LaserTurret, enemies: Creepts.Enemy[]): void {

        BoardContainer.currentInstance.addLaserBeam(laserTurret, enemies);
    }

    private static onMortarShot(mortar: Creepts.Mortar, launchTurret: Creepts.LaunchTurret): void {

        BoardContainer.currentInstance.addMortar(mortar, launchTurret);
    }

    private static onGlueShot(glue: Creepts.Glue, turret: Creepts.GlueTurret): void {

        BoardContainer.currentInstance.addGlue(glue, turret);
    }

    private static onMineShot(mine: Creepts.Mine, launchMine: Creepts.LaunchTurret): void {

        BoardContainer.currentInstance.addMine(mine, launchMine);
    }

    private static onGlueConsumed(glue: Creepts.Glue): void {

        BoardContainer.currentInstance.onGlueConsumed(glue);
    }

    private static onEnemyHit(enemies: Creepts.Enemy[], bullet?: Creepts.Arrow, mortar?: Creepts.Mortar, mine?: Creepts.Mine, fire?: boolean, electric?: boolean): void {

        let impactType: string = null;

        if (fire && electric) {
            impactType = GameConstants.IMPACT_FIRE_ELECTRIC;
        } else if (bullet) {
            impactType = GameConstants.IMPACT_ARROW;
        } else if (mortar) {
            impactType = GameConstants.IMPACT_MORTAR;
        } else if (mine) {
            impactType = GameConstants.IMPACT_MINE;
        } else if (fire) {
            impactType = GameConstants.IMPACT_FIRE;
        } else if (electric) {
            impactType = GameConstants.IMPACT_ELECTRIC;
        }

        for (let i = 0; i < enemies.length; i++) {
            BoardContainer.currentInstance.onEnemyHit(enemies[i], impactType);
        }

        if (bullet) {
            BoardContainer.currentInstance.removeBullet(bullet);
        }

        if (mortar) {
            BoardContainer.currentInstance.detonateMortar(mortar);
        }

        if (mine) {
            BoardContainer.currentInstance.detonateMine(mine);
        }
    }

    private static removeBullet(bullet?: Creepts.Arrow) {

        BoardContainer.currentInstance.removeBullet(bullet);
    }

    private static removeGlueBullet(bullet: Creepts.GlueBullet): void {

        BoardContainer.currentInstance.removeGlueBullet(bullet);
    }

    private static onEnemyGlueHit(enemies: Creepts.Enemy[], bullet: Creepts.GlueBullet): void {

        for (let i = 0; i < enemies.length; i++) {
            BoardContainer.currentInstance.onEnemyGlueHit(enemies[i]);
        }

        if (bullet) {
            BoardContainer.currentInstance.removeGlueBullet(bullet);
        }
    }

    private static onEnemiesTeleported(teleportedEnemiesData: { enemy: Creepts.Enemy, glueTurret: Creepts.GlueTurret }[]): void {

        for (let i = 0; i < teleportedEnemiesData.length; i++) {
            BoardContainer.currentInstance.teleportEnemy(teleportedEnemiesData[i].enemy, teleportedEnemiesData[i].glueTurret);
        }
    }

    private static onEnemyKilled(enemy: Creepts.Enemy): void {
        BoardContainer.currentInstance.onEnemyKilled(enemy);

        if (!GameVars.gameData.tutorialSeen) {
            TutorialManager.onEnemyKilled();
        }
    }

    private static onNoEnemiesOnStage(): void {

        if (!BattleManager.engine.gameOver) {
            BoardContainer.currentInstance.showRoundCompletedLayer();
        }
    }

    private static onWaveOver(): void {

        if (GameVars.autoSendWave) {
            BattleScene.currentInstance.boardContainer.onClickNextWave();
        } else {
            GameVars.waveOver = true;
        }

    }

    private static onNextWaveActive(): void {

        GameVars.wave++;

        BattleScene.currentInstance.boardContainer.activeNextWave();
    }

    private static onGameOver(): void {

        if (GameVars.gameOver) {
            return;
        }

        GameVars.gameOver = true;
        GameVars.logsObject = { actions: [] };
        GameManager.writeGameData();
        BattleScene.currentInstance.showGameOverLayer();

        GameVars.simplio.SetScore(GameVars.simplioUserId, BattleManager.engine.score).then(() => {
            console.warn("Score updated");
        });
    }
}
