import { BattleScene } from "./BattleScene";
import { BattleManager } from "./BattleManager";
import { GameVars } from "../../GameVars";
import { GameManager } from "../../GameManager";

export class TutorialManager {


    static isStartedFromSettings: boolean;
    private static state: "waiting_for_placeturret" |
        "waiting_for_tapnewwave" |
        "waiting_for_tap2x" |
        "waiting_for_tap3x" |
        "waiting_for_enemykill" |
        "waiting_for_turretlevelup" |
        "waiting_for_enemykill2" |
        "waiting_for_turretupgrade" |
        "waiting_for_enemykill3" |
        "waiting_for_tapauto" |
        "waiting_for_tapmanual" |
        "waiting_for_tapnewwavehorde" |
        "waiting_for_end" |
        "end";

    public static get State() {
        return this.state;
    }

    public static init(): void {
        TutorialManager.state = "waiting_for_placeturret";

        BattleScene.currentInstance.hideButtonsAtTutorialStart();
    }

    public static onTurredSelected(): void {

        BattleScene.currentInstance.onTutorialTurredSelected();

    }

    public static onTurretPlaced(): void {
        TutorialManager.state = "waiting_for_tapnewwave";
        setTimeout(() => {
            BattleScene.currentInstance.onTutorialTurretPlaced();
        }, 300);
    }

    public static onFirstWaveReleased(): void {
        BattleScene.currentInstance.onFirstWaveReleased();

        TutorialManager.state = "waiting_for_tap2x";
        setTimeout(() => {
            if (TutorialManager.state === "waiting_for_tap2x") {
                BattleManager.semiPause();
            }
        }, 2000);
    }

    public static onTapSpeedMultiplier() {
        if (TutorialManager.state === "waiting_for_tap2x") {
            BattleManager.semiResume();
            BattleScene.currentInstance.onSpeedX2();
            TutorialManager.state = "waiting_for_tap3x";
            setTimeout(() => {
                if (TutorialManager.state === "waiting_for_tap3x") {
                    BattleManager.semiPause();
                }
            }, 1000);
        } else if (TutorialManager.state === "waiting_for_tap3x") {
            BattleManager.semiResume();
            BattleScene.currentInstance.onSpeedX3();
            TutorialManager.state = "waiting_for_enemykill";
        }
    }

    public static onEnemyKilled(): void {
        if (TutorialManager.state == "waiting_for_enemykill") {
            TutorialManager.state = "waiting_for_turretlevelup";
            BattleManager.semiPause();
            BattleScene.currentInstance.readyForTurretLevelUp();
        } else if (TutorialManager.state == "waiting_for_enemykill2") {
            BattleManager.semiPause();
            TutorialManager.state = "waiting_for_turretupgrade";
            BattleScene.currentInstance.showBallistaUpgrade();
        } else if (TutorialManager.state == "waiting_for_enemykill3") {
            BattleScene.currentInstance.showModeButtonAndHandCursor();
            BattleScene.currentInstance.onShowAutoModeSelection();
            TutorialManager.state = "waiting_for_tapauto";
        }
    }

    public static hideTutorialLayer(): void {
        BattleScene.currentInstance.hideTutorialLayer();
    }

    public static onTurretLevelUp(): void {
        BattleScene.currentInstance.onTurretLevelUp();
        TutorialManager.state = "waiting_for_enemykill2";
        BattleManager.semiResume();
        setTimeout(() => {
            BattleScene.currentInstance.hideTurretMenu();
        }, 100);
    }

    public static onBallistaUpgraded(): void {
        BattleScene.currentInstance.onBallistaUpgraded();
        TutorialManager.state = "waiting_for_enemykill3";
        BattleManager.semiResume();
        setTimeout(() => {
            BattleScene.currentInstance.hideTurretMenu();
        }, 100);
    }

    public static onModeChanged() {
        if (TutorialManager.state === "waiting_for_tapauto") {
            BattleScene.currentInstance.onModeChangedToAuto();
            setTimeout(() => {
                BattleScene.currentInstance.showModeButtonAndHandCursor();
                BattleScene.currentInstance.onShowManualModeSelection();
            }, 100);
            TutorialManager.state = "waiting_for_tapmanual";
        } else if (TutorialManager.state == "waiting_for_tapmanual") {
            BattleScene.currentInstance.onModeChangedToManual();
            TutorialManager.state = "waiting_for_tapnewwavehorde";
            setTimeout(() => {
                BattleScene.currentInstance.showNextWaveButtonForHorde();
            }, 500);
        }
    }


    public static onHordeReleased() {
        BattleScene.currentInstance.onHordeWaveReleased();
        TutorialManager.state = "waiting_for_end";
        setTimeout(() => {
            if (TutorialManager.state !== 'end') {
                TutorialManager.state = "end";
                BattleScene.currentInstance.onTutorialOver();
            }
        }, 5000);
    }



    public static onTutorialOver(): void {

        GameVars.gameData.tutorialSeen = true;
        GameVars.gameData.tutorialSeenAtleastOnce = true;
        TutorialManager.isStartedFromSettings = false;
        GameVars.logsObject = { actions: [] };
        GameManager.writeGameData(() => {
            GameManager.readGameData(true);
        });
    }
}
