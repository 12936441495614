import { AudioManager } from "./../../../AudioManager";
import { TurretActor } from "./TurretActor";
import * as Creepts from "endless-siege-engine";
import { GameVars } from "../../../GameVars";
import { TurretType } from "endless-siege-engine/build/main/lib/Types";

export class ProjectileTurretActor extends TurretActor {

    constructor(scene: Phaser.Scene, position: {r: number, c: number}, turret: Creepts.Turret) {

        super(scene, TurretType.PROJECTILE, position, turret);

        this.setScale(.8);

        this.base = new Phaser.GameObjects.Image(this.scene, 0, -10, "texture_atlas_1", (GameVars.ballistaUpgraded) ? "archer_upgraded_1_base" : "archer_1_base");
        this.base.setInteractive({ useHandCursor: true });
        this.base.on("pointerdown", this.onDownTurret, this);
        this.addAt(this.base, 0);

        this.canon = new Phaser.GameObjects.Sprite(this.scene, 0, -20, "texture_atlas_1", "archer_1_top");
        this.add(this.canon);

        this.canonInitialY = -20;
        this.canon.rotation = this.canonPrevRotation;
    }

    public setCanonRotation(dx: number, dy: number): void {

        super.setCanonRotation(dx, dy);

        this.canon.rotation = this.canonPrevRotation;
    }

    public upgrade(): void {

        super.upgrade();

        switch (this.turret.grade) {
 
            case 2:
                this.base.setFrame((GameVars.ballistaUpgraded) ? "archer_upgraded_2_base" : "archer_2_base");
                this.canon.setFrame("archer_2_top");
                this.base.y = -18;
                this.canonInitialY = -32;
                break;
            case 3: 
                this.base.setFrame((GameVars.ballistaUpgraded) ? "archer_upgraded_3_base" : "archer_3_base");
                this.canon.setFrame("archer_3_top");
                this.base.y = -22;
                this.canonInitialY = -32;
                break;
            default:
        }

        this.canon.y = this.canonInitialY;
    }

    public shootArrow(): void {
        
        this.scene.tweens.add({
            targets: this.canon,
            x: - 5 * Math.cos(this.canon.rotation),
            y: - 5 * Math.sin(this.canon.rotation) + this.canonInitialY,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: GameVars.timeStepFactor === 1 ? 40 : 10,
            onComplete: () => {
                
                if (!this.scene) {
                    return;
                }
                
                this.scene.tweens.add({
                    targets: this.canon,
                    x: 0,
                    y: this.canonInitialY,
                    ease: Phaser.Math.Easing.Cubic.Out,
                    duration: GameVars.timeStepFactor === 1 ? 40 : 10
                });
            },
            onCompleteScope: this
        });

        AudioManager.playSoundEffect("ballista");
    }
}
