import "howler";
import "phaser";
import { Game } from "./Game";
import { GameConstants } from "./GameConstants";
import { BootScene } from "./scenes/BootScene";
import { PreloadScene } from "./scenes/PreloadScene";
import { BattleScene } from "./scenes/battle-scene/BattleScene";
import { SplashScene } from "./scenes/splash-scene/SplashScene";
import { GameManager } from "./GameManager";
import { GraphicsScene } from "./scenes/graphics/GraphicsScene";

let game: Game;

window.onload = () => {

    const gameConfig = {

        version: GameConstants.VERSION,
        type: Phaser.AUTO,
        backgroundColor: "#000000",
        scale: {
            mode: Phaser.Scale.FIT,
            autoCenter: Phaser.Scale.CENTER_BOTH,
            parent: "content",
            width: GameConstants.GAME_WIDTH,
            height: GameConstants.GAME_HEIGHT
        },
        scene: [
            BootScene,
            PreloadScene,
            SplashScene,
            BattleScene,
            GraphicsScene
        ]
    };

    game = new Game(gameConfig);

    window.focus();
};

function onOrientationChange(): void {

    setTimeout(() => {

        const w = window.innerWidth;
        const h = window.innerHeight;

        let isPortrait = w < h;

        if (isPortrait) {

            if (BootScene.bootedInWrongOrientation) {
                BootScene.bootedInWrongOrientation = false;
                GameManager.init();
            }

            document.getElementById("orientation").style.display = "none";
            document.getElementById("content").style.display = "block";

        } else {
            document.getElementById("orientation").style.display = "block";
            document.getElementById("content").style.display = "none";
        }
    }, 250);
}

function resize(): void {

    const canvas = document.querySelector("canvas");
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const windowRatio = windowWidth / windowHeight;

    const gameWidth: any = game.config.width;
    const gameHeight: any = game.config.height;

    const gameRatio = gameWidth / gameHeight;

    if (windowRatio < gameRatio) {
        canvas.style.width = windowWidth + "px";
        canvas.style.height = (windowWidth / gameRatio) + "px";
    } else {
        canvas.style.width = (windowHeight * gameRatio) + "px";
        canvas.style.height = windowHeight + "px";
    }
}
