import { AudioManager } from "../../AudioManager";
import { GameConstants } from "../../GameConstants";
import { GameManager } from "../../GameManager";
import { GameVars } from "../../GameVars";
import { BattleManager } from "./BattleManager";
import { TutorialManager } from "./TutorialManager";

export class SplashMenu extends Phaser.GameObjects.Container {

    private musicLabel: Phaser.GameObjects.Text;
    private soundsLabel: Phaser.GameObjects.Text;
    private settingsContainer: Phaser.GameObjects.Container;
    private fullscreenLabel: Phaser.GameObjects.Text;
    private isFullscreen: boolean;

    constructor(scene: Phaser.Scene) {

        super(scene);

        this.y = -200;

        const bg = new Phaser.GameObjects.Rectangle(this.scene, 0, 200, GameConstants.GAME_WIDTH, GameConstants.GAME_HEIGHT, 0, 0.5);
        this.add(bg);
        bg.setInteractive();
        bg.once(Phaser.Input.Events.GAMEOBJECT_POINTER_UP, () => {
            this.destroy();
        }, this);

        const menuBackgroundCenter = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "layout_box_3");
        menuBackgroundCenter.setOrigin(.5, 0);
        menuBackgroundCenter.scaleY = GameVars.currentScene.game.device.os.desktop ? 8.5 : 7.5;
        this.add(menuBackgroundCenter);
        menuBackgroundCenter.setInteractive();

        const menuBackgroundBottom = new Phaser.GameObjects.Image(this.scene, 0, menuBackgroundCenter.height * menuBackgroundCenter.scaleY, "texture_atlas_1", "layout_box_4");
        menuBackgroundBottom.setOrigin(.5, 0);
        this.add(menuBackgroundBottom);

        const blueBanner = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "title_area");
        blueBanner.scaleX = .8;
        blueBanner.scaleY = .8;
        this.add(blueBanner);

        const menuTitle = new Phaser.GameObjects.Text(this.scene, 0, -7, GameVars.gameText[GameVars.gameData.language].PAUSE_MENU_TITLE, { fontFamily: "Supercell", fontSize: "26px", color: "#FFFFFF" });
        menuTitle.setOrigin(.5);
        menuTitle.setStroke("#000000", 6);
        menuTitle.setShadow(4, 4, "#000000");
        this.add(menuTitle);

        this.settingsContainer = new Phaser.GameObjects.Container(this.scene);
        this.add(this.settingsContainer);

        const buttonContainer = new Phaser.GameObjects.Container(this.scene, 0, 100);
        this.settingsContainer.add(buttonContainer);

        const tutorialButton = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "tutorial_btn_bg");
        tutorialButton.setInteractive({ useHandCursor: true });
        tutorialButton.on("pointerover", () => {
            buttonContainer.setScale(1.025);
        });
        tutorialButton.on("pointerout", () => {
            buttonContainer.setScale(1);
        });
        tutorialButton.on("pointerdown", this.onDownTutorialButton, this);
        buttonContainer.add(tutorialButton);

        const tutorialText = new Phaser.GameObjects.Text(this.scene, 0, 0, GameVars.gameText[GameVars.gameData.language].TUTORIAL, { fontFamily: "Supercell", fontSize: "18px", color: "#FFFFFF" });
        tutorialText.setOrigin(.5);
        tutorialText.setStroke("#000000", 6);
        tutorialText.setShadow(3, 3, "#000000");
        buttonContainer.add(tutorialText);

        // EL FONDO PARA LOS BOTONES DE CONTROL DEL AUDIO
        const bck = new Phaser.GameObjects.Graphics(this.scene);
        bck.setPosition(0, 255);
        bck.fillStyle(0xAAAAAA, .35);
        bck.fillRoundedRect(-165, -75, 330, 150, 10);
        this.settingsContainer.add(bck);

        // lOS BOTONES DE AUDIO
        const musicButtonContainer = new Phaser.GameObjects.Container(this.scene, 0, 220);
        this.settingsContainer.add(musicButtonContainer);

        const musicButton = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "btn_info");
        musicButton.setInteractive({ useHandCursor: true });
        musicButton.on("pointerdown", this.onDownMusicButton, this);
        musicButton.on("pointerover", () => {

            if (this.alpha !== 1) {
                return;
            }

            musicButtonContainer.setScale(1.025);
        }, this);
        musicButton.on("pointerout", () => {

            if (this.alpha !== 1) {
                return;
            }

            musicButtonContainer.setScale(1);
        }, this);
        musicButtonContainer.add(musicButton);

        const musicButtonText = new Phaser.GameObjects.Text(this.scene, -130, 0, GameVars.gameText[GameVars.gameData.language].MUSIC, { fontFamily: "Supercell", fontSize: "20px", color: "#FFFFFF" });
        musicButtonText.setOrigin(0, .5);
        musicButtonText.setStroke("#000000", 4);
        musicButtonText.setShadow(3, 3, "#000000");
        musicButtonContainer.add(musicButtonText);

        this.musicLabel = new Phaser.GameObjects.Text(this.scene, 92, 0, GameVars.gameText[GameVars.gameData.language].OFF, { fontFamily: "Supercell", fontSize: "20px", color: "#FFFFFF" });
        this.musicLabel.setOrigin(.5);
        this.musicLabel.setStroke("#000000", 4);
        this.musicLabel.setShadow(3, 3, "#000000");
        musicButtonContainer.add(this.musicLabel);

        const soundsButtonContainer = new Phaser.GameObjects.Container(this.scene, 0, 290);
        this.settingsContainer.add(soundsButtonContainer);

        const soundsButton = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "btn_info");
        soundsButton.setInteractive({ useHandCursor: true });
        soundsButton.on("pointerdown", this.onDownSoundsButton, this);
        soundsButton.on("pointerover", () => {

            if (this.alpha !== 1) {
                return;
            }

            soundsButtonContainer.setScale(1.025);
        }, this);
        musicButton.on("pointerout", () => {

            if (this.alpha !== 1) {
                return;
            }

            soundsButtonContainer.setScale(1);
        }, this);
        soundsButtonContainer.add(soundsButton);

        const soundsButtonText = new Phaser.GameObjects.Text(this.scene, -130, 0, GameVars.gameText[GameVars.gameData.language].SOUNDS, { fontFamily: "Supercell", fontSize: "20px", color: "#FFFFFF" });
        soundsButtonText.setOrigin(0, .5);
        soundsButtonText.setStroke("#000000", 4);
        soundsButtonText.setShadow(3, 3, "#000000");
        soundsButtonContainer.add(soundsButtonText);

        this.soundsLabel = new Phaser.GameObjects.Text(this.scene, 92, 0, GameVars.gameText[GameVars.gameData.language].OFF, { fontFamily: "Supercell", fontSize: "20px", color: "#FFFFFF" });
        this.soundsLabel.setOrigin(.5);
        this.soundsLabel.setStroke("#000000", 4);
        this.soundsLabel.setShadow(3, 3, "#000000");
        soundsButtonContainer.add(this.soundsLabel);

        this.setAudioLabels();

        this.setAlpha(.7);

        this.scene.tweens.add({
            targets: this,
            scaleX: 1,
            scaleY: 1,
            alpha: 1,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 250
        });

        // Button fullscreen
        if (GameVars.currentScene.game.device.os.desktop) {

            const fullscreenButtonContainer = new Phaser.GameObjects.Container(this.scene, 0, 375);
            this.settingsContainer.add(fullscreenButtonContainer);

            const fullscreenButton = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "btn_info");
            fullscreenButton.setInteractive({ useHandCursor: true });
            fullscreenButton.on("pointerdown", this.onDownFullscreenButton, this);
            fullscreenButton.on("pointerover", () => {

                if (this.alpha !== 1) {
                    return;
                }

                fullscreenButtonContainer.setScale(1.025);
            }, this);
            fullscreenButton.on("pointerout", () => {

                if (this.alpha !== 1) {
                    return;
                }

                fullscreenButtonContainer.setScale(1);
            }, this);
            fullscreenButtonContainer.add(fullscreenButton);

            const fullscreenButtonText = new Phaser.GameObjects.Text(this.scene, -130, 0, GameVars.gameText[GameVars.gameData.language].FULLSCREEN, { fontFamily: "Supercell", fontSize: "20px", color: "#FFFFFF" });
            fullscreenButtonText.setOrigin(0, .5);
            fullscreenButtonText.setStroke("#000000", 4);
            fullscreenButtonText.setShadow(3, 3, "#000000");
            fullscreenButtonContainer.add(fullscreenButtonText);

            this.fullscreenLabel = new Phaser.GameObjects.Text(this.scene, 92, 0, GameVars.gameText[GameVars.gameData.language].OFF, { fontFamily: "Supercell", fontSize: "20px", color: "#FFFFFF" });
            this.fullscreenLabel.setOrigin(.5);
            this.fullscreenLabel.setStroke("#000000", 4);
            this.fullscreenLabel.setShadow(3, 3, "#000000");
            fullscreenButtonContainer.add(this.fullscreenLabel);

            this.isFullscreen = GameVars.currentScene.scale.isFullscreen;
            this.updateFullscreenLabel();
        }
    }

    public update(): void {

        if (GameVars.currentScene.game.device.os.desktop && this.isFullscreen !== GameVars.currentScene.scale.isFullscreen) {
            this.isFullscreen = !this.isFullscreen;
            if (!this.isFullscreen) {
                GameManager.resize();
            }
            this.updateFullscreenLabel();
        }
    }

    private onDownTutorialButton(): void {
        AudioManager.playSoundEffect("btn_click");
        GameVars.currentMapId = 1;
        GameVars.logsObject = { actions: [] };
        TutorialManager.isStartedFromSettings = true;
        GameVars.gameData.tutorialSeen = false;
        GameManager.writeGameData(() => {
            GameManager.readGameData(true);
        });
        this.destroy();
    }

    private onDownMusicButton(): void {

        AudioManager.toggleMusicState();

        this.setAudioLabels();

        AudioManager.playSoundEffect("btn_click");
    }

    private onDownFullscreenButton(): void {

        if (GameVars.currentScene.scale.isFullscreen) {
            GameVars.currentScene.scale.stopFullscreen();
            this.isFullscreen = false;
            GameManager.resize();
        } else {
            GameVars.currentScene.scale.startFullscreen();
            this.isFullscreen = true;
        }
        this.updateFullscreenLabel();
        AudioManager.playSoundEffect("btn_click");
    }

    private updateFullscreenLabel(): void {

        if (!this.isFullscreen) {
            this.fullscreenLabel.text = GameVars.gameText[GameVars.gameData.language].OFF;
            this.fullscreenLabel.setColor("#FE0044");
        } else {
            this.fullscreenLabel.text = GameVars.gameText[GameVars.gameData.language].ON;
            this.fullscreenLabel.setColor("#32CB00");
        }
    }


    private onDownSoundsButton(): void {

        AudioManager.toggleSoundState();

        this.setAudioLabels();

        AudioManager.playSoundEffect("btn_click");
    }

    private setAudioLabels(): void {

        if (GameVars.gameData.musicMuted) {
            this.musicLabel.text = GameVars.gameText[GameVars.gameData.language].OFF;
            this.musicLabel.setColor("#FE0044");
        } else {
            this.musicLabel.text = GameVars.gameText[GameVars.gameData.language].ON;
            this.musicLabel.setColor("#32CB00");
        }

        if (GameVars.gameData.soundMuted) {
            this.soundsLabel.text = GameVars.gameText[GameVars.gameData.language].OFF;
            this.soundsLabel.setColor("#FE0044");
        } else {
            this.soundsLabel.text = GameVars.gameText[GameVars.gameData.language].ON;
            this.soundsLabel.setColor("#32CB00");
        }
    }

    private createFinishDuelButton(): void {

        const finishButtonContainer = new Phaser.GameObjects.Container(this.scene, 0, 100);
        this.settingsContainer.add(finishButtonContainer);

        const finishDuelButton = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "btn_yellow_wide");
        finishDuelButton.setInteractive({ useHandCursor: true });
        finishDuelButton.on("pointerover", () => {
            finishButtonContainer.setScale(1.025);
        });
        finishDuelButton.on("pointerout", () => {
            finishButtonContainer.setScale(1);
        });
        finishDuelButton.on("pointerdown", this.onDownFinishButton, this);
        finishButtonContainer.add(finishDuelButton);

        const finishDuelText = new Phaser.GameObjects.Text(this.scene, 0, 0, GameVars.gameText[GameVars.gameData.language].FINISH, { fontFamily: "Supercell", fontSize: "18px", color: "#FFFFFF" });
        finishDuelText.setOrigin(.5);
        finishDuelText.setStroke("#000000", 6);
        finishDuelText.setShadow(3, 3, "#000000");
        finishButtonContainer.add(finishDuelText);
    }

    private onDownFinishButton(): void {

        BattleManager.forceFinishDuel();
        AudioManager.playSoundEffect("btn_click");
    }
}
