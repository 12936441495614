import { ArrowActor } from "./ArrowActor";
import * as Creepts from "endless-siege-engine";
import { BattleScene } from "../BattleScene";


export class ArrowActorsPool {

    private arrowActors: ArrowActor[];
    private scene: Phaser.Scene;

    constructor(scene: Phaser.Scene, numArrows: number) {
        
        this.scene = scene;

        this.scene = scene;
        this.arrowActors = [];

        for (let i = 0; i < numArrows; i++) {
            
            const arrowActor = new ArrowActor(this.scene);
            this.arrowActors.push(arrowActor);
        }
    }

    public getArrow(bullet: Creepts.Arrow): ArrowActor {

        if (this.arrowActors.length === 0) {
            this.increasePoolSize();
        }

        const arrowActor = this.arrowActors.pop();
        arrowActor.reset(bullet);

        return arrowActor;
    }

    public onArrowRemovedFromBoard(arrowActor: ArrowActor): void {
        this.arrowActors.push(arrowActor);
    }

    private increasePoolSize(): void  {

        for (let i = 0; i < 5; i ++) {
            const arrowActor = new ArrowActor(this.scene);
            this.arrowActors.push(arrowActor);
        }
    }
}
