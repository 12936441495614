export class GameConstants {

    public static readonly VERSION = "0.0";
    public static readonly DEVELOPMENT = false;
    public static readonly SHOW_DEBUG_GEOMETRY = false;
    public static readonly INTERPOLATE_TRAJECTORIES = true;
    public static readonly VERBOSE = false;
    public static readonly GAME_WIDTH = 768;
    public static readonly GAME_HEIGHT = 1024;
    public static readonly DOWNLOAD_FILES = false;
    public static readonly TIME_STEP = 100;
    public static readonly ENEMY_SPAWNING_DELTA_TICKS = 10;
    public static readonly INITIAL_CREDITS = 500; 
    public static readonly INITIAL_LIFES = 20; 
    public static readonly CELLS_SIZE = 60;
    public static readonly ENEMY_FRAMERATE = 18;
    public static readonly LANGUAGE_ENGLISH = "en";

    public static readonly DUEL_MAP_OBSTACLES = [
        [
            {value: 8, pct: 5},
            {value: 10, pct: 17},
            {value: 1, pct: 34},
            {value: 14, pct: 15},
            {value: 6, pct: 7},
            {value: 13, pct: 6},
            {value: 3, pct: 13},
            {value: 22, pct: 3}
        ],
        [
            {value: 17, pct: 36},
            {value: 18, pct: 20},
            {value: 21, pct: 14},
            {value: 2, pct: 14},
            {value: 9, pct: 6},
            {value: 14, pct: 10}
        ],
        [
            {value: 17, pct: 33},
            {value: 22, pct: 2},
            {value: 9, pct: 5},
            {value: 23, pct: 18},
            {value: 16, pct: 3},
            {value: 18, pct: 33},
            {value: 8, pct: 6}
        ],
        [
            {value: 7, pct: 5},
            {value: 5, pct: 22},
            {value: 15, pct: 36},
            {value: 4, pct: 21},
            {value: 19, pct: 7},
            {value: 12, pct: 5},
            {value: 22, pct: 4}
        ]
    ];

    // TIPO DE IMPACTOS
    public static readonly IMPACT_ARROW = "impact arrow";
    public static readonly IMPACT_MORTAR = "impact mortar";
    public static readonly IMPACT_MINE = "impact mine";
    public static readonly IMPACT_FIRE = "impact fire";
    public static readonly IMPACT_ELECTRIC = "impact electric";
    public static readonly IMPACT_FIRE_ELECTRIC = "impact fire electric";

    public static readonly SAVED_GAME_DATA_KEY = "endless-siege-data";
}
