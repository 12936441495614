import { BattleManager } from "../BattleManager";
import { GameVars } from "../../../GameVars";

export class Score extends Phaser.GameObjects.Container {

    public scoreText: Phaser.GameObjects.Text;
    private scoreLabel: Phaser.GameObjects.Text;

    constructor(scene: Phaser.Scene) {

        super(scene);

        this.y = 35;
        this.x = 90;

        const bckLeft = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "box_big_2");
        bckLeft.scaleX = -1;
        bckLeft.setOrigin(0, .5);
        this.add(bckLeft);

        const bckMid = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "box_big_1");
        bckMid.setOrigin(0, .5);
        bckMid.scaleX = 9.2;
        this.add(bckMid);

        const bckRight = new Phaser.GameObjects.Image(this.scene, bckMid.width * bckMid.scaleX, 0, "texture_atlas_1", "box_big_2");
        bckRight.setOrigin(0, .5);
        this.add(bckRight);

        this.scoreLabel = new Phaser.GameObjects.Text(this.scene, (bckMid.width * bckMid.scaleX) + 5, bckRight.y + bckRight.displayHeight / 2 - 5, GameVars.gameText[GameVars.gameData.language].POINTS, { fontFamily: "Supercell", fontSize: "11px", color: "#ffffff" });
        this.scoreLabel.setOrigin(1);
        this.add(this.scoreLabel);

        this.scoreText = new Phaser.GameObjects.Text(this.scene, (bckMid.width * bckMid.scaleX) / 2, -1, GameVars.formatNumber(BattleManager.engine.score), { fontFamily: "Supercell", fontSize: "32px", color: "#FFE734" });
        this.scoreText.setOrigin(.5);
        this.scoreText.setStroke("#B35B20", 8);
        this.scoreText.setShadow(3, 3, "#700000");
        this.add(this.scoreText);

        if (GameVars.gameOver) {

            const scoreLabelBack = new Phaser.GameObjects.Text(this.scene, (bckMid.width * bckMid.scaleX) / 2, -1, GameVars.formatNumber(BattleManager.engine.score), { fontFamily: "Supercell", fontSize: "32px", color: "#FFE734" });
            scoreLabelBack.setOrigin(.5);
            scoreLabelBack.setStroke("#B35B20", 8);
            scoreLabelBack.setShadow(3, 3, "#700000", 0, true, false);
            this.addAt(scoreLabelBack, 3);

            this.scene.tweens.add({
                targets: scoreLabelBack,
                scaleX: 1.35,
                scaleY: 1.45,
                alpha: 0,
                ease: Phaser.Math.Easing.Cubic.InOut,
                duration: 1250,
                yoyo: false,
                repeat: -1
            });
        }
    }

    hideLabel() {
        this.scoreLabel.setVisible(false);
    }


}
