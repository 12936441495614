import { GameManager } from "../../GameManager";
import { GameVars } from "../../GameVars";

export class ResultsLayer extends Phaser.GameObjects.Container {

    private timeText: Phaser.GameObjects.Text;

    constructor(scene: Phaser.Scene) {

        super(scene);

        const box = new Phaser.GameObjects.Image(this.scene, 0, 40, "texture_atlas_1", "box_results");
        this.add(box);

        const highscoreText = new Phaser.GameObjects.Text(this.scene, 0, 20, GameVars.gameText[GameVars.gameData.language].BEST_SCORE, { fontFamily: "Supercell", fontSize: "16px", color: "#FFE734" });
        highscoreText.setOrigin(.5);
        highscoreText.setStroke("#B35B20", 4);
        highscoreText.setShadow(1, 2, "#700000", 0, true, false);
        this.add(highscoreText);

        const highscoreValue = new Phaser.GameObjects.Text(this.scene, 0, 54, GameVars.formatNumber(GameVars.gameData.scores[GameVars.currentMapId]), { fontFamily: "Supercell", fontSize: "25px", color: "#FFE734" });
        highscoreValue.setOrigin(.5);
        highscoreValue.setStroke("#B35B20", 6);
        highscoreValue.setShadow(2, 3, "#700000", 0, true, false);
        this.add(highscoreValue);

        this.timeText = new Phaser.GameObjects.Text(this.scene, -150, 120, GameVars.gameText[GameVars.gameData.language].NEW_MAP_IN + "\n ", { fontFamily: "Supercell", fontSize: "18px", color: "#6ceeff", align: "center" });
        this.timeText.setOrigin(0, .5);
        this.timeText.setStroke("#30608f", 5);
        this.timeText.setShadow(2, 2, "#1d3956", 0, true, false);
        this.add(this.timeText);

        this.scene.sys.updateList.add(this);
    }

    public preUpdate(time: number, delta: number): void {

        let totalSeconds = GameManager.getTimeUntilEndOfDay();

        const hours = Math.floor(totalSeconds / 3600);
        totalSeconds %= 3600;
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;

        this.timeText.setText(GameVars.gameText[GameVars.gameData.language].NEW_MAP_IN + " " + hours + "h " + minutes + "m " + seconds + "s\n ");
    }
}
