
import { GameVars } from "./../../GameVars";
import { GameConstants } from "../../GameConstants";
import { BattleManager } from "./BattleManager";

export class FxEnemyTraspass extends Phaser.GameObjects.Container {

    constructor(scene: Phaser.Scene) {
        
        super(scene);

        if (!this.scene.game.device.os.desktop) {
            try {
                window.navigator.vibrate(200);
            } catch (error) {
                console.warn(error);
            }
        }

        const left = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "fx_enemy_traspass");
        left.setOrigin(1, 0);
        left.setScale(GameConstants.GAME_HEIGHT / left.width, .5);
        left.setAngle(-90);
        this.add(left);

        const right = new Phaser.GameObjects.Image(this.scene, GameConstants.GAME_WIDTH, 0, "texture_atlas_1", "fx_enemy_traspass");
        right.setOrigin(1, 0);
        right.setScale(GameConstants.GAME_HEIGHT  / left.width, -.5);
        right.setAngle(-90);
        this.add(right);

        const top = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "fx_enemy_traspass");
        top.setOrigin(0);
        top.setScale(GameConstants.GAME_WIDTH / left.width, .5);
        this.add(top);

        const bottom = new Phaser.GameObjects.Image(this.scene, 0, GameConstants.GAME_HEIGHT, "texture_atlas_1", "fx_enemy_traspass");
        bottom.setOrigin(0);
        bottom.setScale(GameConstants.GAME_WIDTH / left.width, -.5);
        this.add(bottom);

        let duration = GameVars.timeStepFactor === 1 ? 800 : 200;

        if (BattleManager.engine.noEnemiesOnStage) {
            duration *= 4;
        }

        this.scene.tweens.add({
            targets: this,
            alpha: 0,
            ease: Phaser.Math.Easing.Cubic.In,
            duration: duration,
            onComplete: function(): void {
                this.destroy();
            },
            onCompleteScope: this
        });
    }
}
