import { GameConstants } from "./../../GameConstants";
import { BoardContainer } from "./board-container/BoardContainer";
import { BattleManager } from "./BattleManager";
import { GameVars } from "./../../GameVars"
import { AudioManager } from "../../AudioManager";

export class RoundCompletedLayer extends Phaser.GameObjects.Container {

    constructor(scene: Phaser.Scene) {
        
        super(scene);

        const titleBck = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "title_area");
        this.add(titleBck);

        this.y = GameConstants.GAME_HEIGHT / 2 - 200;

        const score = new Phaser.GameObjects.Text(this.scene, 0, -10, GameVars.gameText[GameVars.gameData.language].ROUND_MINUS + BattleManager.engine.round + GameVars.gameText[GameVars.gameData.language].COMPLETE_MINUS, {fontFamily: "Supercell", fontSize: "25px", color: "#FFFFFF"});
        score.setOrigin(.5);
        score.setStroke("#000000", 6);
        score.setShadow(4, 4, "#000000");
        this.add(score);

        this.alpha = 0;

        this.scene.tweens.add({
            targets: this,
            alpha: 1,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 500
        });

        this.scene.tweens.add({
            targets: this,
            alpha: 0,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 500,
            delay: 2000,
            onComplete: () => {
                BoardContainer.currentInstance.hideRoundCompletedLayer();
            },
            onCOmpleteScope: this
        });

        AudioManager.playSoundEffect("wave_complete");
    }
}
