import { GameConstants } from "../../../GameConstants";
import { BattleManager } from "../BattleManager";

export class LifesContainer extends Phaser.GameObjects.Container {

    public lifesLabel: Phaser.GameObjects.Text;

    private heartIcon: Phaser.GameObjects.Image;

    constructor(scene: Phaser.Scene) {

        super(scene);

        this.x = GameConstants.GAME_WIDTH - 100;
        this.y = 35;

        const bckMid = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "box_small_2");
        bckMid.setOrigin(0, .5);
        bckMid.scaleX = 3.5;
        this.add(bckMid);

        const bckRight = new Phaser.GameObjects.Image(this.scene, bckMid.width * bckMid.scaleX, 0, "texture_atlas_1", "box_small_1");
        bckRight.setOrigin(0, .5);
        this.add(bckRight);

        this.lifesLabel = new Phaser.GameObjects.Text(this.scene, 52, -1, BattleManager.engine.lifes.toString(), {fontFamily: "Supercell", fontSize: "22px", color: "#FFFFFF"});
        this.lifesLabel.setOrigin(.5);
        this.lifesLabel.setStroke("#000000", 4);
        this.lifesLabel.setShadow(3, 3, "#000000");
        this.add(this.lifesLabel);

        this.heartIcon = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "info_icon_life");
        this.add(this.heartIcon);
    }

    public onEnemyReachedExit(): void {

        this.heartIcon.setScale(1.25);

        this.scene.time.delayedCall(120, function(): void {
            this.heartIcon.setScale(1);
        }, [], this);
    }
}
