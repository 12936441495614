import { AudioManager } from "./../../AudioManager";
import { GameManager } from "../../GameManager";
import { GameVars } from "../../GameVars";
import { BattleManager } from "./BattleManager";

export class PauseMenu extends Phaser.GameObjects.Container {

    private musicLabel: Phaser.GameObjects.Text;
    private soundsLabel: Phaser.GameObjects.Text;
    private settingsContainer: Phaser.GameObjects.Container;
    private quitContainer: Phaser.GameObjects.Container;
    private fullscreenLabel: Phaser.GameObjects.Text;
    private isFullscreen: boolean;

    constructor(scene: Phaser.Scene) {
        
        super(scene);

        this.y = -200;

        const menuBackgroundCenter = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "layout_box_3");
        menuBackgroundCenter.setOrigin(.5, 0);
        menuBackgroundCenter.scaleY = GameVars.currentScene.game.device.os.desktop ? 8.5 : 7.5;
        this.add(menuBackgroundCenter);

        const menuBackgroundBottom = new Phaser.GameObjects.Image(this.scene, 0, menuBackgroundCenter.height * menuBackgroundCenter.scaleY, "texture_atlas_1", "layout_box_4");
        menuBackgroundBottom.setOrigin(.5, 0);
        this.add(menuBackgroundBottom);

        const blueBanner = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "title_area");
        blueBanner.scaleX = .8;
        blueBanner.scaleY = .8;
        this.add(blueBanner);

        const menuTitle = new Phaser.GameObjects.Text(this.scene, 0, -7, GameVars.gameText[GameVars.gameData.language].PAUSE_MENU_TITLE, {fontFamily: "Supercell", fontSize: "26px", color: "#FFFFFF"});
        menuTitle.setOrigin(.5);
        menuTitle.setStroke("#000000", 6);
        menuTitle.setShadow(4, 4, "#000000");
        this.add(menuTitle);

        this.settingsContainer = new Phaser.GameObjects.Container(this.scene);
        this.add(this.settingsContainer);

        const buttonContainer = new Phaser.GameObjects.Container(this.scene, 0, 100);
        this.settingsContainer.add(buttonContainer);

        const restartButton = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "btn_red_wide");
        restartButton.setInteractive({ useHandCursor: true });
        restartButton.on("pointerover", () => { 
            buttonContainer.setScale(1.025);   
        });
        restartButton.on("pointerout", () => { 
            buttonContainer.setScale(1);  
        });
        restartButton.on("pointerdown", this.onDownResetButton, this);
        buttonContainer.add(restartButton);
        
        const restartText = new Phaser.GameObjects.Text(this.scene, 0, 0, GameVars.gameText[GameVars.gameData.language].RESTART, {fontFamily: "Supercell", fontSize: "18px", color: "#FFFFFF"});
        restartText.setOrigin(.5);
        restartText.setStroke("#000000", 6);
        restartText.setShadow(3, 3, "#000000");
        buttonContainer.add(restartText);
            
        // EL FONDO PARA LOS BOTONES DE CONTROL DEL AUDIO
        const bck = new Phaser.GameObjects.Graphics(this.scene);
        bck.setPosition(0, 255);
        bck.fillStyle(0xAAAAAA, .35);
        bck.fillRoundedRect(-165, -75, 330, 150, 10);
        this.settingsContainer.add(bck);

        // lOS BOTONES DE AUDIO
        const musicButtonContainer = new Phaser.GameObjects.Container(this.scene, 0, 220);
        this.settingsContainer.add(musicButtonContainer);

        const musicButton = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "btn_info");
        musicButton.setInteractive({ useHandCursor: true });
        musicButton.on("pointerdown", this.onDownMusicButton, this);
        musicButton.on("pointerover", () => {

            if (this.alpha !== 1) {
                return;
            }

            musicButtonContainer.setScale(1.025); 
        }, this);
        musicButton.on("pointerout", () => { 
        
            if (this.alpha !== 1) {
                return;
            }

            musicButtonContainer.setScale(1); 
        }, this);
        musicButtonContainer.add(musicButton);

        const musicButtonText = new Phaser.GameObjects.Text(this.scene, -130, 0, GameVars.gameText[GameVars.gameData.language].MUSIC, {fontFamily: "Supercell", fontSize: "20px", color: "#FFFFFF"});
        musicButtonText.setOrigin(0, .5);
        musicButtonText.setStroke("#000000", 4);
        musicButtonText.setShadow(3, 3, "#000000");
        musicButtonContainer.add(musicButtonText);

        this.musicLabel = new Phaser.GameObjects.Text(this.scene, 92, 0, GameVars.gameText[GameVars.gameData.language].OFF, {fontFamily: "Supercell", fontSize: "20px", color: "#FFFFFF"});
        this.musicLabel.setOrigin(.5);
        this.musicLabel.setStroke("#000000", 4);
        this.musicLabel.setShadow(3, 3, "#000000");
        musicButtonContainer.add(this.musicLabel);

        const soundsButtonContainer = new Phaser.GameObjects.Container(this.scene, 0, 290);
        this.settingsContainer.add(soundsButtonContainer);

        const soundsButton = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "btn_info");
        soundsButton.setInteractive({ useHandCursor: true });
        soundsButton.on("pointerdown", this.onDownSoundsButton, this);
        soundsButton.on("pointerover", () => {

            if (this.alpha !== 1) {
                return;
            }

            soundsButtonContainer.setScale(1.025); 
        }, this);
        musicButton.on("pointerout", () => { 
        
            if (this.alpha !== 1) {
                return;
            }

            soundsButtonContainer.setScale(1); 
        }, this);
        soundsButtonContainer.add(soundsButton);

        const soundsButtonText = new Phaser.GameObjects.Text(this.scene, -130, 0, GameVars.gameText[GameVars.gameData.language].SOUNDS, {fontFamily: "Supercell", fontSize: "20px", color: "#FFFFFF"});
        soundsButtonText.setOrigin(0, .5);
        soundsButtonText.setStroke("#000000", 4);
        soundsButtonText.setShadow(3, 3, "#000000");
        soundsButtonContainer.add(soundsButtonText);

        this.soundsLabel = new Phaser.GameObjects.Text(this.scene, 92, 0, GameVars.gameText[GameVars.gameData.language].OFF, {fontFamily: "Supercell", fontSize: "20px", color: "#FFFFFF"});
        this.soundsLabel.setOrigin(.5);
        this.soundsLabel.setStroke("#000000", 4);
        this.soundsLabel.setShadow(3, 3, "#000000");
        soundsButtonContainer.add(this.soundsLabel);

        this.setAudioLabels();

        this.setAlpha(.7);

        this.scene.tweens.add({
            targets: this,
            scaleX: 1,
            scaleY: 1,
            alpha: 1,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 250
        });

        // Button fullscreen
        if (GameVars.currentScene.game.device.os.desktop) {
            
            const fullscreenButtonContainer = new Phaser.GameObjects.Container(this.scene, 0, 375);
            this.settingsContainer.add(fullscreenButtonContainer);

            const fullscreenButton = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "btn_info");
            fullscreenButton.setInteractive({ useHandCursor: true });
            fullscreenButton.on("pointerdown", this.onDownFullscreenButton, this);
            fullscreenButton.on("pointerover", () => {

                if (this.alpha !== 1) {
                    return;
                }

                fullscreenButtonContainer.setScale(1.025); 
            }, this);
            fullscreenButton.on("pointerout", () => { 
            
                if (this.alpha !== 1) {
                    return;
                }

                fullscreenButtonContainer.setScale(1); 
            }, this);
            fullscreenButtonContainer.add(fullscreenButton);

            const fullscreenButtonText = new Phaser.GameObjects.Text(this.scene, -130, 0, GameVars.gameText[GameVars.gameData.language].FULLSCREEN, {fontFamily: "Supercell", fontSize: "20px", color: "#FFFFFF"});
            fullscreenButtonText.setOrigin(0, .5);
            fullscreenButtonText.setStroke("#000000", 4);
            fullscreenButtonText.setShadow(3, 3, "#000000");
            fullscreenButtonContainer.add(fullscreenButtonText);

            this.fullscreenLabel = new Phaser.GameObjects.Text(this.scene, 92, 0, GameVars.gameText[GameVars.gameData.language].OFF, {fontFamily: "Supercell", fontSize: "20px", color: "#FFFFFF"});
            this.fullscreenLabel.setOrigin(.5);
            this.fullscreenLabel.setStroke("#000000", 4);
            this.fullscreenLabel.setShadow(3, 3, "#000000");
            fullscreenButtonContainer.add(this.fullscreenLabel);

            this.isFullscreen = GameVars.currentScene.scale.isFullscreen;
            this.updateFullscreenLabel();
        }

        // Begin quit menu
        this.quitContainer = new Phaser.GameObjects.Container(this.scene, 0, ((!GameVars.currentScene.game.device.os.desktop) ? 10 : 45));
        this.quitContainer.visible = false;
        this.add(this.quitContainer);
        
        const quitTitle = new Phaser.GameObjects.Text(this.scene, 0, -10, GameVars.gameText[GameVars.gameData.language].RESTART_GAME_QUESTION, {fontFamily: "Supercell", fontSize: "25px", color: "#FFFFFF", align: "center"});
        quitTitle.setOrigin(0.5, -1.35);
        quitTitle.setStroke("#000000", 6);
        quitTitle.setShadow(4, 4, "#000000");
        this.quitContainer.add(quitTitle);

        const quitSubtitle = new Phaser.GameObjects.Text(this.scene, 0, -10, GameVars.gameText[GameVars.gameData.language].RESTART_GAME_SUBTITLE, {fontFamily: "Supercell", fontSize: "20px", color: "#FFFFFF", align: "center"});
        quitSubtitle.setOrigin(0.5, -1.85);
        quitSubtitle.setStroke("#000000", 6);
        quitSubtitle.setShadow(4, 4, "#000000");
        this.quitContainer.add(quitSubtitle);

        // no button
        const noButtonContaniner = new Phaser.GameObjects.Container(this.scene, -75, ((!GameVars.currentScene.game.device.os.desktop) ? 260 : 280));
        this.quitContainer.add(noButtonContaniner);

        const noButton = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "btn_yellow_wide");
        noButton.setInteractive({ useHandCursor: true });
        noButton.on("pointerover", () => { 
            noButtonContaniner.setScale(1.025);   
        });
        noButton.on("pointerout", () => { 
            noButtonContaniner.setScale(1);  
        });
        noButton.on("pointerdown", this.onDownNoButton, this);
        noButtonContaniner.add(noButton);

        const noText = new Phaser.GameObjects.Text(this.scene, 0, 0, GameVars.gameText[GameVars.gameData.language].RESTART_MENU_NO, {fontFamily: "Supercell", fontSize: "18px", color: "#FFFFFF"});
        noText.setOrigin(.5);
        noText.setStroke("#000000", 6);
        noText.setShadow(3, 3, "#000000");
        noButtonContaniner.add(noText);

        // Yes button
        const yesButtonContaniner = new Phaser.GameObjects.Container(this.scene, 75, ((!GameVars.currentScene.game.device.os.desktop) ? 260 : 280));
        this.quitContainer.add(yesButtonContaniner);

        const yesButton = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "btn_red_wide");
        yesButton.setInteractive({ useHandCursor: true });
        yesButton.on("pointerover", () => { 
            yesButtonContaniner.setScale(1.025);   
        });
        yesButton.on("pointerout", () => { 
            yesButtonContaniner.setScale(1);  
        });
        yesButton.on("pointerdown", this.onDownYesButton, this);
        yesButtonContaniner.add(yesButton);

        const yesText = new Phaser.GameObjects.Text(this.scene, 0, 0, GameVars.gameText[GameVars.gameData.language].RESTART_MENU_YES, {fontFamily: "Supercell", fontSize: "18px", color: "#FFFFFF"});
        yesText.setOrigin(.5);
        yesText.setStroke("#000000", 6);
        yesText.setShadow(3, 3, "#000000");
        yesButtonContaniner.add(yesText);
    }

    public update(): void {

        if (GameVars.currentScene.game.device.os.desktop && this.isFullscreen !== GameVars.currentScene.scale.isFullscreen) {
            this.isFullscreen = !this.isFullscreen;
            if (!this.isFullscreen) {
                GameManager.resize();
            }
            this.updateFullscreenLabel();
        }
    }

    private onDownResetButton(): void {  

        this.settingsContainer.visible = false;
        this.quitContainer.visible = true;
        AudioManager.playSoundEffect("btn_click");
    }

    private onDownMusicButton(): void {

        AudioManager.toggleMusicState();

        this.setAudioLabels();

        AudioManager.playSoundEffect("btn_click");
    }

    private onDownFullscreenButton(): void {

        if (GameVars.currentScene.scale.isFullscreen) {
            GameVars.currentScene.scale.stopFullscreen();
            this.isFullscreen = false;
            GameManager.resize();
        } else {
            GameVars.currentScene.scale.startFullscreen();
            this.isFullscreen = true;
        }
        this.updateFullscreenLabel();
        AudioManager.playSoundEffect("btn_click");
    }

    private updateFullscreenLabel(): void {

        if (!this.isFullscreen) {
            this.fullscreenLabel.text = GameVars.gameText[GameVars.gameData.language].OFF;
            this.fullscreenLabel.setColor("#FE0044");
        } else {
            this.fullscreenLabel.text = GameVars.gameText[GameVars.gameData.language].ON;
            this.fullscreenLabel.setColor("#32CB00");
        }
    }

    private onDownNoButton(): void {

        this.settingsContainer.visible = true;
        this.quitContainer.visible = false;
        AudioManager.playSoundEffect("btn_click");
    }

    private onDownYesButton(): void {

        GameManager.reset();
        
        AudioManager.playSoundEffect("btn_click");
    }

    private onDownSoundsButton(): void {

        AudioManager.toggleSoundState();

        this.setAudioLabels();

        AudioManager.playSoundEffect("btn_click");
    }

    private setAudioLabels(): void {

        if (GameVars.gameData.musicMuted) {
            this.musicLabel.text = GameVars.gameText[GameVars.gameData.language].OFF;
            this.musicLabel.setColor("#FE0044");
        } else  {
            this.musicLabel.text = GameVars.gameText[GameVars.gameData.language].ON;
            this.musicLabel.setColor("#32CB00");
        }

        if (GameVars.gameData.soundMuted) {
            this.soundsLabel.text = GameVars.gameText[GameVars.gameData.language].OFF;
            this.soundsLabel.setColor("#FE0044");
        } else  {
            this.soundsLabel.text = GameVars.gameText[GameVars.gameData.language].ON;
            this.soundsLabel.setColor("#32CB00");
        }
    }

    private createFinishDuelButton(): void {

        const finishButtonContainer = new Phaser.GameObjects.Container(this.scene, 0, 100);
        this.settingsContainer.add(finishButtonContainer);

        const finishDuelButton = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "btn_yellow_wide");
        finishDuelButton.setInteractive({ useHandCursor: true });
        finishDuelButton.on("pointerover", () => { 
            finishButtonContainer.setScale(1.025);   
        });
        finishDuelButton.on("pointerout", () => { 
            finishButtonContainer.setScale(1);  
        });
        finishDuelButton.on("pointerdown", this.onDownFinishButton, this);
        finishButtonContainer.add(finishDuelButton);
    
        const finishDuelText = new Phaser.GameObjects.Text(this.scene, 0, 0, GameVars.gameText[GameVars.gameData.language].FINISH, {fontFamily: "Supercell", fontSize: "18px", color: "#FFFFFF"});
        finishDuelText.setOrigin(.5);
        finishDuelText.setStroke("#000000", 6);
        finishDuelText.setShadow(3, 3, "#000000");
        finishButtonContainer.add(finishDuelText);
    }

    private onDownFinishButton(): void {  

        BattleManager.forceFinishDuel();
        AudioManager.playSoundEffect("btn_click");
    }
}
