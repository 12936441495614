import { TurretMenu } from "./TurretMenu";
import { BattleManager } from "../BattleManager";
import { GameVars } from "../../../GameVars";
import { AudioManager } from "../../../AudioManager";

export class ButtonSell extends Phaser.GameObjects.Container {

    public priceLabel: Phaser.GameObjects.BitmapText;
    
    private button: Phaser.GameObjects.Image;
    private turretMenu: TurretMenu;

    constructor(scene: Phaser.Scene, x: number, y: number) {

        super(scene);

        this.x = x;
        this.y = y;

        this.button = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "btn_yellow");
        this.button.setInteractive({ useHandCursor: true });
        this.button.on("pointerdown", this.onDownButton, this);
        this.button.on("pointerover", () => { this.setScale(1.05); }, this);
        this.button.on("pointerout", () => { this.setScale(1); }, this);
        this.add(this.button);

        const text = new Phaser.GameObjects.Text(this.scene, 0, -34, "Sell", {fontFamily: "Supercell", fontSize: "14px", color: "#FFFFFF"});
        text.setOrigin(.5);
        text.setStroke("#000000", 4);
        text.setShadow(3, 3, "#000000");
        this.add(text);

        const sellIcon = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "icon_sell");
        this.add(sellIcon);

        this.priceLabel = new Phaser.GameObjects.BitmapText(this.scene, -2, 36, "supercell-white", GameVars.formatNumber(10), 17);
        this.priceLabel.setOrigin(.5);
        if (this.priceLabel.text.length >= 6) {
            this.priceLabel.setScale(.9);
        }
        this.add(this.priceLabel);
    }

    public setTurretMenu(turretMenu: TurretMenu): void {

        this.turretMenu = turretMenu;
    }

    public clearTurretMenu(): void {

        this.turretMenu = null;
    }

    private onDownButton(): void {
        
        if (this.turretMenu === null) {
            return;
        }

        BattleManager.sellTurret(this.turretMenu.turret.id);

        AudioManager.playSoundEffect("btn_click");
    }
}
