import { MineActor } from "../turret-actors/MineActor";
import { GluePool } from "../turret-actors/GluePool";
import { EnemyActor } from "../enemy-actors/EnemyActor";
import { TurretActor } from "../turret-actors/TurretActor";
import { Board } from "./Board";
import { GameConstants } from "../../../GameConstants";
import { ArrowActor } from "../turret-actors/ArrowActor";
import { GameVars } from "../../../GameVars";
import { ProjectileTurretActor } from "../turret-actors/ProjectileTurretActor";
import { LaserTurretActor } from "../turret-actors/LaserTurretActor";
import { LaserBeam } from "../turret-actors/LaserBeam";
import { LaunchTurretActor } from "../turret-actors/LaunchTurretActor";
import { MortarActor } from "../turret-actors/MortarActor";
import { GlueTurretActor } from "../turret-actors/GlueTurretActor";
import { BattleManager } from "../BattleManager";
import { PauseMenu } from "../PauseMenu";
import { GlueBulletActor } from "../turret-actors/GlueBulletActor";
import { AudioManager } from "../../../AudioManager";
import { RoundCompletedLayer } from "../RoundCompletedLayer";
import { Walls } from "./Walls";
import { SmokeEffect } from "../SmokeEffect";
import { BattleScene } from "../BattleScene";
import { TutorialManager } from "../TutorialManager";
import { ArrowActorsPool } from "../turret-actors/ArrowActorsPool";
import { EnemyActorsPool } from "../enemy-actors/EnemyActorsPool";
import { TurretType } from "endless-siege-engine/build/main/lib/Types";
import { GameManager } from "../../../GameManager";
import * as Creepts from "endless-siege-engine";

export class BoardContainer extends Phaser.GameObjects.Container {

    public static currentInstance: BoardContainer;

    public board: Board;
    public enemyActorsPool: EnemyActorsPool;
    public tutorialPosition: { c: number, r: number };


    private enemyActors: EnemyActor[];
    private turretActors: TurretActor[];
    private arrowActors: ArrowActor[];
    private glueBulletActors: GlueBulletActor[];
    private mortarActors: MortarActor[];
    private mineActors: MineActor[];
    private rangeCircles: Phaser.GameObjects.Image[];
    private pauseMenu: PauseMenu;
    private roundCompletedLayer: RoundCompletedLayer;
    private waterContainer: Phaser.GameObjects.Container;
    private pointerContainer: Phaser.GameObjects.Container;
    private glueCirclesContainer: Phaser.GameObjects.Container;
    private circlesContainer: Phaser.GameObjects.Container;
    private shotsContainer: Phaser.GameObjects.Container;
    private prePosition: Phaser.GameObjects.Graphics;
    private gluePools: GluePool[];
    private walls: Walls;
    private lastPrePositionTurret: { c: number, r: number };
    private handCursor: Phaser.GameObjects.Image;
    private tutorialTurretContainer: Phaser.GameObjects.Container;
    private arrowActorsPool: ArrowActorsPool;
    private shaking: boolean;
    private shakeAmplitude: number;
    private f: number;

    private nextWaveButton: Phaser.GameObjects.Image;
    private nextWaveText: Phaser.GameObjects.Text;

    constructor(scene: Phaser.Scene) {

        super(scene);

        BoardContainer.currentInstance = this;

        this.x = GameConstants.GAME_WIDTH / 2;
        this.y = GameConstants.GAME_HEIGHT / 2 - GameConstants.CELLS_SIZE / 2;

        this.setScale(GameVars.scaleCorrectionFactor, GameVars.scaleCorrectionFactor * GameVars.scaleY);

        this.createAnimations();

        this.shaking = false;
        this.shakeAmplitude = .5;

        this.enemyActors = [];
        this.turretActors = [];
        this.arrowActors = [];
        this.glueBulletActors = [];
        this.mortarActors = [];
        this.mineActors = [];
        this.rangeCircles = [];
        this.gluePools = [];

        this.lastPrePositionTurret = null;

        this.f = 0;

        this.arrowActorsPool = new ArrowActorsPool(this.scene, 5);
        this.enemyActorsPool = new EnemyActorsPool(this.scene, 20, 20, 5, 5);

        this.board = new Board(this.scene);
        this.add(this.board);

        if (GameConstants.SHOW_DEBUG_GEOMETRY) {
            this.drawDebugGeometry();
        }

        this.waterContainer = new Phaser.GameObjects.Container(this.scene);
        this.board.add(this.waterContainer);

        if (this.board.portalAnimation) {
            this.board.bringToTop(this.board.portalAnimation);
        }

        this.board.bringToTop(this.board.portal);

        if (this.board.portalFront) {
            this.board.bringToTop(this.board.portalFront);
        }

        this.pointerContainer = new Phaser.GameObjects.Container(this.scene);
        this.board.add(this.pointerContainer);

        this.glueCirclesContainer = new Phaser.GameObjects.Container(this.scene);
        this.board.add(this.glueCirclesContainer);

        this.pointerContainer.setInteractive(new Phaser.Geom.Rectangle(0, - GameConstants.GAME_HEIGHT, GameConstants.GAME_WIDTH, GameConstants.GAME_HEIGHT * 4), Phaser.Geom.Rectangle.Contains);
        this.pointerContainer.on("pointerdown", () => { this.onPointerDown(); });

        this.board.actorsContainer = new Phaser.GameObjects.Container(this.scene);
        this.board.add(this.board.actorsContainer);

        this.shotsContainer = new Phaser.GameObjects.Container(this.scene);
        this.board.add(this.shotsContainer);

        this.circlesContainer = new Phaser.GameObjects.Container(this.scene);
        this.board.add(this.circlesContainer);

        this.prePosition = new Phaser.GameObjects.Graphics(this.scene);
        this.prePosition.fillStyle(0xffffff, .5);
        this.prePosition.fillRect(-30, -30, 60, 60);
        this.prePosition.visible = false;
        this.board.add(this.prePosition);

        this.addObstacles();

        this.walls = new Walls(this.scene);
        this.board.add(this.walls);

        if (this.board.portalFront) {
            this.board.bringToTop(this.board.portal);
        }

        const prevPath = GameVars.currentMapData.path[0];
        const path = GameVars.currentMapData.path[1];

        let pathDir: { r: number, c: number } = { r: prevPath.r - path.r, c: prevPath.c - path.c };

        let x = (GameConstants.CELLS_SIZE * path.c + GameConstants.CELLS_SIZE / 2) * GameVars.scaleCorrectionFactor;
        let y = (GameConstants.CELLS_SIZE * path.r + GameConstants.CELLS_SIZE / 2) * GameVars.scaleCorrectionFactor;

        this.nextWaveButton = new Phaser.GameObjects.Image(this.scene, x, y, "texture_atlas_1", pathDir.r < 0 ? "box_next_wave" : "box_next_wave_side");
        this.nextWaveButton.setInteractive({ useHandCursor: true });
        this.nextWaveButton.setScale(pathDir.r < 0 && pathDir.c < 0 ? -1 : 1, 1);
        this.nextWaveButton.on("pointerdown", this.onClickNextWave, this);
        this.nextWaveButton.on("pointerover", () => {
            if (GameVars.paused || GameVars.gameOver || !GameVars.gameData.tutorialSeen) {
                return;
            }
            this.nextWaveButton.setScale(this.nextWaveButton.scaleX < 0 ? -1.1 : 1.1, 1.1);
            this.nextWaveText.setScale(1.1);
        }, this);
        this.nextWaveButton.on("pointerout", () => {
            if (GameVars.paused || GameVars.gameOver) {
                return;
            }
            this.nextWaveButton.setScale(this.nextWaveButton.scaleX < 0 ? -1 : 1, 1);
            this.nextWaveText.setScale(1);
        }, this);
        this.board.add(this.nextWaveButton);

        this.nextWaveText = new Phaser.GameObjects.Text(this.scene, x, y, GameVars.gameText[GameVars.gameData.language].NEXT_WAVE, { fontFamily: "Supercell", fontSize: "13px", color: "#FFFFFF", align: "center" });
        this.nextWaveText.setOrigin(.5);
        this.nextWaveText.setStroke("#000000", 4);
        this.nextWaveText.setShadow(3, 3, "#000000");
        this.nextWaveText.lineSpacing = -3;
        this.nextWaveText.setWordWrapWidth(50);
        this.board.add(this.nextWaveText);

        if (pathDir.r < 0) {
            this.nextWaveButton.y += 10 * GameVars.scaleCorrectionFactor;
            this.nextWaveText.y += 14 * GameVars.scaleCorrectionFactor;
        } else if (pathDir.c < 0) {
            this.nextWaveButton.x += 18 * GameVars.scaleCorrectionFactor;
            this.nextWaveText.x += 26 * GameVars.scaleCorrectionFactor;
            this.nextWaveText.y -= 2;
        } else {
            this.nextWaveButton.x -= 18 * GameVars.scaleCorrectionFactor;
            this.nextWaveText.x -= 26 * GameVars.scaleCorrectionFactor;
            this.nextWaveText.y -= 2;
            this.nextWaveButton.scaleX = -1;
        }

        this.createNextWaveTween();
    }

    public update(time: number, delta: number): void {

        this.enemyActors.forEach(function (enemy) {
            enemy.update(time, delta);
        });

        this.turretActors.forEach(function (tower) {
            tower.update(time, delta);
        });

        this.arrowActors.forEach(function (bullet) {
            bullet.update(time, delta);
        });

        this.glueBulletActors.forEach(function (bullet) {
            bullet.update(time, delta);
        });

        this.mortarActors.forEach(function (mortar) {
            mortar.update(time, delta);
        });

        if (this.pauseMenu) {
            this.pauseMenu.update();
        }

        this.board.actorsContainer.sort("y");

        // efecto tembleque tras compra del booster
        if (this.shaking) {

            this.x = GameConstants.GAME_WIDTH / 2 + this.shakeAmplitude * (1 - 2 * Math.random());
            this.y = GameConstants.GAME_HEIGHT / 2 - GameConstants.CELLS_SIZE / 2 + this.shakeAmplitude * (1 - 2 * Math.random());

            this.shakeAmplitude *= 1.06;

            if (this.shakeAmplitude > 6) {
                this.shakeAmplitude = 6;
            }

            this.f++;

            if (this.f === 990) {
                this.stopShaking();
            }
        }
    }

    public markCellOnTutorial(): void {

        // ENCONTRAR LA CELDA ADECUADA
        const pathCell = GameVars.enemiesPathCells[6];
        const surroundingCells = [
            { c: -1, r: -1 }, { c: 0, r: -1 }, { c: 1, r: -1 },
            { c: -1, r: 0 }, { c: 1, r: 0 },
            { c: -1, r: 1 }, { c: 0, r: 1 }, { c: 1, r: 1 },
            { c: -2, r: -2 }, { c: 0, r: -2 }, { c: 2, r: -2 },
            { c: -2, r: 0 }, { c: 2, r: 0 },
            { c: -2, r: 2 }, { c: 0, r: 2 }, { c: 2, r: 2 },
            { c: -1, r: -2 }, { c: 1, r: -2 }, { c: 1, r: -2 },
            { c: -1, r: 0 }, { c: 1, r: 0 },
            { c: -1, r: 2 }, { c: 1, r: 2 }, { c: 1, r: 2 },
            { c: -2, r: -1 }, { c: 2, r: -1 },
            { c: -2, r: 1 }, { c: 2, r: 1 },
            { c: -2, r: 1 }, { c: 2, r: 1 },
        ];

        let c: number;
        let r: number;

        for (let i = 0; i < surroundingCells.length; i++) {

            c = pathCell.c + surroundingCells[i].c;
            r = pathCell.r + surroundingCells[i].r;

            let canBeBuiltUpon = true;

            if (c < 0 || r < 0) {
                canBeBuiltUpon = false;
            } else {

                // que no sea una celda no edificable
                for (let j = 0; j < GameVars.plateausCells.length; j++) {
                    if (c === GameVars.plateausCells[j].c && r === GameVars.plateausCells[j].r) {
                        canBeBuiltUpon = false;
                        break;
                    }
                }

                // que no sea una celda del camino
                for (let j = 0; j < GameVars.enemiesPathCells.length; j++) {
                    if (c === GameVars.enemiesPathCells[j].c && r === GameVars.enemiesPathCells[j].r) {
                        canBeBuiltUpon = false;
                        break;
                    }
                }
            }

            if (canBeBuiltUpon) {
                break;
            }
        }

        this.tutorialPosition = { c: c, r: r };

        if (GameVars.currentMapId === 1) {
            this.tutorialPosition = { c: 6, r: 9 }
        }

        /*  // ponemos una torreta de ballesta con alfa bajado(
         this.tutorialTurretContainer = new Phaser.GameObjects.Container(this.scene, (this.tutorialPosition.c + .5) * GameConstants.CELLS_SIZE, (this.tutorialPosition.r + .5) * GameConstants.CELLS_SIZE - 8);
         this.tutorialTurretContainer.setScale(.8);
         this.tutorialTurretContainer.alpha = .45;
         this.board.add(this.tutorialTurretContainer);
 
         const turretBase = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "archer_1_base");
         this.tutorialTurretContainer.add(turretBase);
 
         const turretCannon = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "archer_1_top");
         turretCannon.angle = -90;
         this.tutorialTurretContainer.add(turretCannon);
 
         // ponemos en cursor del dedo
         this.handCursor = new Phaser.GameObjects.Image(this.scene, (this.tutorialPosition.c + .5) * GameConstants.CELLS_SIZE + 15, (this.tutorialPosition.r + .5) * GameConstants.CELLS_SIZE, "texture_atlas_1", "hand_cursor");
         this.handCursor.setOrigin(.05, .2);
         this.board.add(this.handCursor);
 
         this.scene.tweens.add({
             targets: this.handCursor,
             scaleX: 1.1,
             scaleY: 1.1,
             ease: Phaser.Math.Easing.Cubic.Out,
             duration: 750,
             yoyo: true,
             repeat: -1
         }); */
    }

    public showHandCursorOnTurret(): void {
        this.turretActors[0].addHandCursor();
    }

    public onTurretRangesModified(type: TurretType) {
        for (let i = 0; i < this.turretActors.length; ++i) {
            if (this.turretActors[i].name === type) {
                this.turretActors[i].reloadRangeCircle();
            }
        }
    }

    public changeTurretSprite(type: TurretType) {

        for (let i = 0; i < this.turretActors.length; ++i) {
            if (this.turretActors[i].name === type) {
                const effect = new Phaser.GameObjects.Sprite(this.scene, this.turretActors[i].x, this.turretActors[i].y, "texture_atlas_1", "fx_upgrades_towers_cards_01");
                this.scene.add.existing(effect);
                this.board.actorsContainer.add(effect);
                effect.scale *= 2;
                effect.anims.play("gold_turret");
                setTimeout(() => {
                    this.turretActors[i].upgradeSprites();
                }, 200);
            }
        }

        AudioManager.playSoundEffect("turret_upgrade");
    }

    public addEnemy(enemy: Creepts.Enemy, position: { r: number, c: number }): void {

        let enemyActor = this.enemyActorsPool.getEnemy(enemy, position);

        if (enemyActor) {
            this.board.actorsContainer.add(enemyActor);
            this.enemyActors.push(enemyActor);
        }

        this.board.newPortalGlare();
    }

    public removeEnemy(id: number): void {

        let i: number;
        for (i = 0; i < this.enemyActors.length; i++) {
            if (this.enemyActors[i].id === id) {
                break;
            }
        }

        const enemy = this.enemyActors[i];

        if (enemy) {
            enemy.alpha = 0;
            this.enemyActors.splice(i, 1);
            this.enemyActorsPool.onEnemyRemovedFromBoard(enemy);
            this.board.actorsContainer.remove(enemy);
        }
    }

    public addTurret(type: string, position: { r: number, c: number }): void {

        this.prePosition.visible = false;

        if (position.r < 0 || position.c < 0 || position.r >= GameVars.currentMapData.size.r || position.c >= GameVars.currentMapData.size.c) {
            return;
        }

        for (let i = 0; i < GameVars.enemiesPathCells.length; i++) {
            if (position.c === GameVars.enemiesPathCells[i].c && position.r === GameVars.enemiesPathCells[i].r) {
                return;
            }
        }

        for (let i = 0; i < this.turretActors.length; i++) {
            if (position.c === this.turretActors[i].turret.position.c && position.r === this.turretActors[i].turret.position.r) {
                return;
            }
        }

        let isOnPlateau = true;

        if (GameVars.plateausCells.length !== 0) {
            for (let i = 0; i < GameVars.plateausCells.length; i++) {
                if (GameVars.plateausCells[i].c === position.c && GameVars.plateausCells[i].r === position.r) {
                    isOnPlateau = false;
                    break;
                }
            }
        }

        if (!isOnPlateau) {
            return;
        }

        if (!GameVars.gameData.tutorialSeen) {
            if (!(this.tutorialPosition.c === position.c && this.tutorialPosition.r === position.r)) {
                return;
            } else {

                // this.tutorialTurretContainer.destroy();
                this.handCursor?.destroy();
                TutorialManager.onTurretPlaced();
            }
        }

        let turretActor: TurretActor;
        let turret = BattleManager.addTurret(type, position);

        if (!turret) {
            return;
        }

        switch (type) {

            case TurretType.PROJECTILE:
                turretActor = new ProjectileTurretActor(this.scene, position, turret);
                break;
            case TurretType.LASER:
                turretActor = new LaserTurretActor(this.scene, position, turret);
                break;
            case TurretType.LAUNCH:
                turretActor = new LaunchTurretActor(this.scene, position, turret);
                break;
            case TurretType.GLUE:
                turretActor = new GlueTurretActor(this.scene, position, turret);
                break;
            default:
        }

        this.board.actorsContainer.add(turretActor);
        this.turretActors.push(turretActor);

        turretActor.onAddedToBoardContainer();

        AudioManager.playSoundEffect("turret_planted");
    }

    public onTurretSold(id: number): void {

        BattleScene.currentInstance.hideTurretMenu();
        this.hideRangeCircles();

        let i: number;
        for (i = 0; i < this.turretActors.length; i++) {
            if (this.turretActors[i].id === id) {
                break;
            }
        }

        const turret = this.turretActors[i];

        if (turret) {

            const smokeEffect = new SmokeEffect(this.scene);
            smokeEffect.x = turret.x;
            smokeEffect.y = turret.y;
            this.board.actorsContainer.add(smokeEffect);

            this.turretActors.splice(i, 1);
            turret.sold();
        }
    }

    public addBullet(bullet: Creepts.Arrow, projectileTurret: Creepts.ProjectileTurret): void {

        const projectileTurretActor = <ProjectileTurretActor>this.getTurretActorByID(projectileTurret.id);
        projectileTurretActor.shootArrow();

        const arrowActor = this.arrowActorsPool.getArrow(bullet);
        this.shotsContainer.add(arrowActor);

        this.arrowActors.push(arrowActor);
    }

    public addGlueBullet(bullet: Creepts.GlueBullet, projectileTurret: Creepts.GlueTurret): void {

        const glueTurretActor = <GlueTurretActor>this.getTurretActorByID(projectileTurret.id);
        glueTurretActor.shootGlue();

        const bulletActor = new GlueBulletActor(this.scene, bullet);
        this.shotsContainer.add(bulletActor);

        this.glueBulletActors.push(bulletActor);
    }

    public addLaserBeam(laserTurret: Creepts.LaserTurret, enemies: Creepts.Enemy[]): void {

        const laserTurretActor = <LaserTurretActor>this.getTurretActorByID(laserTurret.id);

        let enemyActors = [];

        for (let i = 0; i < enemies.length; i++) {
            enemyActors.push(this.getEnemyActorByID(enemies[i].id));
        }

        laserTurretActor.shootLaser(enemyActors);

        const laserBeam = new LaserBeam(this.scene, laserTurretActor, enemyActors, laserTurret.grade);
        this.shotsContainer.add(laserBeam);
    }

    public addMortar(mortar: Creepts.Mortar, launchTurret: Creepts.LaunchTurret): void {

        const launchTurretActor = <LaunchTurretActor>this.getTurretActorByID(launchTurret.id);
        launchTurretActor.shootMortar();

        const mortarActor = new MortarActor(this.scene, mortar);
        this.shotsContainer.add(mortarActor);

        this.mortarActors.push(mortarActor);
    }

    public addMine(mine: Creepts.Mine, launchTurret: Creepts.LaunchTurret): void {

        const launchTurretActor = <LaunchTurretActor>this.getTurretActorByID(launchTurret.id);
        launchTurretActor.shootMine();

        const mineActor = new MineActor(this.scene, mine, launchTurretActor);
        this.glueCirclesContainer.add(mineActor);

        this.mineActors.push(mineActor);
    }

    public addGlue(glue: Creepts.Glue, glueTurret: Creepts.GlueTurret): void {

        const glueTurretActor = <GlueTurretActor>this.getTurretActorByID(glueTurret.id);
        glueTurretActor.shootGlue();

        const gluePool = new GluePool(this.scene, glueTurretActor, glue);
        this.glueCirclesContainer.add(gluePool);
        this.glueCirclesContainer.sendToBack(gluePool);
        this.gluePools.push(gluePool);
    }

    public onGlueConsumed(glue: Creepts.Glue): void {

        let gluePool: GluePool = null;

        for (let i = 0; i < this.gluePools.length; i++) {

            if (glue.id === this.gluePools[i].id) {
                gluePool = this.gluePools[i];
                this.gluePools.splice(i, 1);
                gluePool.destroy();
                break;
            }
        }
    }

    public onEnemyHit(enemy: Creepts.Enemy, impactType: string): void {

        let enemyActor: EnemyActor = this.getEnemyActorByID(enemy.id);

        if (enemyActor && !enemyActor.isDead) {
            enemyActor.hit(impactType);
        }
    }

    public onEnemyGlueHit(enemy: Creepts.Enemy): void {

        let enemyActor: EnemyActor = this.getEnemyActorByID(enemy.id);

        if (enemyActor && !enemyActor.isDead) {
            enemyActor.glueHit();
        }
    }

    public onEnemyKilled(enemy: Creepts.Enemy): void {

        let enemyActor: EnemyActor = this.getEnemyActorByID(enemy.id);

        if (enemyActor) {
            enemyActor.die();
        }
    }

    public teleportEnemy(enemy: Creepts.Enemy, glueTurret: Creepts.GlueTurret): void {

        let enemyActor: EnemyActor = this.getEnemyActorByID(enemy.id);

        if (enemyActor && !enemyActor.isDead) {
            const effectX = enemyActor.x;
            const effectY = enemyActor.y - 10;
            enemyActor.teleport(glueTurret);
            AudioManager.playSoundEffect("t3_teleport");

            let teleport = this.scene.add.sprite(effectX, effectY, "texture_atlas_3");
            this.board.add(teleport);

            teleport.anims.play("teleport_in");

            teleport.on("animationcomplete", () => {
                teleport.destroy();
            }, this);
        }
    }

    public teleportEnd(enemy: EnemyActor): void {

        let teleport = this.scene.add.sprite(enemy.x, enemy.y - 10, "texture_atlas_3");
        this.board.add(teleport);

        teleport.anims.play("teleport_out");

        teleport.on("animationcomplete", () => {
            teleport.destroy();
            teleport = null;
        }, this);
    }

    public removeBullet(bullet: Creepts.Arrow): void {

        let arrowActor: ArrowActor = null;

        for (let i = 0; i < this.arrowActors.length; i++) {

            if (this.arrowActors[i].bullet.id === bullet.id) {
                arrowActor = this.arrowActors[i];
                break;
            }
        }

        if (arrowActor) {

            const i = this.arrowActors.indexOf(arrowActor);
            this.arrowActors.splice(i, 1);

            arrowActor.alpha = 0;
            this.shotsContainer.remove(arrowActor);
            this.arrowActorsPool.onArrowRemovedFromBoard(arrowActor);
        }
    }

    public removeGlueBullet(bullet: Creepts.GlueBullet): void {

        let bulletActor: GlueBulletActor = null;

        for (let i = 0; i < this.glueBulletActors.length; i++) {

            if (this.glueBulletActors[i].glueBullet.id === bullet.id) {
                bulletActor = this.glueBulletActors[i];
                break;
            }
        }

        if (bulletActor) {
            const i = this.glueBulletActors.indexOf(bulletActor);
            this.glueBulletActors.splice(i, 1);
            bulletActor.destroy();
        }
    }

    public detonateMortar(mortar: Creepts.Mortar): void {

        let mortarActor: MortarActor = null;

        for (let i = 0; i < this.mortarActors.length; i++) {

            if (this.mortarActors[i].mortar.id === mortar.id) {
                mortarActor = this.mortarActors[i];
                break;
            }
        }

        if (mortarActor) {
            mortarActor.detonate();
        }
    }

    public detonateMine(mine: Creepts.Mine): void {

        let mineActor: MineActor = null;

        for (let i = 0; i < this.mineActors.length; i++) {

            if (this.mineActors[i].mine.id === mine.id) {
                mineActor = this.mineActors[i];
                break;
            }
        }

        if (mineActor) {
            mineActor.detonate();
        }
    }

    public removeMortar(mortarActor: MortarActor): void {
        const i = this.mortarActors.indexOf(mortarActor);
        this.mortarActors.splice(i, 1);
        mortarActor.visible = false;
        mortarActor.destroy();
    }

    public removeMine(mineActor: MineActor): void {

        const i = this.mineActors.indexOf(mineActor);
        this.mineActors.splice(i, 1);
        mineActor.visible = false;
        mineActor.destroy();
    }

    public upgradeTurret(id: number): void {

        const turretActor = this.getTurretActorByID(id);
        turretActor.upgrade();
    }

    public improveTurret(id: number): void {

        const turretActor = this.getTurretActorByID(id);
        turretActor.improve();
    }

    public getTurretActorByID(id: number): TurretActor {

        let turretActor = null;

        for (let i = 0; i < this.turretActors.length; i++) {
            if (this.turretActors[i].id === id) {
                turretActor = this.turretActors[i];
                break;
            }
        }

        return turretActor;
    }

    public getEnemyActorByID(id: number): EnemyActor {

        let enemy = null;

        for (let i = 0; i < this.enemyActors.length; i++) {
            if (this.enemyActors[i].id === id) {
                enemy = this.enemyActors[i];
                break;
            }
        }

        return enemy;
    }

    public createRangeCircle(range: number, x: number, y: number): Phaser.GameObjects.Image {

        const rangeCircle = new Phaser.GameObjects.Image(this.scene, x, y, "texture_atlas_1", "shoot_range");
        rangeCircle.setScale((range * 2) / rangeCircle.width);
        rangeCircle.visible = false;
        this.circlesContainer.add(rangeCircle);

        this.rangeCircles.push(rangeCircle);

        return rangeCircle;
    }

    public hideRangeCircles(): void {

        for (let i = 0; i < this.rangeCircles.length; i++) {
            this.rangeCircles[i].visible = false;
        }

        this.board.hideTurretFrame();
    }

    public showPauseMenu(): void {

        if (!this.pauseMenu) {
            BattleManager.pause();
            this.pauseMenu = new PauseMenu(this.scene);
            this.add(this.pauseMenu);
            this.hideRangeCircles();
            BattleScene.currentInstance.hideTurretMenu();
        } else {
            this.hidePauseMenu();
        }
    }

    public hidePauseMenu(): void {

        if (this.pauseMenu) {
            BattleManager.resume();
            this.pauseMenu.destroy();
            this.pauseMenu = null;
            this.pauseMenu = null;
        }
    }

    public clearBoosterEnemies(): void {

        // DONDE ANTES HABIA UN ENEMIGO PONER UN DESTELLO
        // COMO MAXIMO 35
        // HACEMOS UN SHUFFLE DEL ARRAY DE ENEMIGOS PARA QUE AL HABER SOLO 25 NO SE VEA RARO
        this.enemyActors = Phaser.Utils.Array.Shuffle(this.enemyActors);

        for (let i = 0; i < 35; i++) {

            const enemy = this.enemyActors[i];

            if (enemy) {

                const effect = new Phaser.GameObjects.Sprite(this.scene, enemy.x, enemy.y, "texture_atlas_1", "fx_upgrades_towers_cards_01");
                this.scene.add.existing(effect);

                effect.scale *= 1.25;
                effect.anims.play("gold_turret");
                this.board.actorsContainer.add(effect);

                effect.on("animationcomplete", () => {
                    effect.destroy();
                }, this);
            }
        }

        for (let i = 0; i < this.enemyActors.length; i++) {

            const enemy = this.enemyActors[i];
            this.enemyActorsPool.onEnemyRemovedFromBoard(enemy);
            this.board.actorsContainer.remove(enemy);
        }

        this.enemyActors = [];

        AudioManager.playSoundEffect("enemies_cleared");
    }

    public onGameOver(): void {

        if (this.pauseMenu) {
            this.hidePauseMenu();
        }
    }

    public showRoundCompletedLayer(): void {

        if (GameVars.timeStepFactor === 8 || GameVars.autoSendWave || !GameVars.gameData.tutorialSeen) {

            return;
        }

        this.roundCompletedLayer = new RoundCompletedLayer(this.scene);
        this.add(this.roundCompletedLayer);

        this.showTurretsLevelLabel();
    }

    public hideRoundCompletedLayer(): void {

        this.remove(this.roundCompletedLayer);
        this.roundCompletedLayer = null;
    }

    public setTurretPrePosition(c: number, r: number): void {

        if (!this.prePosition.visible) {
            this.prePosition.visible = true;
        }

        let canBeAdded = true;

        if (c < 0 || c >= this.board.boardMatrix[0].length || r < 0 || r >= this.board.boardMatrix.length) {
            canBeAdded = false;
        } else {
            if (this.board.boardMatrix[r][c] === 1) {
                canBeAdded = false;
            }
        }

        for (let i = 0; i < this.turretActors.length; i++) {

            if (this.turretActors[i].turret.position.c === c && this.turretActors[i].turret.position.r === r) {
                canBeAdded = false;
            }
        }

        for (let i = 0; i < GameVars.plateausCells.length; i++) {

            if (GameVars.plateausCells[i].c === c && GameVars.plateausCells[i].r === r) {
                canBeAdded = false;
            }
        }

        if (!GameVars.gameData.tutorialSeen && !(this.tutorialPosition.c === c && this.tutorialPosition.r === r)) {
            canBeAdded = false;
        }

        this.prePosition.clear();

        if (canBeAdded) {

            this.prePosition.fillStyle(0xffffff, .5);
            this.prePosition.fillRect(-30, -30, 60, 60);

            if (this.lastPrePositionTurret !== null && (this.lastPrePositionTurret.c !== c || this.lastPrePositionTurret.r !== r)) {
                AudioManager.playSoundEffect("drag_over_buildable_cell");
            }

        } else {

            this.prePosition.fillStyle(0xff0000, .5);
            this.prePosition.fillRect(-30, -30, 60, 60);

            if (this.lastPrePositionTurret !== null && (this.lastPrePositionTurret.c !== c || this.lastPrePositionTurret.r !== r)) {
                AudioManager.playSoundEffect("drag_over_non_buildable_cell");
            }
        }

        this.lastPrePositionTurret = { c: c, r: r };

        this.prePosition.setPosition(GameConstants.CELLS_SIZE * c + GameConstants.CELLS_SIZE / 2, GameConstants.CELLS_SIZE * r + GameConstants.CELLS_SIZE / 2);
    }

    public showTurretsLevelLabel(): void {

        for (let i = 0; i < this.turretActors.length; i++) {
            this.turretActors[i].showLevel();
        }
    }

    public tutorialTurretLevelUp(): void {
        if (TutorialManager.State === "waiting_for_turretlevelup") {
            this.turretActors[0].level = 1;
        }

        this.turretActors[0].level++;
        this.turretActors[0].hideLevel();
        this.turretActors[0].showLevel();
    }

    public activeNextWave(): void {

        if (!GameVars.gameData.tutorialSeen) {
            return;
        }

        this.scene.tweens.add({
            targets: [this.nextWaveButton, this.nextWaveText],
            alpha: 1,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 200
        });
    }

    public onClickNextWave(): void {

        if (this.nextWaveButton.alpha !== 1 || GameVars.paused || GameVars.gameOver) {
            return;
        }

        if (TutorialManager.State === "waiting_for_tapnewwave") {
            this.handCursor?.destroy();
            this.handCursor = null;
            TutorialManager.onFirstWaveReleased();
        }

        if (TutorialManager.State === "waiting_for_tapnewwavehorde" && this.handCursor) {
            this.handCursor.destroy();
            this.handCursor = null;
            TutorialManager.onHordeReleased();
        }

        this.nextWaveButton.alpha = 0;
        this.nextWaveText.alpha = 0;

        const bonus = BattleManager.newWave();

        if (bonus > 0) {

            const bonusLabelContainer = new Phaser.GameObjects.Container(this.scene);
            bonusLabelContainer.x = this.nextWaveButton.x - 20;
            bonusLabelContainer.y = this.nextWaveButton.y + 5;
            this.board.add(bonusLabelContainer);

            const coinIcon = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "info_icon_gold");
            coinIcon.setScale(.6);
            bonusLabelContainer.add(coinIcon);

            const bonusLabel = new Phaser.GameObjects.Text(this.scene, coinIcon.x + 12, coinIcon.y, GameVars.formatNumber(bonus), { fontFamily: "Supercell", fontSize: "20px", color: "#FFE734" });
            bonusLabel.setOrigin(0, .5);
            bonusLabel.setStroke("#B35B20", 4);
            bonusLabel.setShadow(1, 1.5, "#700000", 0, true, false);
            bonusLabelContainer.add(bonusLabel);

            this.scene.tweens.add({
                targets: bonusLabelContainer,
                y: bonusLabelContainer.y - 15,
                ease: Phaser.Math.Easing.Cubic.Out,
                duration: 450,
                delay: 150,
                onComplete: function (): void {
                    this.scene.tweens.add({
                        targets: bonusLabelContainer,
                        alpha: 0,
                        ease: Phaser.Math.Easing.Cubic.Out,
                        duration: 250,
                        onComplete: function (): void {
                            bonusLabelContainer.destroy();
                        },
                        onCompleteScope: this
                    });
                },
                onCompleteScope: this
            });

            AudioManager.playSoundEffect("coins_emitted");

        } else {

            AudioManager.playSoundEffect("btn_click");
        }
    }

    public showNextWaveButtonInTutorial(): void {

        this.nextWaveButton.visible = true;
        this.nextWaveText.visible = true;
        this.nextWaveButton.alpha = 1;
        this.nextWaveText.alpha = 1;

        this.handCursor = new Phaser.GameObjects.Image(this.scene, this.nextWaveButton.x + 50, this.nextWaveButton.y, "texture_atlas_1", "hand_cursor");
        this.handCursor.setOrigin(.2);
        this.board.add(this.handCursor);

        let px = this.handCursor.x + 25;

        if (this.nextWaveButton.scaleX < 0) {
            this.handCursor.x = this.nextWaveButton.x - 70;
            this.handCursor.scaleX = -1;

            px = this.handCursor.x - 25;
        }

        this.scene.tweens.add({
            targets: this.handCursor,
            x: px,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 750,
            yoyo: false,
            repeat: -1
        });
    }

    public hideButtonsAtTutorialStart(): void {

        this.nextWaveButton.visible = false;
        this.nextWaveText.visible = false;
    }

    public updateNextWaveButtonToGold(): void {

        this.scene.time.delayedCall(200, function (): void {
            this.nextWaveButton.setFrame((this.nextWaveButton.frame.name === "box_next_wave") ? "box_next wave_gold" : "box_next_wave_side_gold");
        }, [], this);

        const effect = new Phaser.GameObjects.Sprite(this.scene, this.nextWaveButton.x, this.nextWaveButton.y, "texture_atlas_1", "fx_upgrades_towers_cards_01");
        this.scene.add.existing(effect);
        this.board.add(effect);
        effect.scale *= 2;
        effect.anims.play("gold_turret");
    }

    public hideTurretsLevelLabel(): void {

        if (GameVars.semiPaused) {

            this.showTurretsLevelLabel();
            return;
        }

        for (let i = 0; i < this.turretActors.length; i++) {
            this.turretActors[i].hideLevel();
        }
    }

    private createNextWaveTween(): void {

        const fast = GameVars.waveOver;
        const small = this.nextWaveButton.scaleY > 1;
        const factor = (this.nextWaveButton.scaleX < 0) ? -1 : 1;

        this.scene.tweens.add({
            targets: this.nextWaveButton,
            scaleX: ((small) ? 1 : (fast) ? 1.2 : 1.1) * factor,
            scaleY: (small) ? 1 : (fast) ? 1.2 : 1.1,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: (fast) ? 250 : 600,
            onComplete: () => {
                this.createNextWaveTween();
            },
            onCompleteScope: this,
            yoyo: true,
            delay: (fast) ? 100 : 800
        });

        this.scene.tweens.add({
            targets: this.nextWaveText,
            scaleX: (small) ? 1 : (fast) ? 1.2 : 1.1,
            scaleY: (small) ? 1 : (fast) ? 1.2 : 1.1,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: (fast) ? 250 : 600,
            onCompleteScope: this,
            yoyo: true,
            delay: (fast) ? 100 : 800
        });
    }

    private stopShaking(): void {

        this.shaking = false;

        this.x = GameConstants.GAME_WIDTH / 2;
        this.y = GameConstants.GAME_HEIGHT / 2 - GameConstants.CELLS_SIZE / 2;
    }

    private addObstacles(): void {

        for (let i = 0; i < GameVars.obstaclesBoard.length; i++) {
            for (let j = 0; j < GameVars.obstaclesBoard[0].length; j++) {

                if (GameVars.obstaclesBoard[i][j] === 99) {

                    const img = this.scene.add.sprite(- GameConstants.CELLS_SIZE + GameConstants.CELLS_SIZE * j + GameConstants.CELLS_SIZE / 2, - GameConstants.CELLS_SIZE * 8 + GameConstants.CELLS_SIZE * i + GameConstants.CELLS_SIZE / 2, "texture_atlas_1");
                    img.anims.play("water");
                    this.waterContainer.add(img);

                    this.addWaterPatches(i, j);

                } else if (GameVars.obstaclesBoard[i][j] !== 0) {

                    const img = GameManager.generateObstacle(this.scene, - GameConstants.CELLS_SIZE + GameConstants.CELLS_SIZE * j + GameConstants.CELLS_SIZE / 2, - GameConstants.CELLS_SIZE * 7 + GameConstants.CELLS_SIZE * i - 5, GameVars.obstaclesBoard[i][j]);
                    img.setOrigin(.5, 1);
                    this.board.actorsContainer.add(img);

                    if (GameVars.enemiesPathCells[0].c !== -1 && GameVars.enemiesPathCells[0].c !== this.board.boardMatrix[0].length) {

                        // si estamos cerca de el portal no aparecen los obstaculos
                        if (i === GameVars.enemiesPathCells[0].r + 8 && j === GameVars.enemiesPathCells[0].c + 1 - 1) {
                            img.visible = false;
                        } else if (i === GameVars.enemiesPathCells[0].r + 8 && j === GameVars.enemiesPathCells[0].c + 1 + 1) {
                            img.visible = false;
                        } else if (i === GameVars.enemiesPathCells[0].r + 8 - 1 && j === GameVars.enemiesPathCells[0].c + 1 - 1) {
                            img.visible = false;
                        } else if (i === GameVars.enemiesPathCells[0].r + 8 - 1 && j === GameVars.enemiesPathCells[0].c + 1 + 1) {
                            img.visible = false;
                        } else if (i === GameVars.enemiesPathCells[0].r + 8 - 1 && j === GameVars.enemiesPathCells[0].c + 1) {
                            img.visible = false;
                        }
                    }
                }

                this.addWaterEdges(i, j);
            }
        }
    }

    private isAllWater(i: number, j: number): boolean {

        if (GameVars.obstaclesBoard[i - 1] && GameVars.obstaclesBoard[i - 1][j] !== 99) {
            return false;
        } else if (GameVars.obstaclesBoard[i + 1] && GameVars.obstaclesBoard[i + 1][j] !== 99) {
            return false;
        } else if (GameVars.obstaclesBoard[i][j - 1] !== 99) {
            return false;
        } else if (GameVars.obstaclesBoard[i][j + 1] !== 99) {
            return false;
        }

        return true;
    }

    private addWaterPatches(i: number, j: number) {

        // top
        if (i - 8 !== 0 && this.board.boardMatrix[i - 8 - 1] && this.board.boardMatrix[i - 8 - 1][j - 1] === 1) {

            let name = "cell_dirt_angle_00";
            let scaleY = 1;

            if (GameVars.obstaclesBoard[i][j - 1] !== 99 && GameVars.obstaclesBoard[i][j + 1] !== 99) {
                name = "cell_dirt_angle_02";
            } else if (GameVars.obstaclesBoard[i][j + 1] !== 99) {
                name = "cell_dirt_angle_01";
            } else if (GameVars.obstaclesBoard[i][j - 1] !== 99) {
                name = "cell_dirt_angle_01";
                scaleY = -1;
            }

            let img = this.scene.add.sprite(- GameConstants.CELLS_SIZE + GameConstants.CELLS_SIZE * j + GameConstants.CELLS_SIZE / 2, - GameConstants.CELLS_SIZE * 8 + GameConstants.CELLS_SIZE * i + GameConstants.CELLS_SIZE / 2, "texture_atlas_1", name);
            img.scaleY = scaleY;
            img.setAngle(90);
            this.waterContainer.add(img);
        } else if (GameVars.obstaclesBoard[i - 1] && GameVars.obstaclesBoard[i - 1][j] !== 99) {

            let name = "cell_grass_angle_00";
            let scaleY = 1;

            if (GameVars.obstaclesBoard[i][j - 1] !== 99 && GameVars.obstaclesBoard[i][j + 1] !== 99) {
                name = "cell_grass_angle_02";
            } else if (GameVars.obstaclesBoard[i][j + 1] !== 99) {
                name = "cell_grass_angle_01";
            } else if (GameVars.obstaclesBoard[i][j - 1] !== 99) {
                name = "cell_grass_angle_01";
                scaleY = -1;
            }

            let img = this.scene.add.sprite(- GameConstants.CELLS_SIZE + GameConstants.CELLS_SIZE * j + GameConstants.CELLS_SIZE / 2, - GameConstants.CELLS_SIZE * 8 + GameConstants.CELLS_SIZE * i + GameConstants.CELLS_SIZE / 2, "texture_atlas_1", name);
            img.scaleY = scaleY;
            img.setAngle(90);
            this.waterContainer.add(img);
        }

        // left
        if (this.board.boardMatrix[i - 8] && this.board.boardMatrix[i - 8][j - 1 - 1] === 1) {

            let name = "cell_dirt_angle_00";

            if (GameVars.obstaclesBoard[i - 1][j] !== 99) {
                name = "cell_dirt_angle_01";
            }

            let img = this.scene.add.sprite(- GameConstants.CELLS_SIZE + GameConstants.CELLS_SIZE * j + GameConstants.CELLS_SIZE / 2, - GameConstants.CELLS_SIZE * 8 + GameConstants.CELLS_SIZE * i + GameConstants.CELLS_SIZE / 2, "texture_atlas_1", name);
            this.waterContainer.add(img);
        } else if (GameVars.obstaclesBoard[i] && GameVars.obstaclesBoard[i][j - 1] !== 99) {

            let name = "cell_grass_angle_00";

            if (GameVars.obstaclesBoard[i - 1] && GameVars.obstaclesBoard[i - 1][j] !== 99) {
                name = "cell_grass_angle_01";
            }

            let img = this.scene.add.sprite(- GameConstants.CELLS_SIZE + GameConstants.CELLS_SIZE * j + GameConstants.CELLS_SIZE / 2, - GameConstants.CELLS_SIZE * 8 + GameConstants.CELLS_SIZE * i + GameConstants.CELLS_SIZE / 2, "texture_atlas_1", name);
            this.waterContainer.add(img);
        }

        // right
        if (this.board.boardMatrix[i - 8] && this.board.boardMatrix[i - 8][j - 1 + 1] === 1) {

            let name = "cell_dirt_angle_00";

            if (GameVars.obstaclesBoard[i - 1][j] !== 99) {
                name = "cell_dirt_angle_01";
            }

            let img = this.scene.add.sprite(- GameConstants.CELLS_SIZE + GameConstants.CELLS_SIZE * j + GameConstants.CELLS_SIZE / 2, - GameConstants.CELLS_SIZE * 8 + GameConstants.CELLS_SIZE * i + GameConstants.CELLS_SIZE / 2, "texture_atlas_1", name);
            img.scaleX = -1;
            this.waterContainer.add(img);
        } else if (GameVars.obstaclesBoard[i] && GameVars.obstaclesBoard[i][j + 1] !== 99) {

            let name = "cell_grass_angle_00";

            if (GameVars.obstaclesBoard[i - 1] && GameVars.obstaclesBoard[i - 1][j] !== 99) {
                name = "cell_grass_angle_01";
            }

            let img = this.scene.add.sprite(- GameConstants.CELLS_SIZE + GameConstants.CELLS_SIZE * j + GameConstants.CELLS_SIZE / 2, - GameConstants.CELLS_SIZE * 8 + GameConstants.CELLS_SIZE * i + GameConstants.CELLS_SIZE / 2, "texture_atlas_1", name);
            img.scaleX = -1;
            this.waterContainer.add(img);
        }

        // corner left
        if (this.board.boardMatrix[i - 8 - 1] && i - 8 !== 0 && j - 1 !== 0 && this.board.boardMatrix[i - 8 - 1][j - 1 - 1] === 1) {
            if (GameVars.obstaclesBoard[i - 1] && GameVars.obstaclesBoard[i - 1][j] === 99 && GameVars.obstaclesBoard[i] && GameVars.obstaclesBoard[i][j - 1] === 99) {
                let img = this.scene.add.sprite(- GameConstants.CELLS_SIZE + GameConstants.CELLS_SIZE * j + GameConstants.CELLS_SIZE / 2, - GameConstants.CELLS_SIZE * 8 + GameConstants.CELLS_SIZE * i + GameConstants.CELLS_SIZE / 2, "texture_atlas_1", "cell_dirt_corner");
                this.waterContainer.add(img);
            }
        } else if (GameVars.obstaclesBoard[i - 1] && GameVars.obstaclesBoard[i - 1][j - 1] !== 99) {
            if (GameVars.obstaclesBoard[i - 1][j] === 99 && GameVars.obstaclesBoard[i] && GameVars.obstaclesBoard[i][j - 1] === 99) {
                let img = this.scene.add.sprite(- GameConstants.CELLS_SIZE + GameConstants.CELLS_SIZE * j + GameConstants.CELLS_SIZE / 2, - GameConstants.CELLS_SIZE * 8 + GameConstants.CELLS_SIZE * i + GameConstants.CELLS_SIZE / 2, "texture_atlas_1", "cell_grass_corner");
                this.waterContainer.add(img);
            }
        }

        // corner right
        if (this.board.boardMatrix[i - 8 - 1] && i - 8 !== 0 && this.board.boardMatrix[i - 8 - 1][j - 1 + 1] === 1) {
            if (GameVars.obstaclesBoard[i - 1] && GameVars.obstaclesBoard[i - 1][j] === 99 && GameVars.obstaclesBoard[i] && GameVars.obstaclesBoard[i][j + 1] === 99) {
                let img = this.scene.add.sprite(- GameConstants.CELLS_SIZE + GameConstants.CELLS_SIZE * j + GameConstants.CELLS_SIZE / 2, - GameConstants.CELLS_SIZE * 8 + GameConstants.CELLS_SIZE * i + GameConstants.CELLS_SIZE / 2, "texture_atlas_1", "cell_dirt_corner");
                img.scaleX = -1;
                this.waterContainer.add(img);
            }
        } else if (GameVars.obstaclesBoard[i - 1] && GameVars.obstaclesBoard[i - 1][j + 1] !== 99) {
            if (GameVars.obstaclesBoard[i - 1][j] === 99 && GameVars.obstaclesBoard[i] && GameVars.obstaclesBoard[i][j + 1] === 99) {
                let img = this.scene.add.sprite(- GameConstants.CELLS_SIZE + GameConstants.CELLS_SIZE * j + GameConstants.CELLS_SIZE / 2, - GameConstants.CELLS_SIZE * 8 + GameConstants.CELLS_SIZE * i + GameConstants.CELLS_SIZE / 2, "texture_atlas_1", "cell_grass_corner");
                img.scaleX = -1;
                this.waterContainer.add(img);
            }
        }
    }

    private addWaterEdges(i: number, j: number): string {

        let boarderCells = [0, 0, 0, 1, 1, 1, 0, 0];

        if (GameVars.obstaclesBoard[i - 1] && GameVars.obstaclesBoard[i - 1][j] && GameVars.obstaclesBoard[i - 1][j] === 99) {
            boarderCells[0] = 1;
        }

        if (GameVars.obstaclesBoard[i - 1] && GameVars.obstaclesBoard[i - 1][j + 1] && GameVars.obstaclesBoard[i - 1][j + 1] === 99) {
            boarderCells[1] = 1;
        }

        if (GameVars.obstaclesBoard[i] && GameVars.obstaclesBoard[i][j + 1] && GameVars.obstaclesBoard[i][j + 1] === 99) {
            boarderCells[2] = 1;
        }

        if (GameVars.obstaclesBoard[i] && GameVars.obstaclesBoard[i][j - 1] && GameVars.obstaclesBoard[i][j - 1] === 99) {
            boarderCells[6] = 1;
        }

        if (GameVars.obstaclesBoard[i - 1] && GameVars.obstaclesBoard[i - 1][j - 1] && GameVars.obstaclesBoard[i - 1][j - 1] === 99) {
            boarderCells[7] = 1;
        }

        let cellName = "";

        if (boarderCells[0] === 0 && boarderCells[2] === 0 && boarderCells[4] === 0 && boarderCells[6] === 0) {
            cellName += "01";
        } else if (boarderCells[0] === 0 && boarderCells[2] === 0 && boarderCells[6] === 0) {
            cellName += "03";
        } else if (boarderCells[0] === 0 && boarderCells[2] === 0 && boarderCells[4] === 0) {
            cellName += "04";
        } else if (boarderCells[6] === 0 && boarderCells[2] === 0 && boarderCells[4] === 0) {
            cellName += "05";
        } else if (boarderCells[6] === 0 && boarderCells[0] === 0 && boarderCells[4] === 0) {
            cellName += "06";
        } else if (boarderCells[0] === 0 && boarderCells[2] === 0) {
            if (boarderCells[5] === 1) {
                cellName += "07";
            } else {
                cellName += "38";
            }
        } else if (boarderCells[0] === 0 && boarderCells[6] === 0) {
            if (boarderCells[3] === 1) {
                cellName += "08";
            } else {
                cellName += "41";
            }
        } else if (boarderCells[2] === 0 && boarderCells[4] === 0) {
            if (boarderCells[7] === 1) {
                cellName += "09";
            } else {
                cellName += "39";
            }
        } else if (boarderCells[6] === 0 && boarderCells[4] === 0) {
            if (boarderCells[1] === 1) {
                cellName += "10";
            } else {
                cellName += "40";
            }
        } else if (boarderCells[0] === 0 && boarderCells[4] === 0) {
            cellName += "11";
        } else if (boarderCells[2] === 0 && boarderCells[6] === 0) {
            cellName += "12";
        } else if (boarderCells[6] === 0) {
            if (boarderCells[1] === 0 && boarderCells[3] === 0) {
                cellName += "45";
            } else if (boarderCells[1] === 0) {
                cellName += "35";
            } else if (boarderCells[3] === 0) {
                cellName += "36";
            } else {
                cellName += "13";
            }

        } else if (boarderCells[0] === 0) {
            if (boarderCells[5] === 0 && boarderCells[3] === 0) {
                cellName += "42";
            } else if (boarderCells[5] === 0) {
                cellName += "30";
            } else if (boarderCells[3] === 0) {
                cellName += "37";
            } else {
                cellName += "14";
            }
        } else if (boarderCells[2] === 0) {
            if (boarderCells[5] === 0 && boarderCells[7] === 0) {
                cellName += "43";
            } else if (boarderCells[5] === 0) {
                cellName += "31";
            } else if (boarderCells[7] === 0) {
                cellName += "32";
            } else {
                cellName += "15";
            }
        } else if (boarderCells[4] === 0) {
            if (boarderCells[1] === 0 && boarderCells[7] === 0) {
                cellName += "44";
            } else if (boarderCells[1] === 0) {
                cellName += "34";
            } else if (boarderCells[7] === 0) {
                cellName += "33";
            } else {
                cellName += "16";
            }

        } else if (boarderCells[1] === 0 && boarderCells[3] === 0 && boarderCells[5] === 0 && boarderCells[7] === 0) {
            cellName += "17";
        } else if (boarderCells[3] === 0 && boarderCells[5] === 0 && boarderCells[7] === 0) {
            cellName += "18";
        } else if (boarderCells[5] === 0 && boarderCells[1] === 0 && boarderCells[7] === 0) {
            cellName += "19";
        } else if (boarderCells[3] === 0 && boarderCells[1] === 0 && boarderCells[7] === 0) {
            cellName += "20";
        } else if (boarderCells[3] === 0 && boarderCells[1] === 0 && boarderCells[5] === 0) {
            cellName += "21";
        } else if (boarderCells[3] === 0 && boarderCells[5] === 0) {
            cellName += "22";
        } else if (boarderCells[7] === 0 && boarderCells[5] === 0) {
            cellName += "23";
        } else if (boarderCells[7] === 0 && boarderCells[1] === 0) {
            cellName += "24";
        } else if (boarderCells[3] === 0 && boarderCells[1] === 0) {
            cellName += "25";
        } else if (boarderCells[5] === 0) {
            cellName += "26";
        } else if (boarderCells[7] === 0) {
            cellName += "27";
        } else if (boarderCells[1] === 0) {
            cellName += "28";
        } else if (boarderCells[3] === 0) {
            cellName += "29";
        } else {
            cellName += "02";
        }
        return cellName;
    }

    private drawDebugGeometry(): void {

        const path = new Phaser.GameObjects.Graphics(this.scene);

        path.lineStyle(2, 0xFFA500);

        for (let i = 0; i < GameVars.enemiesPathCells.length - 1; i++) {

            let x = (GameVars.enemiesPathCells[i].c + .5) * GameConstants.CELLS_SIZE;
            let y = (GameVars.enemiesPathCells[i].r + .5) * GameConstants.CELLS_SIZE;

            path.moveTo(x, y);

            x = (GameVars.enemiesPathCells[i + 1].c + .5) * GameConstants.CELLS_SIZE;
            y = (GameVars.enemiesPathCells[i + 1].r + .5) * GameConstants.CELLS_SIZE;

            path.lineTo(x, y);

            path.stroke();
        }

        this.board.add(path);
    }

    private onPointerDown(): void {

        if (!GameVars.gameData.tutorialSeen) {
            return;
        }

        if (this.pauseMenu) {
            this.hidePauseMenu();
        } else {
            this.hideRangeCircles();
            BattleScene.currentInstance.hideTurretMenu();
        }
    }

    private createAnimations(): void {

        const animationData = [
            { suffix: "", atlas: "texture_atlas_2" },
            { suffix: "_teleported", atlas: "texture_atlas_4" },
            { suffix: "_frozen", atlas: "texture_atlas_5" },
            { suffix: "_frozen_and_teleported", atlas: "texture_atlas_6" }
        ];

        for (let i = 0; i < animationData.length; i++) {

            const suffix = animationData[i].suffix;
            const atlas = animationData[i].atlas;

            this.scene.anims.create({ key: "enemy_blob_back" + suffix, frames: this.scene.anims.generateFrameNames(atlas, { prefix: "enemy_blob_back_", start: 1, end: 11, zeroPad: 2, suffix: "" }), frameRate: GameConstants.ENEMY_FRAMERATE, repeat: -1, skipMissedFrames: false });
            this.scene.anims.create({ key: "enemy_blob_front" + suffix, frames: this.scene.anims.generateFrameNames(atlas, { prefix: "enemy_blob_front_", start: 1, end: 11, zeroPad: 2, suffix: "" }), frameRate: GameConstants.ENEMY_FRAMERATE, repeat: -1, skipMissedFrames: false });
            this.scene.anims.create({ key: "enemy_blob_left" + suffix, frames: this.scene.anims.generateFrameNames(atlas, { prefix: "enemy_blob_left_", start: 1, end: 11, zeroPad: 2, suffix: "" }), frameRate: GameConstants.ENEMY_FRAMERATE, repeat: -1, skipMissedFrames: false });
            this.scene.anims.create({ key: "enemy_blob_right" + suffix, frames: this.scene.anims.generateFrameNames(atlas, { prefix: "enemy_blob_right_", start: 1, end: 11, zeroPad: 2, suffix: "" }), frameRate: GameConstants.ENEMY_FRAMERATE, repeat: -1, skipMissedFrames: false });

            this.scene.anims.create({ key: "enemy_flier_back" + suffix, frames: this.scene.anims.generateFrameNames(atlas, { prefix: "enemy_flier_back_", start: 1, end: 11, zeroPad: 2, suffix: "" }), frameRate: GameConstants.ENEMY_FRAMERATE, repeat: -1, skipMissedFrames: false });
            this.scene.anims.create({ key: "enemy_flier_front" + suffix, frames: this.scene.anims.generateFrameNames(atlas, { prefix: "enemy_flier_front_", start: 1, end: 11, zeroPad: 2, suffix: "" }), frameRate: GameConstants.ENEMY_FRAMERATE, repeat: -1, skipMissedFrames: false });
            this.scene.anims.create({ key: "enemy_flier_left" + suffix, frames: this.scene.anims.generateFrameNames(atlas, { prefix: "enemy_flier_left_", start: 1, end: 11, zeroPad: 2, suffix: "" }), frameRate: GameConstants.ENEMY_FRAMERATE, repeat: -1, skipMissedFrames: false });
            this.scene.anims.create({ key: "enemy_flier_right" + suffix, frames: this.scene.anims.generateFrameNames(atlas, { prefix: "enemy_flier_right_", start: 1, end: 11, zeroPad: 2, suffix: "" }), frameRate: GameConstants.ENEMY_FRAMERATE, repeat: -1, skipMissedFrames: false });

            this.scene.anims.create({ key: "enemy_healer_back" + suffix, frames: this.scene.anims.generateFrameNames(atlas, { prefix: "enemy_healer_back_", start: 1, end: 11, zeroPad: 2, suffix: "" }), frameRate: GameConstants.ENEMY_FRAMERATE, repeat: -1, skipMissedFrames: false });
            this.scene.anims.create({ key: "enemy_healer_front" + suffix, frames: this.scene.anims.generateFrameNames(atlas, { prefix: "enemy_healer_front_", start: 1, end: 11, zeroPad: 2, suffix: "" }), frameRate: GameConstants.ENEMY_FRAMERATE, repeat: -1, skipMissedFrames: false });
            this.scene.anims.create({ key: "enemy_healer_left" + suffix, frames: this.scene.anims.generateFrameNames(atlas, { prefix: "enemy_healer_left_", start: 1, end: 11, zeroPad: 2, suffix: "" }), frameRate: GameConstants.ENEMY_FRAMERATE, repeat: -1, skipMissedFrames: false });
            this.scene.anims.create({ key: "enemy_healer_right" + suffix, frames: this.scene.anims.generateFrameNames(atlas, { prefix: "enemy_healer_right_", start: 1, end: 11, zeroPad: 2, suffix: "" }), frameRate: GameConstants.ENEMY_FRAMERATE, repeat: -1, skipMissedFrames: false });

            this.scene.anims.create({ key: "enemy_runner_back" + suffix, frames: this.scene.anims.generateFrameNames(atlas, { prefix: "enemy_runner_back_", start: 1, end: 11, zeroPad: 2, suffix: "" }), frameRate: GameConstants.ENEMY_FRAMERATE * 2, repeat: -1, skipMissedFrames: false });
            this.scene.anims.create({ key: "enemy_runner_front" + suffix, frames: this.scene.anims.generateFrameNames(atlas, { prefix: "enemy_runner_front_", start: 1, end: 11, zeroPad: 2, suffix: "" }), frameRate: GameConstants.ENEMY_FRAMERATE * 2, repeat: -1, skipMissedFrames: false });
            this.scene.anims.create({ key: "enemy_runner_left" + suffix, frames: this.scene.anims.generateFrameNames(atlas, { prefix: "enemy_runner_left_", start: 1, end: 11, zeroPad: 2, suffix: "" }), frameRate: GameConstants.ENEMY_FRAMERATE * 2, repeat: -1, skipMissedFrames: false });
            this.scene.anims.create({ key: "enemy_runner_right" + suffix, frames: this.scene.anims.generateFrameNames(atlas, { prefix: "enemy_runner_right_", start: 1, end: 11, zeroPad: 2, suffix: "" }), frameRate: GameConstants.ENEMY_FRAMERATE * 2, repeat: -1, skipMissedFrames: false });

            this.scene.anims.create({ key: "enemy_soldier_back" + suffix, frames: this.scene.anims.generateFrameNames(atlas, { prefix: "enemy_soldier_back_", start: 1, end: 11, zeroPad: 2, suffix: "" }), frameRate: GameConstants.ENEMY_FRAMERATE, repeat: -1, skipMissedFrames: false });
            this.scene.anims.create({ key: "enemy_soldier_front" + suffix, frames: this.scene.anims.generateFrameNames(atlas, { prefix: "enemy_soldier_front_", start: 1, end: 11, zeroPad: 2, suffix: "" }), frameRate: GameConstants.ENEMY_FRAMERATE, repeat: -1, skipMissedFrames: false });
            this.scene.anims.create({ key: "enemy_soldier_left" + suffix, frames: this.scene.anims.generateFrameNames(atlas, { prefix: "enemy_soldier_left_", start: 1, end: 11, zeroPad: 2, suffix: "" }), frameRate: GameConstants.ENEMY_FRAMERATE, repeat: -1, skipMissedFrames: false });
            this.scene.anims.create({ key: "enemy_soldier_right" + suffix, frames: this.scene.anims.generateFrameNames(atlas, { prefix: "enemy_soldier_right_", start: 1, end: 11, zeroPad: 2, suffix: "" }), frameRate: GameConstants.ENEMY_FRAMERATE, repeat: -1, skipMissedFrames: false });
        }

        this.scene.anims.create({ key: "bolt", frames: this.scene.anims.generateFrameNames("texture_atlas_3", { prefix: "bolt_", start: 0, end: 13, zeroPad: 2, suffix: "" }), frameRate: 30, repeat: 0, skipMissedFrames: false });
        this.scene.anims.create({ key: "flame", frames: this.scene.anims.generateFrameNames("texture_atlas_3", { prefix: "flame_", start: 0, end: 29, zeroPad: 2, suffix: "" }), frameRate: 30, repeat: 0, skipMissedFrames: false });
        this.scene.anims.create({ key: "plasma_blast", frames: this.scene.anims.generateFrameNames("texture_atlas_3", { prefix: "plasma_blast_", start: 1, end: 3, zeroPad: 1, suffix: "" }), frameRate: 30, repeat: -1, skipMissedFrames: false });
        this.scene.anims.create({ key: "heal", frames: this.scene.anims.generateFrameNames("texture_atlas_3", { prefix: "heal_fx_", start: 1, end: 36, zeroPad: 2, suffix: "" }), frameRate: 30, repeat: 0, skipMissedFrames: false });
        this.scene.anims.create({ key: "electricity", frames: this.scene.anims.generateFrameNames("texture_atlas_1", { prefix: "electricity_", start: 0, end: 11, zeroPad: 2, suffix: "" }), frameRate: 24, repeat: -1, skipMissedFrames: false });
        this.scene.anims.create({ key: "fire", frames: this.scene.anims.generateFrameNames("texture_atlas_1", { prefix: "fire_", start: 0, end: 23, zeroPad: 2, suffix: "" }), frameRate: 24, repeat: -1, skipMissedFrames: false });
        this.scene.anims.create({ key: "plasma", frames: this.scene.anims.generateFrameNames("texture_atlas_1", { prefix: "plasma_", start: 0, end: 17, zeroPad: 2, suffix: "" }), frameRate: 24, repeat: -1, skipMissedFrames: false });
        this.scene.anims.create({ key: "snow_shoot", frames: this.scene.anims.generateFrameNames("texture_atlas_1", { prefix: "fx_snow_shoot_", start: 1, end: 16, zeroPad: 2, suffix: "" }), frameRate: 30, repeat: 0, skipMissedFrames: false });
        this.scene.anims.create({ key: "explosion", frames: this.scene.anims.generateFrameNames("texture_atlas_1", { prefix: "explossion_", start: 1, end: 28, zeroPad: 2, suffix: "" }), frameRate: 60, repeat: 0, skipMissedFrames: false });
        this.scene.anims.create({ key: "crystal_top", frames: this.scene.anims.generateFrameNames("texture_atlas_1", { prefix: "crystal_1_top_", start: 1, end: 18, zeroPad: 2, suffix: "" }), frameRate: 16, repeat: -1, skipMissedFrames: false });
        this.scene.anims.create({ key: "portal_inner_fx", frames: this.scene.anims.generateFrameNames("texture_atlas_1", { prefix: "portal_inner_fx_", start: 1, end: 13, zeroPad: 4, suffix: "" }), frameRate: 12, repeat: -1, skipMissedFrames: false });
        this.scene.anims.create({ key: "teleport_in", frames: this.scene.anims.generateFrameNames("texture_atlas_3", { prefix: "teleport_in_", start: 1, end: 23, zeroPad: 2, suffix: "" }), frameRate: 60, repeat: 0, skipMissedFrames: false });
        this.scene.anims.create({ key: "teleport_out", frames: this.scene.anims.generateFrameNames("texture_atlas_3", { prefix: "teleport_out_", start: 1, end: 3, zeroPad: 2, suffix: "" }), frameRate: 60, repeat: 0, skipMissedFrames: false });
        this.scene.anims.create({ key: "glue_fx", frames: this.scene.anims.generateFrameNames("texture_atlas_1", { prefix: "fx_snow_shoot_", start: 1, end: 16, zeroPad: 1, suffix: "" }), frameRate: 12, repeat: 0, skipMissedFrames: false });
        this.scene.anims.create({ key: "mine", frames: this.scene.anims.generateFrameNames("texture_atlas_1", { prefix: "mine_falling_", start: 1, end: 11, zeroPad: 2, suffix: "" }), frameRate: 30, repeat: -1, skipMissedFrames: false });
        this.scene.anims.create({ key: "splat", frames: this.scene.anims.generateFrameNames("texture_atlas_1", { prefix: "death_splash_", start: 1, end: 10, zeroPad: 2, suffix: "" }), frameRate: 20, hideOnComplete: true, skipMissedFrames: false });
        this.scene.anims.create({ key: "red-splat", frames: this.scene.anims.generateFrameNames("texture_atlas_1", { prefix: "red_splash_", start: 1, end: 10, zeroPad: 2, suffix: "" }), frameRate: 20, hideOnComplete: true, skipMissedFrames: false });
        this.scene.anims.create({ key: "electric_damage", frames: this.scene.anims.generateFrameNames("texture_atlas_1", { prefix: "electric_damage_", start: 0, end: 17, zeroPad: 2, suffix: "" }), frameRate: 30, repeat: 0, skipMissedFrames: false });
        this.scene.anims.create({ key: "chest_loop", frames: this.scene.anims.generateFrameNames("texture_atlas_1", { prefix: "icon_chest_", start: 1, end: 12, zeroPad: 2, suffix: "" }), frameRate: 24, repeat: -1, skipMissedFrames: false });
        this.scene.anims.create({ key: "gold_turret", frames: this.scene.anims.generateFrameNames("texture_atlas_1", { prefix: "fx_upgrades_towers_cards_", start: 1, end: 18, zeroPad: 2, suffix: "" }), frameRate: 30, repeat: 0, hideOnComplete: true });
        this.scene.anims.create({ key: "water", frames: this.scene.anims.generateFrameNames("texture_atlas_1", { prefix: "cell_water_", start: 1, end: 25, zeroPad: 2, suffix: "" }), frameRate: 16, repeat: -1, skipMissedFrames: false });
    }
}
