
import { GameConstants } from "../../../GameConstants";
import { GameVars } from "../../../GameVars";
import * as Creepts from "endless-siege-engine";

export class ArrowActor extends Phaser.GameObjects.Image {

    public bullet: Creepts.Arrow;
    public initialPosition: {x: number, y: number};
    public realX: number;
    public realY: number;

    private showInStage: boolean;

    constructor(scene: Phaser.Scene) {

        super(scene, 0, 0, "texture_atlas_1");

        this.setOrigin(.5, 0);
    }

    public update(time: number, delta: number): void {
        
        let smoothFactor: number;

        if (GameConstants.INTERPOLATE_TRAJECTORIES) {
            smoothFactor = .3;
        } else {
            smoothFactor = 1;
        }

        const offX = (this.bullet.x * GameConstants.CELLS_SIZE - this.realX) * smoothFactor;
        const offY = (this.bullet.y * GameConstants.CELLS_SIZE - this.realY) * smoothFactor;
        
        this.realX += offX;
        this.realY += offY;

        if (this.bullet.tick <= this.bullet.ticksToImpact) {
            this.rotation = Math.atan2(offY, offX) + Math.PI / 2;
        } else {
            this.setScale(this.scale * .75);
        }

        this.x = this.realX;
        this.y = this.realY;

        if (this.showInStage && this.showInStage) {
            const distX = this.initialPosition.x - this.x;
            const distY = this.initialPosition.y - this.y;
            if (Math.sqrt( distX * distX + distY * distY) > 45) {
                this.visible = true;
            }
        }
    }

    public reset(bullet: Creepts.Arrow): void {

        this.bullet = bullet;

        this.setFrame("archer_" + bullet.turret.grade + "_arrow");

        this.visible = false;
        this.alpha = 1;

        this.setScale(1);

        this.x = this.bullet.x * GameConstants.CELLS_SIZE;
        this.y = this.bullet.y * GameConstants.CELLS_SIZE;

        this.realX = this.x;
        this.realY = this.y;

        this.initialPosition = {x: this.x, y: this.y};

        if (GameVars.timeStepFactor === 8) {
            this.showInStage = false;
        } else {
            this.showInStage = true;
        }
    }
}
