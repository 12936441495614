import { GameConstants } from "../../../GameConstants";
import { GameVars } from "../../../GameVars";
import { AudioManager } from "../../../AudioManager";


export class Board extends Phaser.GameObjects.Container {

    public actorsContainer: Phaser.GameObjects.Container;
    public pathContainer: Phaser.GameObjects.Container;
    public boardMatrix: number[][];
    public portal: Phaser.GameObjects.Image;
    public portalFront: Phaser.GameObjects.Image;
    public portalAnimation: Phaser.GameObjects.Sprite;
    private turretFrame: Phaser.GameObjects.Image;

    private renderTextureBackground: Phaser.GameObjects.RenderTexture;

    constructor(scene: Phaser.Scene) {

        super(scene);

        this.x = - GameConstants.CELLS_SIZE * GameVars.currentMapData.size.c / 2;
        this.y = - GameConstants.CELLS_SIZE * GameVars.currentMapData.size.r / 2;

        this.pathContainer = new Phaser.GameObjects.Container(this.scene);
        this.add(this.pathContainer);

        this.renderTextureBackground = this.scene.add.renderTexture(-GameConstants.GAME_WIDTH, -GameConstants.GAME_HEIGHT, GameConstants.GAME_WIDTH * 3, GameConstants.GAME_HEIGHT * 2);
        this.pathContainer.add(this.renderTextureBackground);

        this.boardMatrix = new Array(GameVars.currentMapData.size.r);

        for (let i = 0; i < this.boardMatrix.length; i++) {
            this.boardMatrix[i] = new Array(GameVars.currentMapData.size.c).fill(2); 
        }

        for (let i = 0; i < GameVars.plateausCells.length; i++) {
            this.boardMatrix[GameVars.plateausCells[i].r][GameVars.plateausCells[i].c] = 0;
        }

        for (let i = 0; i < GameVars.enemiesPathCells.length; i++) {

            if (GameVars.enemiesPathCells[i].c < 0 || GameVars.enemiesPathCells[i].r < 0 || GameVars.enemiesPathCells[i].c >= GameVars.currentMapData.size.c || GameVars.enemiesPathCells[i].r >= GameVars.currentMapData.size.r) {
                continue;
            }

            this.boardMatrix[GameVars.enemiesPathCells[i].r][GameVars.enemiesPathCells[i].c] = 1;
        }

        for (let i = 0; i < this.boardMatrix.length + 20; i++) {

            for (let j = 0; j < this.boardMatrix[0].length + 6; j++) {
                const img = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", ((i % 2 === 0 && j % 2 === 0) || (i % 2 === 1 && j % 2 === 1)) ? "cell_grass_1" : "cell_grass_2");
                this.renderTextureBackground.draw(img, GameConstants.GAME_WIDTH + GameConstants.CELLS_SIZE * (j - 2) + GameConstants.CELLS_SIZE / 2, GameConstants.GAME_HEIGHT + GameConstants.CELLS_SIZE * (i - 10) + GameConstants.CELLS_SIZE / 2);
            }
        }

        for (let i = 0; i < this.boardMatrix.length; i++) {

            for (let j = 0; j < this.boardMatrix[i].length; j++) {

                if (this.boardMatrix[i][j] === 1) {
                    let img = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "cell_dirt");
                    this.renderTextureBackground.draw(img, GameConstants.GAME_WIDTH + GameConstants.CELLS_SIZE * j + GameConstants.CELLS_SIZE / 2, GameConstants.GAME_HEIGHT + GameConstants.CELLS_SIZE * i + GameConstants.CELLS_SIZE / 2);
                    this.addPathShadows(i, j);
                } else if (this.boardMatrix[i][j] === 2) {
                    let img = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", ((i % 2 === 0 && j % 2 === 0) || (i % 2 === 1 && j % 2 === 1)) ? "cell_grass_1" : "cell_grass_2");
                    this.renderTextureBackground.draw(img, GameConstants.GAME_WIDTH + GameConstants.CELLS_SIZE * j + GameConstants.CELLS_SIZE / 2, GameConstants.GAME_HEIGHT + GameConstants.CELLS_SIZE * i + GameConstants.CELLS_SIZE / 2);
                }
            }
        } 

        this.portal = new Phaser.GameObjects.Image(this.scene, GameConstants.CELLS_SIZE * GameVars.enemiesPathCells[0].c + GameConstants.CELLS_SIZE / 2, GameConstants.CELLS_SIZE * GameVars.enemiesPathCells[0].r + GameConstants.CELLS_SIZE, "texture_atlas_1", "portal");
        this.portal.setOrigin(.5, 1);
        this.add(this.portal);

        if (GameVars.enemiesPathCells[0].c === -1) {

            this.portalFront = new Phaser.GameObjects.Image(this.scene, GameConstants.CELLS_SIZE * GameVars.enemiesPathCells[0].c + GameConstants.CELLS_SIZE / 2, GameConstants.CELLS_SIZE * GameVars.enemiesPathCells[0].r + GameConstants.CELLS_SIZE + 8, "texture_atlas_1", "portal_side_down");
            this.portalFront.setOrigin(0, 1);
            this.add(this.portalFront);

            this.portal.setFrame("portal_side_up");
            this.portal.setOrigin(0, 1);
            this.portal.y += 8;

        } else if (GameVars.enemiesPathCells[0].c === this.boardMatrix[0].length) {

            this.portalFront = new Phaser.GameObjects.Image(this.scene, GameConstants.CELLS_SIZE * GameVars.enemiesPathCells[0].c + GameConstants.CELLS_SIZE / 2, GameConstants.CELLS_SIZE * GameVars.enemiesPathCells[0].r + GameConstants.CELLS_SIZE + 8, "texture_atlas_1", "portal_side_down");
            this.portalFront.setOrigin(0, 1);
            this.portalFront.scaleX = -1;
            this.add(this.portalFront);

            this.portal.setFrame("portal_side_up");
            this.portal.setOrigin(0, 1);
            this.portal.scaleX = -1;
            this.portal.y += 8;

        } else {
            
            this.portalAnimation = this.scene.add.sprite(GameConstants.CELLS_SIZE * GameVars.enemiesPathCells[0].c + GameConstants.CELLS_SIZE / 2, GameConstants.CELLS_SIZE * GameVars.enemiesPathCells[0].r + GameConstants.CELLS_SIZE, "texture_atlas_1");
            this.portalAnimation.setOrigin(.5, 1);
            this.add(this.portalAnimation);

            this.portalAnimation.anims.play("portal_inner_fx");

            this.bringToTop(this.portal);
        }

        this.turretFrame = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "tower_frame");
        this.turretFrame.visible = false;
        this.add(this.turretFrame);

        this.scene.tweens.add({
            targets: this.turretFrame,
            scaleX: 1.075,
            scaleY: 1.075,
            ease: Phaser.Math.Easing.Cubic.InOut,
            duration: 400,
            yoyo: true,
            repeat: -1
        });
    }

    public resetTurretFrame(x: number, y: number): void {
        this.turretFrame.visible = true;
        this.turretFrame.x = x;
        this.turretFrame.y = y - 10;
        this.bringToTop(this.turretFrame);
    }

    public hideTurretFrame(): void {
        this.turretFrame.visible = false;
    }

    public addPathShadows(i: number, j: number): void {

        // SIDE

        if (j === 0 || this.boardMatrix[i][j - 1] !== 1) {

            let img = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "shadow_side");
            this.renderTextureBackground.draw(img, GameConstants.GAME_WIDTH + GameConstants.CELLS_SIZE * j + GameConstants.CELLS_SIZE / 2, GameConstants.GAME_HEIGHT + GameConstants.CELLS_SIZE * i + GameConstants.CELLS_SIZE / 2);
        }

        if (i === 0 || this.boardMatrix[i - 1][j] !== 1) {

            let img = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1");

            let name = i === 0 || GameVars.obstaclesBoard[i - 1 + 8][j + 1] !== 99 ? "shadow_side" : "light_side_1";

            if (name === "light_side_1") {
                if (GameVars.obstaclesBoard[i - 1 + 8][j + 1 - 1] !== 99 && GameVars.obstaclesBoard[i - 1 + 8][j + 1 + 1] !== 99) {
                    name = "light_side_3";
                } else if (GameVars.obstaclesBoard[i - 1 + 8][j + 1 - 1] !== 99) {
                    name = "light_side_2";
                    img.scaleY = -1;
                } else if (GameVars.obstaclesBoard[i - 1 + 8][j + 1 + 1] !== 99) {
                    name = "light_side_2";
                }
            }

            img.setFrame(name);
            img.setAngle(90);
            this.renderTextureBackground.draw(img, GameConstants.GAME_WIDTH + GameConstants.CELLS_SIZE * j + GameConstants.CELLS_SIZE / 2, GameConstants.GAME_HEIGHT + GameConstants.CELLS_SIZE * i + GameConstants.CELLS_SIZE / 2);
        }

        if (j === this.boardMatrix[0].length - 1 || this.boardMatrix[i][j + 1] !== 1) {

            let img = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "shadow_side");
            img.setAngle(180);
            this.renderTextureBackground.draw(img, GameConstants.GAME_WIDTH + GameConstants.CELLS_SIZE * j + GameConstants.CELLS_SIZE / 2, GameConstants.GAME_HEIGHT + GameConstants.CELLS_SIZE * i + GameConstants.CELLS_SIZE / 2);
        }

        if (i === this.boardMatrix.length - 1 || this.boardMatrix[i + 1][j] !== 1) {

            let img = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "shadow_side");
            img.setAngle(270);
            this.renderTextureBackground.draw(img, GameConstants.GAME_WIDTH + GameConstants.CELLS_SIZE * j + GameConstants.CELLS_SIZE / 2, GameConstants.GAME_HEIGHT + GameConstants.CELLS_SIZE * i + GameConstants.CELLS_SIZE / 2);
        }

        // CORNER

        if (j !== 0 && i !== this.boardMatrix.length - 1 && this.boardMatrix[i + 1][j - 1] !== 1 && this.boardMatrix[i][j - 1] === 1 && this.boardMatrix[i + 1][j] === 1) {

            let img = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "shadow_corner");
            this.renderTextureBackground.draw(img, GameConstants.GAME_WIDTH + GameConstants.CELLS_SIZE * j + GameConstants.CELLS_SIZE / 2, GameConstants.GAME_HEIGHT + GameConstants.CELLS_SIZE * i + GameConstants.CELLS_SIZE / 2);
        }

        if (j !== 0 && i !== 0 && this.boardMatrix[i - 1][j - 1] !== 1 && this.boardMatrix[i][j - 1] === 1 && this.boardMatrix[i - 1][j] === 1) {

            let img = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "shadow_corner");
            img.setAngle(90);
            this.renderTextureBackground.draw(img, GameConstants.GAME_WIDTH + GameConstants.CELLS_SIZE * j + GameConstants.CELLS_SIZE / 2, GameConstants.GAME_HEIGHT + GameConstants.CELLS_SIZE * i + GameConstants.CELLS_SIZE / 2);
        }

        if (j !== this.boardMatrix[0].length - 1 && i !== 0 && this.boardMatrix[i - 1][j + 1] !== 1 && this.boardMatrix[i][j + 1] === 1 && this.boardMatrix[i - 1][j] === 1) {

            let img = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "shadow_corner");
            img.setAngle(180);
            this.renderTextureBackground.draw(img, GameConstants.GAME_WIDTH + GameConstants.CELLS_SIZE * j + GameConstants.CELLS_SIZE / 2, GameConstants.GAME_HEIGHT + GameConstants.CELLS_SIZE * i + GameConstants.CELLS_SIZE / 2);
        }

        if (j !== this.boardMatrix[0].length - 1 && i !== this.boardMatrix.length - 1 && this.boardMatrix[i + 1][j + 1] !== 1 && this.boardMatrix[i][j + 1] === 1 && this.boardMatrix[i + 1][j] === 1) {

            let img = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "shadow_corner");
            img.setAngle(270);
            this.renderTextureBackground.draw(img, GameConstants.GAME_WIDTH + GameConstants.CELLS_SIZE * j + GameConstants.CELLS_SIZE / 2, GameConstants.GAME_HEIGHT + GameConstants.CELLS_SIZE * i + GameConstants.CELLS_SIZE / 2);
        }
    }

    public newPortalGlare(): void {

        let portalGlare: Phaser.GameObjects.Image;

        if (this.portal.frame.name === "portal") {

            portalGlare = new Phaser.GameObjects.Image(this.scene, this.portal.x, this.portal.y - this.portal.height, "texture_atlas_1", "portal_glare");
            portalGlare.setOrigin(.5, 0);
            
        } else {

            portalGlare = new Phaser.GameObjects.Image(this.scene, this.portal.x, this.portal.y, "texture_atlas_1", "portal_side_glare");
            portalGlare.setOrigin(0, 1);
            portalGlare.scaleX = this.portal.scaleX;
        }

        const i = this.getIndex(this.actorsContainer);
        this.addAt(portalGlare, i - 1);

        this.scene.tweens.add({
            targets: portalGlare,
            alpha: 0,
            ease: Phaser.Math.Easing.Linear,
            duration: GameVars.timeStepFactor === 1 ? 500 : 120,
            onComplete: function(): void {
                portalGlare.destroy();
            },
            onCompleteScope: this
        });

        AudioManager.playSoundEffect("portal_spawns");
    }

    public sendActorBack(actor: any): void {

        this.sendToBack(actor);
        this.sendToBack(this.pathContainer);
    }
}
