
import { GameConstants } from "../../../GameConstants";
import { GlueTurretActor } from "./GlueTurretActor";
import { GameVars } from "../../../GameVars";
import { AudioManager } from "../../../AudioManager";
import * as Creepts from "endless-siege-engine";


export class GluePool extends Phaser.GameObjects.Container {

    public id: number;

    private glueTurretActor: GlueTurretActor;
    private glue: Creepts.Glue;

    constructor(scene: Phaser.Scene, glueTurretActor: GlueTurretActor, glue: Creepts.Glue) {

        super(scene);

        this.glueTurretActor = glueTurretActor;
        this.glue = glue;

        this.id = glue.id;

        this.x = this.glueTurretActor.x;
        this.y = this.glueTurretActor.y;

        let range = this.glue.range * GameConstants.CELLS_SIZE;

        for (let i = 0; i < 3; i++) {

            const rangeCircle = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "area_freeze");
            rangeCircle.setScale(0);
            rangeCircle.alpha = 0;
            this.add(rangeCircle);

            this.scene.tweens.add({
                targets: rangeCircle,
                scaleX: (range * 2) / rangeCircle.width,
                scaleY: (range * 2) / rangeCircle.width,
                alpha: 1,
                ease: Phaser.Math.Easing.Cubic.Out,
                duration: GameVars.timeStepFactor === 1 ? 600 : 200,
                delay: (GameVars.timeStepFactor === 1 ? 300 : 100) * i,
                onComplete: () => {
                    if (!this.scene) {
                        return;
                    }
                    this.scene.tweens.add({
                        targets: rangeCircle,
                        alpha: 0,
                        ease: Phaser.Math.Easing.Cubic.Out,
                        duration: GameVars.timeStepFactor === 1 ? 300 : 100
                    });
                },
                onCompleteScope: this
            });
        }

        AudioManager.playSoundEffect("t2_hielo");
    }

    public destroy(): void {

        if (!this.scene) {
            return;
        }

        this.scene.tweens.add({
            targets: this,
            alpha: 0,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: GameVars.timeStepFactor === 1 ? 600 : 200,
            onComplete: () => {
                super.destroy();
            },
            onCompleteScope: this
        });
    }

    private drawFx(): void {

        let angleExtra = Math.random() * 180;

        let pos = 40;
        let angle = Phaser.Math.DegToRad(Math.random() * 40 - 20 + angleExtra);

        let fx = new Phaser.GameObjects.Image(this.scene, pos * Math.cos(angle), pos * Math.sin(angle), "texture_atlas_1", "bullet_fx_3");
        fx.setScale(.4);
        this.add(fx);

        pos = 65;

        fx = new Phaser.GameObjects.Image(this.scene, pos * Math.cos(angle), pos * Math.sin(angle), "texture_atlas_1", "bullet_fx_3");
        fx.setScale(.25);
        this.add(fx);

        angle = Phaser.Math.DegToRad(100 + Math.random() * 40 + angleExtra);
        pos = 40;

        fx = new Phaser.GameObjects.Image(this.scene, pos * Math.cos(angle), pos * Math.sin(angle), "texture_atlas_1", "bullet_fx_3");
        fx.setScale(.25);
        this.add(fx);

        pos = 65;

        fx = new Phaser.GameObjects.Image(this.scene, pos * Math.cos(angle), pos * Math.sin(angle), "texture_atlas_1", "bullet_fx_3");
        fx.setScale(.4);
        this.add(fx);

        pos = 54;

        fx = new Phaser.GameObjects.Image(this.scene, pos * Math.cos(angle + .45), pos * Math.sin(angle + .45), "texture_atlas_1", "bullet_fx_3");
        fx.setScale(.15);
        this.add(fx);

        angle = Phaser.Math.DegToRad(220 + Math.random() * 40 + angleExtra);
        pos = 50;

        fx = new Phaser.GameObjects.Image(this.scene, pos * Math.cos(angle - .1), pos * Math.sin(angle - .1), "texture_atlas_1", "bullet_fx_3");
        fx.setScale(.3);
        this.add(fx);

        pos = 65;

        fx = new Phaser.GameObjects.Image(this.scene, pos * Math.cos(angle + .25), pos * Math.sin(angle + .25), "texture_atlas_1", "bullet_fx_3");
        fx.setScale(.25);
        this.add(fx);
    }
}
