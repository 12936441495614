import { GameVars } from "../../../GameVars";


export class MenuTitle extends Phaser.GameObjects.Container {

    public static readonly WIDTH = 360;
    public static readonly HEIGHT = 70;

    public text: Phaser.GameObjects.Text;

    constructor(scene: Phaser.Scene, label: string) {

        super(scene);

        this.setSize(MenuTitle.WIDTH, MenuTitle.HEIGHT);

        const titleBck = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "banner_red");
        this.add(titleBck);

        // text
        this.text = new Phaser.GameObjects.Text(this.scene, 0, -10, GameVars.gameText[GameVars.gameData.language].GAME_OVER, {fontFamily: "Supercell", fontSize: "30px", color: "#FFFFFF"});
        this.text.setOrigin(.5);
        this.text.setStroke("#000000", 6);
        this.text.setShadow(5, 5, "#000000");
        this.add(this.text);
   }
}
