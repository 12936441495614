
import { BattleScene } from "./../BattleScene";
import { GameConstants } from "../../../GameConstants";
import { BattleManager } from "../BattleManager";
import { GameVars } from "../../../GameVars";
import * as Creepts from "endless-siege-engine";
import { BoardContainer } from "../board-container/BoardContainer";
import { AudioManager } from "../../../AudioManager";
import { TutorialManager } from "../TutorialManager";
import { TurretType } from "endless-siege-engine/build/main/lib/Types";


export class TurretActor extends Phaser.GameObjects.Container {

    public id: number;
    public level: number;
    public p: { r: number, c: number };
    public canonLength: number;
    public base: Phaser.GameObjects.Image;
    public canon: Phaser.GameObjects.Sprite;
    public turret: Creepts.Turret;
    public canonPrevRotation: number;
    public canonAngle: number;
    public canonInitialY: number;
    public followedEnemy: any;

    private rangeCircle: Phaser.GameObjects.Image;
    private handCursor: Phaser.GameObjects.Image;
    private levelLabel: Phaser.GameObjects.BitmapText;

    constructor(scene: Phaser.Scene, type: string, position: { r: number, c: number }, turret: Creepts.Turret) {

        super(scene);

        this.p = position;
        this.name = type;
        this.handCursor = null;

        this.setScale(.9);

        this.turret = turret;
        this.id = this.turret.id;

        this.x = GameConstants.CELLS_SIZE * (this.p.c + .5);
        this.y = GameConstants.CELLS_SIZE * (this.p.r + .5);

        this.canonLength = 40;
        this.canonInitialY = 0;
        this.canonAngle = 19;
        this.canonPrevRotation = Math.PI * (3 / 2);

        this.rangeCircle = BattleManager.createRangeCircle(this.turret.range * GameConstants.CELLS_SIZE, this.x, this.y);

        this.levelLabel = new Phaser.GameObjects.BitmapText(this.scene, 0, 0, "supercell-green", "1", 17);
        this.levelLabel.setOrigin(.5),
            this.add(this.levelLabel);
    }

    public upgradeSprites(): void {

        switch (this.name) {
            case TurretType.PROJECTILE:

                if (this.turret.grade === 1) {
                    this.base.setFrame("archer_upgraded_1_base");
                } else if (this.turret.grade === 2) {
                    this.base.setFrame("archer_upgraded_2_base");
                } else if (this.turret.grade === 3) {
                    this.base.setFrame("archer_upgraded_3_base");
                }
                break;

            case TurretType.LASER:

                if (this.turret.grade === 1) {
                    this.base.setFrame("fire_upgraded_1_base");
                } else if (this.turret.grade === 2) {
                    this.base.setFrame("fire_upgraded_2_base");
                } else if (this.turret.grade === 3) {
                    this.base.setFrame("fire_upgraded_3_base");
                }
                break;

            case TurretType.GLUE:

                if (this.turret.grade === 1) {
                    this.base.setFrame("crystal_upgraded_1_base");
                } else if (this.turret.grade === 2) {
                    this.base.setFrame("crystal_upgraded_2_base");
                } else if (this.turret.grade === 3) {
                    this.base.setFrame("crystal_upgraded_3_base");
                }
                break;

            case TurretType.LAUNCH:

                if (this.turret.grade === 1) {
                    this.base.setFrame("cannon_upgraded_1_base");
                } else if (this.turret.grade === 2) {
                    this.base.setFrame("cannon_upgraded_2_base");
                } else if (this.turret.grade === 3) {
                    this.base.setFrame("cannon_upgraded_3_base");
                }
                break;

            default:
                break;
        }
    }

    public update(time: number, delta: number): void {

        let enemiesWithinRange = this.turret.getEnemiesWithinRange();

        if (this.turret.fixedTarget) {
            if (enemiesWithinRange.length > 0) {
                if (enemiesWithinRange.indexOf(this.followedEnemy) === -1) {
                    this.followedEnemy = enemiesWithinRange[0];
                }
            } else {
                this.followedEnemy = null;
            }
        } else if (enemiesWithinRange.length > 0) {
            this.followedEnemy = enemiesWithinRange[0];
        } else {
            this.followedEnemy = null;
        }

        if (this.followedEnemy) {
            const followedEnemyActor = BoardContainer.currentInstance.getEnemyActorByID(this.followedEnemy.id);

            if (followedEnemyActor) {
                const dx = followedEnemyActor.x - this.x;
                const dy = followedEnemyActor.y - (this.y + this.canonInitialY);

                if (this.canon) {
                    this.setCanonRotation(dx, dy);
                }
            }
        }
    }

    public showLevel(): void {
        this.bringToTop(this.levelLabel);

        this.levelLabel.visible = true;
        this.levelLabel.text = this.turret.level.toString();
    }

    public hideLevel(): void {

        this.levelLabel.visible = false;
    }

    public onAddedToBoardContainer(): void {

        if (GameVars.semiPaused) {
            this.showLevel();
        } else {
            this.hideLevel();
        }
    }

    public addHandCursor(): void {

        this.handCursor = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "hand_cursor");
        this.handCursor.setOrigin(.05, .2);
        this.add(this.handCursor);

        this.scene.tweens.add({
            targets: this.handCursor,
            x: 25,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 750,
            yoyo: false,
            repeat: -1
        });
    }

    public setCanonRotation(dx: number, dy: number): void {

        let rotation = Math.atan2(dy, dx);
        if (rotation < 0) {
            rotation = Math.PI * 2 + rotation;
        }

        let offRotation = rotation - this.canonPrevRotation;
        if (offRotation > Math.PI) {
            offRotation = offRotation - Math.PI * 2;
        } else if (offRotation < -Math.PI) {
            offRotation = offRotation + Math.PI * 2;
        }

        if (Math.abs(offRotation) > Math.PI / 16) {
            offRotation /= 2;
        }
        rotation = this.canonPrevRotation + offRotation;
        if (rotation > Math.PI * 2) {
            rotation -= Math.PI * 2;
        } else if (rotation < 0) {
            rotation += Math.PI * 2;
        }

        let angle = (rotation * 180) / Math.PI;
        angle = Math.round(angle / 15);
        this.canonAngle = Phaser.Math.Clamp(angle + 1, 1, 24);

        this.canonPrevRotation = rotation;
    }

    public upgrade(): void {

        const items = [];

        for (let i = 0; i < this.length; i++) {
            const item = <any>this.getAt(i);
            items.push(item);
            item.alpha = 0;
            item.setScale(.9);
        }

        this.scene.tweens.add({
            targets: items,
            alpha: 1,
            scaleX: 1,
            scaleY: 1,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 400
        });

        const glow = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "glow");
        glow.setScale(0);
        glow.blendMode = Phaser.BlendModes.SCREEN;
        this.addAt(glow, 0);

        this.scene.tweens.add({
            targets: glow,
            scaleX: 1.35,
            scaleY: 1.35,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 200,
            yoyo: true,
            onComplete: function (): void {
                glow.destroy();
            },
            onCompleteScope: this
        });

        this.reloadRangeCircle();

        AudioManager.playSoundEffect("turret_upgrade");
    }

    public improve(): void {

        this.reloadRangeCircle();

        this.base.blendMode = Phaser.BlendModes.SCREEN;
        if (this.canon) {
            this.canon.blendMode = Phaser.BlendModes.SCREEN;
        }

        this.scene.time.delayedCall(125, function (): void {
            this.base.blendMode = Phaser.BlendModes.NORMAL;
            if (this.canon) {
                this.canon.blendMode = Phaser.BlendModes.NORMAL;
            }
        }, [], this);

    }

    public reloadRangeCircle(): void {

        this.rangeCircle.setScale(1);
        this.rangeCircle.setScale((this.turret.range * GameConstants.CELLS_SIZE * 2) / this.rangeCircle.width);
    }

    public sold(): void {

        this.scene.tweens.add({
            targets: this,
            alpha: 0,
            scaleX: .65,
            scaleY: .65,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 1000,
            onComplete: function (): void {
                this.destroy();
            },
            onCompleteScope: this
        });

        AudioManager.playSoundEffect("sell_turret");
    }

    protected shoot(): void {
        // 
    }

    protected onDownTurret(): void {

        if (GameVars.currentScene !== BattleScene.currentInstance) {
            return;
        }

        if (GameVars.paused || BattleManager.engine.gameOver) {
            return;
        }

        if (!GameVars.gameData.tutorialSeen && TutorialManager.State != "waiting_for_turretlevelup" && TutorialManager.State != "waiting_for_turretupgrade") {
            return;
        }

        BattleManager.hideRangeCircles();
        BattleManager.hideTurretMenu();

        this.rangeCircle.visible = true;

        if (!GameVars.gameData.tutorialSeen) {

            if (this.handCursor) {
                this.handCursor.destroy();
                this.handCursor = null;
            }

            TutorialManager.hideTutorialLayer();
        } else {
            this.levelLabel.visible = false;
        }

        BattleScene.currentInstance.boardContainer.board.resetTurretFrame(this.x, this.y);
        BattleManager.showTurretMenu(this.turret);

        AudioManager.playSoundEffect("btn_click");
    }
}
