
import { GameManager } from "../GameManager"; 
import { GameVars } from "../GameVars";


export class BootScene extends Phaser.Scene {

    public static bootedInWrongOrientation: boolean;

    constructor() {

        super("BootScene");
    }

    public preload(): void {

        this.load.image("load_icon", "assets/load_icon.png");
        this.load.atlas("texture_atlas_0", "assets/texture_atlas_0.png", "assets/texture_atlas_0.json");
    }

    public create(): void {

        this.add.text(-100, -100, "abcdefg", { fontFamily: "Oswald-SemiBold", fontSize: "28px" , color: "#A6F834" });
        this.add.text(-100, -100, "abcdefg", { fontFamily: "Supercell", fontSize: "28px", color: "#A6F834" });

        GameManager.setCurrentScene(this);

        document.addEventListener("fullscreenchange", (a: any) => {

            const canvas = document.querySelector("canvas");

            if (!GameVars.currentScene.scale.isFullscreen) {
                canvas.style.width = "";
                canvas.style.height = "";
            }
            
        }, false);

        if (!this.game.device.os.desktop) {

            const w = window.innerWidth;
            const h = window.innerHeight;

            if (w < h) {

                BootScene.bootedInWrongOrientation = false;
                GameManager.init();
                
            } else {

                BootScene.bootedInWrongOrientation = true;

                document.getElementById("orientation").style.display = "block";
                document.getElementById("content").style.display = "none"; 
            }  

        } else {
            GameManager.init();
        }
    }
}
