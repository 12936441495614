
import { GameManager } from "../GameManager";
import { AudioManager } from "../AudioManager";
import { GameConstants } from "../GameConstants";
import { GameVars } from "../GameVars";


export class PreloadScene extends Phaser.Scene {

    public static currentInstance: PreloadScene;

    private progressBar: Phaser.GameObjects.Graphics;

    constructor() {

        super("PreloadScene");

        PreloadScene.currentInstance = this;
    }

    public preload(): void {

        this.composeScene();

        this.loadAssets();
    }

    public create(): void {

        let musicJson = this.cache.json.get("music");
        musicJson = JSON.parse(JSON.stringify(musicJson).replace("urls", "src"));

        AudioManager.music = new Howl(musicJson);
        Howler.autoSuspend = false;
        Howler.autoUnlock = true;

        // AudioManager.music.unload();
        AudioManager.music.load();

        AudioManager.music.on("load", function () {

            let soundJson = PreloadScene.currentInstance.cache.json.get("sound");
            soundJson = JSON.parse(JSON.stringify(soundJson).replace("urls", "src"));

            AudioManager.sound = new Howl(soundJson);

            // AudioManager.sound.unload();
            AudioManager.sound.load();

            AudioManager.sound.on("load", function () {

                GameManager.setCurrentScene(PreloadScene.currentInstance);
                PreloadScene.currentInstance.scene.setVisible(false);
                GameManager.onGameAssetsLoaded();

                AudioManager.music.on("unlock", () => {
                    setTimeout(() => {
                        Howler.ctx.resume();
                    }, 700);
                });
                AudioManager.sound.on("unlock", () => {
                    setTimeout(() => {
                        Howler.ctx.resume();
                    }, 700);
                });
                window.addEventListener("focus", () => {
                    setTimeout(() => {
                        Howler.ctx.resume();
                    }, 700);
                })

            });
        });
    }

    private composeScene(): void {
       
        const background = this.add.graphics();
        background.fillStyle(0x061F2E);
        background.fillRect(0, 0, GameConstants.GAME_WIDTH, GameConstants.GAME_HEIGHT);

        const scaledItemsContainer = this.add.container(GameConstants.GAME_WIDTH / 2, GameConstants.GAME_HEIGHT / 2);
        scaledItemsContainer.scaleY = GameVars.scaleY;

        const loadIcon = new Phaser.GameObjects.Image(this, 0, 0, "load_icon");
        scaledItemsContainer.add(loadIcon);

        this.tweens.add({
            targets: loadIcon,
            angle: 360,
            ease: Phaser.Math.Easing.Linear,
            duration: 3500,
            repeat: -1
        });
    }

    private loadAssets(): void {

        this.load.atlas("texture_atlas_1", "assets/texture_atlas_1.png", "assets/texture_atlas_1.json");
        this.load.atlas("texture_atlas_2", "assets/texture_atlas_2.png", "assets/texture_atlas_2.json");
        this.load.atlas("texture_atlas_3", "assets/texture_atlas_3.png", "assets/texture_atlas_3.json");
        this.load.atlas("texture_atlas_4", "assets/texture_atlas_4.png", "assets/texture_atlas_4.json");
        this.load.atlas("texture_atlas_5", "assets/texture_atlas_5.png", "assets/texture_atlas_5.json");
        this.load.atlas("texture_atlas_6", "assets/texture_atlas_6.png", "assets/texture_atlas_6.json");

        // EL TEXTO DEL JUEGO
        this.load.json("game-text", "assets/config/text.json");

        // fuentes
        this.load.bitmapFont("supercell-white", "assets/fonts/supercell-white.png", "assets/fonts/supercell-white.xml");
        this.load.bitmapFont("supercell-green", "assets/fonts/supercell-green.png", "assets/fonts/supercell-green.xml");
        this.load.bitmapFont("supercell-red", "assets/fonts/supercell-red.png", "assets/fonts/supercell-red.xml");

        this.load.json("sound", "assets/audio/sound.json");
        this.load.json("music", "assets/audio/music.json");

        this.load.on("progress", this.updateLoadedPercentage, this);
    }

    private updateLoadedPercentage(percentageLoaded: number): void {
        //
    }
}
