
import { LaunchTurretActor } from "./LaunchTurretActor";
import { GameConstants } from "../../../GameConstants";
import { GameVars } from "../../../GameVars";
import { BoardContainer } from "../board-container/BoardContainer";
import * as Creepts from "endless-siege-engine";
import { AudioManager } from "../../../AudioManager";


export class MineActor extends Phaser.GameObjects.Container {

    public mine: Creepts.Mine;

    private mineImage: Phaser.GameObjects.Sprite;

    constructor(scene: Phaser.Scene, mine: Creepts.Mine, launchTurretActor: LaunchTurretActor) {

        super(scene, 0, 0);

        this.mine = mine;

        const randX = Math.random() * GameConstants.CELLS_SIZE / 2 - GameConstants.CELLS_SIZE / 3;
        const randY = Math.random() * GameConstants.CELLS_SIZE / 2 - GameConstants.CELLS_SIZE / 3;

        this.x = launchTurretActor.x;
        this.y = launchTurretActor.y - 30;

        const midY = this.y < this.mine.y * GameConstants.CELLS_SIZE + randY ? this.y - 30 : this.mine.y * GameConstants.CELLS_SIZE + randY - 30; 
        const midX = launchTurretActor.x + ((this.mine.x * GameConstants.CELLS_SIZE + randX) - launchTurretActor.x) / 2;

        this.mineImage = this.scene.add.sprite(0, 0, "texture_atlas_1");
        this.add(this.mineImage);

        this.mineImage.anims.play("mine");

        this.scene.tweens.add({
            targets: this,
            y: midY,
            scaleX: 1.5,
            scaleY: 1.5,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: GameVars.timeStepFactor === 1 ? 300 : 100,
            onComplete: function(): void {

                if (!this.scene) {
                    return;
                }

                this.scene.tweens.add({
                    targets: this,
                    scaleX: 1,
                    scaleY: 1,
                    y: this.mine.y * GameConstants.CELLS_SIZE + randY,
                    ease: Phaser.Math.Easing.Cubic.In,
                    duration: GameVars.timeStepFactor === 1 ? 600 : 200,
                    onComplete: function(): void {

                        if (!this.scene) {
                            return;
                        }

                        this.mineImage.anims.stop();
                        this.mineImage.setFrame("mine");

                        AudioManager.playSoundEffect("mine_lands");
                    },
                    onCompleteScope: this
                });
            },
            onCompleteScope: this
        });

        this.scene.tweens.add({
            targets: this,
            x: midX,
            ease: Phaser.Math.Easing.Linear,
            duration: GameVars.timeStepFactor === 1 ? 300 : 100,
            onComplete: () => {
                if (!this.scene) {
                    return;
                }
                this.scene.tweens.add({
                    targets: this,
                    x: this.mine.x * GameConstants.CELLS_SIZE + randX,
                    ease: Phaser.Math.Easing.Linear,
                    duration: GameVars.timeStepFactor === 1 ? 600 : 200
                });
            }
        });

        AudioManager.playSoundEffect("t2_mine_launcher");
    }

    public detonate(): void {

        this.mineImage.visible = false;
        
        let explosionEffect = this.scene.add.sprite(0, 0, "texture_atlas_1", "explossion_01");
        explosionEffect.setScale(2);
        explosionEffect.angle = Math.random() * 360;
        this.add(explosionEffect);

        explosionEffect.anims.play("explosion");

        explosionEffect.on("animationcomplete", () => {
            BoardContainer.currentInstance.removeMine(this);
        }, this);

        AudioManager.playSoundEffect("t2_mine_explosion");
    }
}
