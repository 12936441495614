import { ResultsLayer } from "./ResultsLayer";
import { SplashMapPreview } from "./SplashMapPreview";
import { GameConstants } from "./../../GameConstants";
import { GameVars } from "../../GameVars";
import { GameManager } from "../../GameManager";
import { AudioManager } from "../../AudioManager";
import { GameTitle } from "../GameTitle";
import { ButtonWithText } from "../../utils/ButtonWithText";
import { SplashMenu } from "../battle-scene/SplashMenu";

export class SplashScene extends Phaser.Scene {

    public static firstInstantiation = true;
    public static currentInstance: SplashScene;

    public onDuelPayment: boolean;

    private f: number;
    private character: Phaser.GameObjects.Image;
    private gameTitle: GameTitle;
    private midContainer: Phaser.GameObjects.Container;
    private playLabel: Phaser.GameObjects.Text;
    private loadIcon: Phaser.GameObjects.Image;
    private playButtonContainer: Phaser.GameObjects.Container;
    private playButtonPressed: boolean;
    settingsBtn: Phaser.GameObjects.Image;
    splashMenu: SplashMenu;

    constructor() {

        super("SplashScene");

        SplashScene.currentInstance = this;
    }

    public create(): void {

        GameVars.currentScene = this;

        this.scene.setVisible(true);

        this.f = 0;
        this.onDuelPayment = false;
        this.playButtonPressed = false;

        this.composeSplash();

        SplashScene.firstInstantiation = false;
    }

    public update(): void {

        this.f++;

        if (this.f === 550) {

            this.f = 0;

            this.tweens.add({
                targets: this.character,
                y: this.character.y - 6,
                ease: Phaser.Math.Easing.Cubic.InOut,
                duration: 80,
                yoyo: true,
                repeat: 1
            });
        }

        this.gameTitle.update();
    }

    public showSplashElelements(): void {

        const items = [];
        if (!this.midContainer.visible) {
            items.push(this.midContainer);
            this.midContainer.visible = true;
            this.midContainer.alpha = 0;
            this.midContainer.setScale(.65);

        }

        if (!this.settingsBtn.visible) {
            items.push(this.settingsBtn);
            this.settingsBtn.setVisible(true);
            this.settingsBtn.setAlpha(0);
            this.settingsBtn.setScale(0.65);
        }

        if (items.length) {
            this.tweens.add({
                targets: items,
                alpha: 1,
                scaleX: 1,
                scaleY: 1 * GameVars.scaleY,
                ease: Phaser.Math.Easing.Elastic.Out,
                duration: 1000
            });
        }
        AudioManager.playSoundEffect("title_appears");
    }


    public async onPlayDown(): Promise<void> {

        if (this.onDuelPayment || this.playButtonPressed) {
            return;
        }

        AudioManager.playSoundEffect("btn_click");

        this.playButtonPressed = true;
        this.playButtonContainer.visible = false;

        const loadIcon = new Phaser.GameObjects.Image(this, this.playButtonContainer.x, this.playButtonContainer.y, "texture_atlas_1", "load_icon");
        loadIcon.setScale(1.25);
        this.midContainer.add(loadIcon);

        this.tweens.add({
            targets: loadIcon,
            angle: 360,
            ease: Phaser.Math.Easing.Linear,
            duration: 2000,
            repeat: -1
        });
        GameManager.onSplashPlayDown();
    }

    private composeSplash(): void {

        const backgroundContainer = this.add.container(0, 0);
        backgroundContainer.scaleY = GameVars.scaleY;

        let n = Math.round(GameConstants.GAME_HEIGHT / 128 / GameVars.scaleY) + 1;

        for (let i = 0; i < n; i++) {
            const backgroundSlice = new Phaser.GameObjects.Image(this, 0, i * 128, "texture_atlas_0", "splash_bg");
            backgroundSlice.setOrigin(0, 0);
            backgroundContainer.add(backgroundSlice);
        }

        const gradient = this.add.image(0, 0, "texture_atlas_0", "blue_gradient");
        gradient.setOrigin(0);
        gradient.scaleX = GameConstants.GAME_WIDTH / 10;
        gradient.scaleY = 1.35 / GameVars.scaleY;

        this.gameTitle = new GameTitle(this);
        this.gameTitle.x = GameConstants.GAME_WIDTH / 2 + 14;
        this.gameTitle.y = 160 * GameVars.scaleY;
        this.gameTitle.scaleY = GameVars.scaleY;
        this.add.existing(this.gameTitle);

        this.gameTitle.activate();

        // mid container
        this.midContainer = new Phaser.GameObjects.Container(this, GameConstants.GAME_WIDTH / 2, GameConstants.GAME_HEIGHT * .515);
        this.midContainer.scaleY = GameVars.scaleY;
        this.midContainer.visible = !SplashScene.firstInstantiation;
        this.add.existing(this.midContainer);

        let characterTextureName: string;

        const rnd = Math.random();

        if (rnd < .2) {
            characterTextureName = "pose_blob";
        } else if (rnd < .4) {
            characterTextureName = "pose_flier";
        } else if (rnd < .6) {
            characterTextureName = "pose_healer";
        } else if (rnd < .8) {
            characterTextureName = "pose_normal";
        } else {
            characterTextureName = "pose_runner";
        }

        this.character = new Phaser.GameObjects.Image(this, -145, 90, "texture_atlas_1", characterTextureName);
        this.character.setOrigin(1, .5);

        this.midContainer.add(this.character);

        if (SplashScene.firstInstantiation) {

            this.character.x = -100;
            this.character.alpha = 0;
            this.character.setScale(.85);

            this.tweens.add({
                targets: this.character,
                x: -145,
                alpha: 1,
                scaleX: 1,
                scaleY: 1,
                ease: Phaser.Math.Easing.Cubic.Out,
                duration: 300,
                delay: 2500
            });
        }

        const splashMapPreview = new SplashMapPreview(this);
        this.midContainer.add(splashMapPreview);

        const banner = new Phaser.GameObjects.Image(this, 0, -100, "texture_atlas_1", "banner_red");
        banner.setScale(.85),
            this.midContainer.add(banner);

        const title = new Phaser.GameObjects.Text(this, 0, -108, GameVars.gameText[GameVars.gameData.language].MAP_OF_THE_DAY, { fontFamily: "Supercell", fontSize: "24px", color: "#FFFFFF" });
        title.setOrigin(.5);
        title.setStroke("#000000", 6);
        title.setShadow(4, 4, "#000000");
        this.midContainer.add(title);

        const weekDayLabel = new Phaser.GameObjects.Text(this, 0, -60, GameVars.getDate(), { fontFamily: "Supercell", fontSize: "16px", color: "#FFFFFF" });
        weekDayLabel.setOrigin(.5);
        weekDayLabel.setStroke("#000000", 4);
        weekDayLabel.setShadow(3, 3, "#000000");
        this.midContainer.add(weekDayLabel);

        const resultsLayer = new ResultsLayer(this);
        resultsLayer.setPosition(0, 300);
        this.midContainer.add(resultsLayer);

        // restart button
        this.playButtonContainer = new Phaser.GameObjects.Container(this, 0, 235);
        this.midContainer.add(this.playButtonContainer);

        const playButton = new Phaser.GameObjects.Image(this, 0, 0, "texture_atlas_1", "btn_play_void");
        playButton.setInteractive({ useHandCursor: true });
        playButton.on("pointerover", () => {
            this.playButtonContainer.setScale(1.025);
        }, this);
        playButton.on("pointerout", () => {
            this.playButtonContainer.setScale(1);
        }, this);

        playButton.on("pointerup", () => {
            this.onPlayDown();
        }, this);
        this.playButtonContainer.add(playButton);

        const playLabel = new Phaser.GameObjects.Text(this, 0, 0, GameVars.gameText[GameVars.gameData.language].PLAY_MAYUS, { fontFamily: "Supercell", fontSize: "28px", color: "#FFFFFF" });
        playLabel.setOrigin(.5);
        playLabel.setStroke("#663333", 8);
        playLabel.setShadow(5, 5, "#663333");
        this.playButtonContainer.add(playLabel);

        this.settingsBtn = this.add.image(0, 0, "texture_atlas_1", "settings_btn");
        this.add.existing(this.settingsBtn);
        this.settingsBtn.x = this.settingsBtn.displayWidth / 2 + 15;
        this.settingsBtn.y = GameConstants.GAME_HEIGHT - this.settingsBtn.displayHeight / 2 - 15;
        this.settingsBtn.setInteractive({ useHandCursor: true });
        this.settingsBtn.on(Phaser.Input.Events.GAMEOBJECT_POINTER_UP, this.showOverlayMenu, this);
        this.settingsBtn.setVisible(false);
    }

    private showOverlayMenu() {
        this.splashMenu = new SplashMenu(this);
        this.splashMenu.setPosition(GameConstants.GAME_WIDTH / 2, GameConstants.GAME_HEIGHT / 2 - 200)
        this.add.existing(this.splashMenu)
    }
}
